import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree';
import { math } from '../helpers/math';

export interface GenericSizeStoreInterface extends Instance<typeof GenericSizeStore> {}
export interface GenericSizeStoreInterfaceSnapshotIn extends SnapshotIn<typeof GenericSizeStore> {}
export interface GenericSizeStoreInterfaceSnapshotOut extends SnapshotOut<typeof GenericSizeStore> {}

export const GenericSizeStore = types
    .model({
        w: types.number,
        h: types.number,
    })
    .actions((self) => ({
        set(data: Partial<GenericSizeStoreInterfaceSnapshotIn>): void {
            if (data.w) self.w = math.round(data.w);
            if (data.h) self.h = math.round(data.h);
        },
    }));
