import { SVG, ArrayXY, Polyline, Image } from '@svgdotjs/svg.js';
import { SVG_PREFIX } from '../environment/const';
import { forceNodeRedraw } from '../helpers/forceNodeRedraw';
import { getImageOrientation } from '../helpers/getImageOrientation';
import { getPseudoID } from '../helpers/getPseudoID';
import { PhotosiEditorSDK } from '../PhotosiEditorSDK';
import { MainThemeInterface } from '../theme/main';
import { filterName, setFilterToElements } from './filtersHelper';

export const initializeStage = (
    stage: HTMLDivElement,
    imageURL: string,
    theme: MainThemeInterface
): {
    imagePreview: Image;
    holeBoundLine: Polyline;
} => {
    const service = PhotosiEditorSDK.getService();

    const adjustPreviewStore = service.AdjustPreviewStore;
    const filesPileStore = service.FilesPileStore;

    const fileStore = filesPileStore.getImageByStageIdAndIndex(adjustPreviewStore.stageId, adjustPreviewStore.index);

    const angleSize: number = 30;

    stage.innerHTML = '';
    const svg = SVG();
    svg.addTo(stage).size(adjustPreviewStore.stage.size.w, adjustPreviewStore.stage.size.h);
    svg.fill(theme.colors.main.black);

    const holeTopSX: ArrayXY = [
        adjustPreviewStore.stage.size.w / 2 - adjustPreviewStore.stage.svghole.w / 2,
        adjustPreviewStore.stage.size.h / 2 - adjustPreviewStore.stage.svghole.h / 2,
    ];
    const holeTopDX: ArrayXY = [holeTopSX[0] + adjustPreviewStore.stage.svghole.w, holeTopSX[1]];
    const holeBottomSX: ArrayXY = [holeTopSX[0], holeTopSX[1] + adjustPreviewStore.stage.svghole.h];
    const holeBottomDX: ArrayXY = [holeBottomSX[0] + adjustPreviewStore.stage.svghole.w, holeBottomSX[1]];

    const rectBg = SVG()
        .path(`M 0 0 h ${adjustPreviewStore.stage.size.w} v ${adjustPreviewStore.stage.size.h} h -${adjustPreviewStore.stage.size.w} Z
               M  ${holeTopSX[0]} ${holeTopSX[1]} h ${adjustPreviewStore.stage.svghole.w} v ${adjustPreviewStore.stage.svghole.h} h -${adjustPreviewStore.stage.svghole.w} Z`);
    rectBg.id('hole-stage');

    rectBg.fill({ color: theme.colors.main.black, opacity: 0.6 });
    rectBg.attr('fill-rule', 'evenodd');

    const holeBoundLine = SVG()
        .polyline([holeTopSX, holeTopDX, holeBottomDX, holeBottomSX, holeTopSX])
        .stroke({ width: 1, color: theme.colors.main.white })
        .fill({ color: 'rgba(255,255,255,0)' });

    const holeVline1 = SVG()
        .line(
            holeTopSX[0] + (adjustPreviewStore.stage.svghole.w / 3) * 1,
            holeTopSX[1],
            holeBottomSX[0] + (adjustPreviewStore.stage.svghole.w / 3) * 1,
            holeBottomSX[1]
        )
        .stroke({ width: 1, color: theme.colors.main.white, opacity: 0.5 });

    const holeVline2 = SVG()
        .line(
            holeTopSX[0] + (adjustPreviewStore.stage.svghole.w / 3) * 2,
            holeTopSX[1],
            holeBottomSX[0] + (adjustPreviewStore.stage.svghole.w / 3) * 2,
            holeBottomSX[1]
        )
        .stroke({ width: 1, color: theme.colors.main.white, opacity: 0.5 });

    const holeHline1 = SVG()
        .line(
            holeTopSX[0],
            holeTopSX[1] + (adjustPreviewStore.stage.svghole.h / 3) * 1,
            holeTopDX[0],
            holeTopDX[1] + (adjustPreviewStore.stage.svghole.h / 3) * 1
        )
        .stroke({ width: 1, color: theme.colors.main.white, opacity: 0.5 });

    const holeHline2 = SVG()
        .line(
            holeTopSX[0],
            holeTopSX[1] + (adjustPreviewStore.stage.svghole.h / 3) * 2,
            holeTopDX[0],
            holeTopDX[1] + (adjustPreviewStore.stage.svghole.h / 3) * 2
        )
        .stroke({ width: 1, color: theme.colors.main.white, opacity: 0.5 });

    const holeAngleTopSX = SVG()
        .polyline([
            [holeTopSX[0], holeTopSX[1] + angleSize],
            [holeTopSX[0], holeTopSX[1]],
            [holeTopSX[0], holeTopSX[1]],
            [holeTopSX[0], holeTopSX[1]],
            [holeTopSX[0] + angleSize, holeTopSX[1]],
            [holeTopSX[0], holeTopSX[1]],
        ])
        .stroke({ width: 3, color: theme.colors.main.white });

    const holeAngleTopDX = holeAngleTopSX
        .clone()
        .move(holeTopDX[0] - angleSize, holeTopDX[1])
        .rotate(90);

    const holeAngleBottomSX = holeAngleTopSX
        .clone()
        .move(holeBottomSX[0], holeBottomSX[1] - angleSize)
        .rotate(-90);

    const holeAngleBottomDX = holeAngleTopSX
        .clone()
        .move(holeBottomDX[0] - angleSize, holeBottomDX[1] - angleSize)
        .rotate(-180);

    const imagePreview = SVG().image(imageURL);
    imagePreview.attr('preserveAspectRatio', 'xMidYMid slice');

    const stageOrientation = getImageOrientation(
        adjustPreviewStore.stage.svghole.w,
        adjustPreviewStore.stage.svghole.h
    );
    const imageOrientation = getImageOrientation(fileStore.thumbnailSize.w, fileStore.thumbnailSize.h);

    if (PhotosiEditorSDK.isVerbose()) console.log(`stage: ${stageOrientation}, image: ${imageOrientation}`);

    imagePreview.size(adjustPreviewStore.stage.image.w, adjustPreviewStore.stage.image.h);
    imagePreview.x(adjustPreviewStore.stage.size.w / 2 - adjustPreviewStore.stage.image.w / 2);
    imagePreview.y(adjustPreviewStore.stage.size.h / 2 - adjustPreviewStore.stage.image.h / 2);

    imagePreview.transform(adjustPreviewStore.getStageTransform());

    svg.add(imagePreview);

    const stageCornerGroup = SVG().group();
    stageCornerGroup.id(`${SVG_PREFIX}_stageCornerGroup_${getPseudoID()}`);

    stageCornerGroup.add(rectBg);
    stageCornerGroup.add(holeBoundLine);
    stageCornerGroup.add(holeAngleTopSX);
    stageCornerGroup.add(holeAngleTopDX);
    stageCornerGroup.add(holeAngleBottomSX);
    stageCornerGroup.add(holeAngleBottomDX);

    switch (adjustPreviewStore.mode) {
        case 'transform':
            stageCornerGroup.add(holeVline1);
            stageCornerGroup.add(holeVline2);
            stageCornerGroup.add(holeHline1);
            stageCornerGroup.add(holeHline2);
            break;
    }

    svg.add(stageCornerGroup);

    setFilterToElements(adjustPreviewStore.filter as filterName, [imagePreview]);

    forceNodeRedraw(imagePreview.node);

    return { imagePreview, holeBoundLine };
};
