import jsCookies from 'js-cookie';
import { DEFAULT_LANGUAGE, LANGUAGE_COOKIE } from '../environment/const';

/**
 * @desc Get language from cookies (customized 4 Photosi.com domains)
 * @return {string}
 */
export function getLanguage(): string {
    const languageCookie = jsCookies.get(`${LANGUAGE_COOKIE}`);
    const browserLanguage = getBrowserLanguage();

    if (typeof languageCookie !== 'undefined') {
        return languageCookie;
    }

    return browserLanguage || DEFAULT_LANGUAGE;
}

/**
 * @desc Get browser language from navigator
 * @return {string}
 */
export function getBrowserLanguage(): string {
    const hasDash = navigator.language.includes('-');

    return hasDash ? navigator.language.split('-')[0] : navigator.language;
}
