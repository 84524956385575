export class math {
    static round(val: number) {
        return (val + 0.49) << 0;
    }
    static floor(val: number) {
        return ~~val;
    }
    static roundFixedTwo(val: number) {
        return this.round((val + Number.EPSILON) * 100) / 100;
    }
}
export default math;
