import { Svg, Element } from '@svgdotjs/svg.js';
import { flow, toJS } from 'mobx';
import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree';
import calculateAspectRatio from '../helpers/calculateAspectRatio';
import scaleLinear from '../helpers/scaleLinear';
import { ImageSize } from '../interface/ImageSize';
import AdjustBoundaries from '../service/AdjustPreviewBoundaries';
import { getImageFromCache } from '../service/CacheStorage';
import { AdjustPreviewBoundariesStoreInterfaceSnapshotIn } from './AdjustPreviewBoundariesStore';

import {
    AdjustPreviewTrasformStoreInterfaceSnapshotIn,
    AdjustPreviewTrasformStoreInterfaceSnapshotOut,
} from './AdjustPreviewTrasformStore';
import { GenericSizeStoreInterfaceSnapshotIn } from './GenericSizeStore';
import { ImageDataExtend } from './ImageUrlStore';
import StageEnv, { StageEnvInterfaceSnapshotOut } from './StageEnv';

export interface AdjustPreviewStoreInterface extends Instance<typeof AdjustPreviewStore> {}
export interface AdjustPreviewStoreInterfaceSnapshotIn extends SnapshotIn<typeof AdjustPreviewStore> {}
export interface AdjustPreviewStoreInterfaceSnapshotOut extends SnapshotOut<typeof AdjustPreviewStore> {}

type AdjustPreviewEnvType = 'stage' | 'orig';
type AdjustModeEnum = 'filter' | 'transform';

export const AdjustPreviewStore = types
    .model({
        stageId: types.string,
        index: types.number,
        id: types.string,
        image: types.string,
        orig: StageEnv,
        stage: StageEnv,
        filter: types.string,
        visible: types.boolean,
        mode: types.enumeration<AdjustModeEnum>(['filter', 'transform']),
    })
    .volatile<ImageDataExtend>((self) => ({
        draw: null,
        element: null,
    }))
    .actions((self) => ({
        setMode(value: AdjustModeEnum) {
            self.mode = value;
        },
        setDraw(draw: Svg) {
            self.draw = draw;
        },
        setElement(elem: Element) {
            self.element = elem;
        },
        hide() {
            self.visible = false;
            URL.revokeObjectURL(self.image);
        },
        show() {
            self.visible = true;
        },
        calcRangeBoundaries(
            env: AdjustPreviewEnvType = 'stage',
            hole?: { w: number; h: number }
        ): AdjustPreviewBoundariesStoreInterfaceSnapshotIn {
            const snapshot: StageEnvInterfaceSnapshotOut = toJS(self[env]);

            let currentHole;
            if (hole === undefined) {
                currentHole = snapshot.svghole;
            } else {
                currentHole = toJS(hole);
            }

            const translateRange = AdjustBoundaries.calcRangeBound({
                imageW: snapshot.image.w * snapshot.transform.scale,
                imageH: snapshot.image.h * snapshot.transform.scale,
                holeW: currentHole.w,
                holeH: currentHole.h,
            });

            // AGGIUSTA LA POSIZIONE DELL'IMMAGINE IN BASE LA SCALA
            self[env].boundaries.translateX.set(Object.assign(snapshot.boundaries.translateX, translateRange.x));
            self[env].boundaries.translateY.set(Object.assign(snapshot.boundaries.translateY, translateRange.y));

            return toJS(self[env].boundaries);
        },
        scaleStageTransformToOrig() {
            const newX = scaleLinear(
                self.stage.transform.translate.x,
                self.stage.boundaries.translateX.min,
                self.stage.boundaries.translateX.max,
                self.orig.boundaries.translateX.min,
                self.orig.boundaries.translateX.max
            );

            const newY = scaleLinear(
                self.stage.transform.translate.y,
                self.stage.boundaries.translateY.min,
                self.stage.boundaries.translateY.max,
                self.orig.boundaries.translateY.min,
                self.orig.boundaries.translateY.max
            );

            self.orig.transform.set({
                rotate: self.stage.transform.rotate,
                scale: self.stage.transform.scale,
                translate: {
                    x: newX || 0,
                    y: newY || 0,
                },
            });
        },
        clearTransform() {
            self.stage.transform.clear();
            self.orig.transform.clear();
        },
        getStageTransform(): AdjustPreviewTrasformStoreInterfaceSnapshotOut {
            return toJS(self.stage.transform);
        },
        setStageImageSize(data: ImageSize) {
            self.stage.image.set({ w: data.width, h: data.height });
        },
    }))
    .actions((self) => ({
        setFilter(filter: string) {
            self.filter = filter;
        },
        setStage(data: AdjustPreviewStoreInterfaceSnapshotIn): void {
            self.clearTransform();
            self.stageId = data.stageId;
            self.index = data.index;
            self.id = data.id;
            self.image = data.image;
            self.filter = data.filter;
            self.orig.set(data.orig);
            self.stage.set(data.stage);

            const newStageImagesize = calculateAspectRatio(
                self.orig.image.w,
                self.orig.image.h,
                self.stage.svghole.w,
                self.stage.svghole.h,
                'cover'
            );
            self.setStageImageSize(newStageImagesize);

            self.calcRangeBoundaries('orig', { ...self.orig.svghole });
            self.calcRangeBoundaries('stage', { ...self.stage.svghole });
        },
        setStageHole(data: Partial<GenericSizeStoreInterfaceSnapshotIn>) {
            self.stage.svghole.set(data);
            const newStageImagesize = calculateAspectRatio(
                self.orig.image.w,
                self.orig.image.h,
                self.stage.svghole.w,
                self.stage.svghole.h,
                'cover'
            );
            self.setStageImageSize(newStageImagesize);
        },
    }))
    .actions((self) => ({
        setStageTransform(
            data: Partial<AdjustPreviewTrasformStoreInterfaceSnapshotIn>
        ): AdjustPreviewTrasformStoreInterfaceSnapshotOut {
            const transform = Object.assign(toJS(self.stage.transform), data);
            self.stage.transform.set(transform);

            self.calcRangeBoundaries('orig');
            self.calcRangeBoundaries('stage');
            self.scaleStageTransformToOrig();
            return self.getStageTransform();
        },
        setImage(image: string) {
            self.image = image;
        },
        getImage() {
            const getImageFlow = flow(function* () {
                const { stageId, index } = self;
                return yield getImageFromCache({ stageId, index }).catch((err) => console.error('getImage', err));
            });

            return getImageFlow();
        },
    }));

export default AdjustPreviewStore;
