import PhotosiEditorSDK from '../PhotosiEditorSDK';

export const sizeCondition = (filesize: number): boolean => {
    const MBUnit = 1048576; //1MB
    let sizeLimit = MBUnit * 8;

    if (PhotosiEditorSDK.getConfig()?.sizeCondition?.limit?.default) {
        sizeLimit = PhotosiEditorSDK.getConfig()?.sizeCondition?.limit?.default || MBUnit * 8;
    }

    if (!PhotosiEditorSDK.isWeakDevice()) {
        sizeLimit = PhotosiEditorSDK.getConfig()?.sizeCondition?.limit?.weakDevice || MBUnit * 8;
    }

    if (window.navigator.hasOwnProperty('connection')) {
        switch (window.navigator.connection.effectiveType) {
            default:
            case '4g':
                sizeLimit = PhotosiEditorSDK.getConfig()?.sizeCondition?.limit?.network4g || MBUnit * 8;
                break;
            case '3g':
                sizeLimit = PhotosiEditorSDK.getConfig()?.sizeCondition?.limit?.network3g || MBUnit * 2;
                break;
            case '2g':
                sizeLimit = PhotosiEditorSDK.getConfig()?.sizeCondition?.limit?.network2g || MBUnit * 0.5;
                break;
        }
    }

    return filesize > sizeLimit ? true : false;
};
