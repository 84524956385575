import { i18n } from 'i18next';
import React, { useLayoutEffect, useState } from 'react';
import PhotosiEditorSDK from '../PhotosiEditorSDK';

export interface TransNSComponentInterface {
    readonly i18n?: i18n;
    i18nKey: string;
}
export const TransNS: React.FC<TransNSComponentInterface> = ({ i18nKey, children }) => {
    const { i18n } = PhotosiEditorSDK.getService();
    const [translate, setTranslate] = useState<string | null>(null);
    useLayoutEffect(() => {
        if (!i18n) return;
        i18n.loadNamespaces(i18nKey.split(':')[0]).then(() => {
            const result = i18n.getResource(i18n.language, i18nKey.split(':')[0], i18nKey.split(':')[1]);
            if (typeof result === 'string') {
                let parsedResult;
                try {
                    parsedResult = JSON.parse(result);
                } catch (error) {}
                if (!parsedResult && typeof parsedResult !== 'object') {
                    setTranslate(result);
                }
            }
        });
    }, []);
    return <>{translate || children}</>;
};
