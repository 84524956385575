import { PhotosiEditorSDKIntferace } from '../interface/PhotosiEditorSDKIntferace';

export const defualtConfigPhotoEditorSdk = (): Partial<PhotosiEditorSDKIntferace.Config> => {
    return {
        publicPath: '',
        showFilter: true,
        offscreenCanvas: window.OffscreenCanvas ? true : false,
        verbose: false,
        textPalette: [],
        backgroundPalette: [],
        previewStage: {
            replaceImage: true,
            adjustImage: true,
            textInput: true,
            textColor: true,
            removeImage: true,
            backgroundColor: true,
            stageButton: false,
        },
        sizeCondition: {
            limit: {
                default: undefined,
                weakDevice: undefined,
                network4g: undefined,
                network3g: undefined,
                network2g: undefined,
            },
        },
    };
};

export default defualtConfigPhotoEditorSdk;
