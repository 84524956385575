import { Svg } from '@svgdotjs/svg.js';
import { IMAGETYPE_SVG, ROTATION_LABEL } from '../environment/const';
import { getGroupUserImageArea } from '../stage-helpers/selectorsHelpers';
import { stringToSVG } from './SvgHandler';

export const rotateSvgImageHole = async (svgTemplate: string): Promise<string | false> => {
    const newSvg: Svg = stringToSVG(svgTemplate) as Svg;

    const images = getGroupUserImageArea(newSvg);
    if (images.length > 1) {
        return false;
    }

    images.forEach((image) => {
        const height = image.height();
        const width = image.width();

        image.width(height);
        image.height(width);
    });

    const blobOptions = { type: IMAGETYPE_SVG };
    const viewBox = newSvg.attr('viewBox');
    const viewBoxArray = viewBox.split(' ');
    const newViewBox = `${viewBoxArray[0]} ${viewBoxArray[1]} ${viewBoxArray[3]} ${viewBoxArray[2]}`;
    newSvg.attr('viewBox', newViewBox);
    newSvg.attr(ROTATION_LABEL, 'true');
    newSvg.attr('xmlns:svgjs', null);

    const blob = new Blob([newSvg.svg()], blobOptions);

    newSvg.remove();

    return URL.createObjectURL(blob);
};
