import { toJS } from 'mobx';
import { applySnapshot, getSnapshot, Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree';
import { setXLink } from '../helpers/setXLink';
import { getImageFromCache } from '../service/CacheStorage';
import { imageInit } from '../stage-helpers/initialize';
import { GenericSizeStore } from './GenericSizeStore';

import {
    ImageUrlStore,
    ImageUrlStoreExtendedInterface,
    ImageUrlStoreInterfaceSnapshotIn,
    ImageUrlStoreInterfaceSnapshotOut,
} from './ImageUrlStore';

export interface FilesPileStoreInterface extends Instance<typeof FilesPileStore> {}
export interface FilesPileStoreInterfaceSnapshotIn extends SnapshotIn<typeof FilesPileStore> {}
export interface FilesPileStoreInterfaceSnapshotOut extends SnapshotOut<typeof FilesPileStore> {}

export const FilesPileStore = types
    .model({
        images: types.array(ImageUrlStore),
    })
    .actions((self) => ({
        getMaxIndex(): number {
            return self.images.reduce((acc, { index }) => {
                if (index > acc) {
                    return index;
                } else {
                    return acc;
                }
            }, -1);
        },
    }))
    .actions((self) => ({
        getNewIndex(): number {
            return self.getMaxIndex() + 1;
        },
        initialize(newImage: Partial<ImageUrlStoreInterfaceSnapshotIn>): void {
            // launch side effect on volatile elment of image collection connected in this store
            self.images.map((image) => {
                if (!(image.index === newImage.index && image.stageId === newImage.stageId)) return;

                if (image.element && newImage.thumbnail && image.draw && image.stageId) {
                    imageInit(image.draw, image.element, image.index, image.stageId);
                }

                if (newImage.cached && image.element && image.element?.node) {
                    getImageFromCache({ ...image })
                        .then((cachedThumbnail) => {
                            setXLink(image.element?.node, cachedThumbnail);
                        })
                        .catch((err) => {
                            console.error('FilesPileStore initialize error:', err);
                        });
                }

                return image;
            });
        },
        clear() {
            self.images.forEach((pile) => {
                URL.revokeObjectURL(pile.thumbnail);
            });
            self.images.clear();
        },
    }))
    .actions((self) => ({
        existImage(newImage: Partial<ImageUrlStoreInterfaceSnapshotIn>): boolean {
            return (
                self.images.filter((image: ImageUrlStoreInterfaceSnapshotIn) => {
                    if (image.index === newImage.index && image.stageId === newImage.stageId) {
                        return true;
                    }
                    return false;
                }).length > 0
            );
        },
        replaceImage(newImage: Partial<ImageUrlStoreInterfaceSnapshotIn>): void {
            if (typeof newImage.stageId !== 'string') throw 'replaceImage: stageId is not valid';
            if (typeof newImage.index !== 'number') throw 'replaceImage: index is not valid';

            const newSnapshot = self.images.map((image) => {
                if (image.index === newImage.index && image.stageId === newImage.stageId) {
                    if (newImage.presignedImageURL) image.presignedImageURL = newImage.presignedImageURL;
                    if (newImage.svgRotate) image.svgRotate = newImage.svgRotate;
                    if (newImage.svgPreview) image.svgPreview = newImage.svgPreview;
                    if (newImage.svgProduction) image.svgProduction = newImage.svgProduction;
                    if (newImage.originSize) image.originSize.set(newImage.originSize);
                    if (newImage.thumbnailSize) image.thumbnailSize.set(newImage.thumbnailSize);
                    if (newImage.holeSize) image.holeSize.set(newImage.holeSize);
                    if (newImage.name) image.name = newImage.name;
                    if (newImage.type) image.type = newImage.type;
                    if (newImage.size) image.size = newImage.size;
                    if (newImage.cached) image.cached = newImage.cached;
                    if (newImage.thumbnail) image.thumbnail = newImage.thumbnail;
                    if (newImage.inView) image.inView = newImage.inView;

                    return image;
                }
                return image;
            });

            self.initialize(newImage);

            applySnapshot(self.images, newSnapshot);
        },
    }))
    .actions((self) => ({
        getImageByStageIdAndIndex(stageId: string, index: number): ImageUrlStoreExtendedInterface {
            return self.images
                .filter((item) => {
                    return item.index === index && stageId === item.stageId;
                })
                .reduce((acc, value) => {
                    acc = value;
                    return toJS(acc);
                }, {} as ImageUrlStoreExtendedInterface);
        },
        getImageByStageId(stageId: string) {
            return self.images.reduce((acc: any, value: ImageUrlStoreInterfaceSnapshotOut) => {
                if (value.stageId === stageId) {
                    return value;
                }
                return acc;
            }, undefined);
        },
        getImagesByStageId(stageId: string) {
            return self.images.filter((item) => {
                return stageId === item.stageId;
            });
        },
        setImageByindex(newImage: Partial<ImageUrlStoreInterfaceSnapshotIn>): void {
            if (self.existImage(newImage) === false) {
                self.images.push({
                    index: 0,
                    thumbnail: '',
                    stageId: '',
                    element: null,
                    svgRotate: false,
                    svgPreview: '',
                    svgProduction: '',
                    presignedImageURL: '',
                    outputSize: GenericSizeStore.create({ w: 0, h: 0 }),
                    thumbnailSize: GenericSizeStore.create({ w: 0, h: 0 }),
                    originSize: GenericSizeStore.create({ w: 0, h: 0 }),
                    holeSize: GenericSizeStore.create({ w: 0, h: 0 }),
                    cached: false,
                    inView: false,
                    ...newImage,
                });
            } else {
                self.replaceImage(newImage);
            }
        },
        remove(stageId: string, index: number) {
            const newPile = self.images.filter((image: ImageUrlStoreInterfaceSnapshotIn) => {
                if (image.index === index && image.stageId === stageId) {
                    URL.revokeObjectURL(image.thumbnail);
                    return false;
                }
                return true;
            });

            applySnapshot(self.images, newPile);
        },
        enrichImage(data: Partial<ImageUrlStoreExtendedInterface>) {
            applySnapshot(
                self.images,
                self.images.map((image) => {
                    if (image.index === data.index && image.stageId === data.stageId) {
                        image.set(data);
                        return image;
                    }
                    return image;
                })
            );
        },
    }))
    .actions((self) => ({
        getImages(): ImageUrlStoreInterfaceSnapshotOut[] {
            return getSnapshot(self.images);
        },
        setImages(images: ImageUrlStoreInterfaceSnapshotIn[]) {
            images.forEach((image) => {
                self.setImageByindex(image);
            });
        },
    }))
    .actions((self) => ({
        put(args: Partial<ImageUrlStoreInterfaceSnapshotIn>) {
            if (typeof args.stageId !== 'string') throw 'FilePileStore put: stageId is not valid';
            const index = typeof args.index === 'number' ? args.index : self.getNewIndex();
            self.setImageByindex({ ...args, index });
            return self.getImageByStageIdAndIndex(args.stageId, index);
        },
        addPresigned(newImage: Partial<ImageUrlStoreInterfaceSnapshotIn>): Partial<ImageUrlStoreInterfaceSnapshotOut> {
            const index = self.getNewIndex();
            const presignedImage = Object.assign(newImage, { index, stageId: newImage.stageId });
            self.setImageByindex(presignedImage);
            return presignedImage;
        },
        isValidOriginImage(stageId: string, index: number) {
            return self.images
                .filter((image: ImageUrlStoreInterfaceSnapshotIn) => image.index === index && image.stageId === stageId)
                .reduce((acc, value) => {
                    acc = value.originSize.h > 0 && value.originSize.w > 0;
                    return acc;
                }, false);
        },
    }));
