import { detect } from 'detect-browser';

export const forceNodeRedraw = (target: HTMLElement | SVGElement): void => {
    const browser = detect();

    if (!browser) {
        return;
    }

    const action = () => {
        if (!target.getAttribute('orig-display')) target.setAttribute('orig-display', target.style.display);
        target.style.display = 'unset';
        setTimeout(() => {
            target.style.display = 'block';
            setTimeout(() => (target.style.display = target.getAttribute('orig-display') || 'block'), 50);
        }, 50);
    };

    switch (browser.os) {
        case 'iOS':
            action();
            break;
    }

    switch (browser.name) {
        case 'ios':
        case 'safari':
            action();
            break;
    }
};

export default forceNodeRedraw;
