import { Element, MatrixAlias, Svg, SVG } from '@svgdotjs/svg.js';
import { toJS } from 'mobx';
import React from 'react';
import {
    DATA_SPINNER_ID,
    DEFAULT_FILTER,
    ORIG_HOLE_REF_ID,
    ORIG_PLACEHOLDER,
    ROTATION_LABEL,
    SVG_PREFIX,
    XLINK_ATTR,
} from '../environment/const';
import calculateAspectRatio from '../helpers/calculateAspectRatio';
import { getPseudoID } from '../helpers/getPseudoID';
import isDevEnv from '../helpers/isDev';
import { ApplyCustumizationToSVGInterface } from '../interface/ApplyCustumizationToSVGInterface';
import { PhotosiEditorSDK } from '../PhotosiEditorSDK';
import AdjustPreviewStore from '../state-manager/AdjustPreviewStore';
import adjustPreviewStoreInitialstate from '../state-manager/initial-config/adjustPreviewStoreInitialState';
import { SvgEditedElementsStoreInterfaceSnapshotIn } from '../state-manager/SvgEditedElementsStore';
import fill from '../svg-prod-export/elementTypes/fill';
import rect from '../svg-prod-export/elementTypes/rect';
import text from '../svg-prod-export/elementTypes/text';
import { applyAdjustment } from './applyAdjustment';
import { getGroupUserBkcolor, getGroupUserImageArea, getGroupUserTextArea, getPlaceholder } from './selectorsHelpers';
import { textGroupInit } from './textHelpers';

// IMPOSTA COLORE DI BACKGROUND
export function setBackgroundColorOnChange(
    event: React.ChangeEvent<HTMLSelectElement>,
    elements: Array<Element>
): Array<Element> {
    return setBackgroundColor(event.target.value, elements);
}

export const setBackgroundColor = (value: string, elements: Array<Element>): Array<Element> => {
    return elements.map((elem: Element) => {
        return elem.attr('fill', value);
    });
};
export const getBackgroundColor = (elements: Array<Element>): string => {
    return elements.map((elem: Element) => {
        return elem.attr('fill');
    })[0];
};

// ADDATTA ELEMENTO SVG PER SALVARLO IN STORE
export function elementAdapter(draw: Svg, stageId: string, elem: Element): SvgEditedElementsStoreInterfaceSnapshotIn {
    const filter = elem.attr('filter') || '';
    const match = filter.match(/\(\#([^)]+)\)/i);

    return {
        id: getPlaceholder(elem.attr('id') || ''),
        stageId: stageId,
        fill: elem.attr('fill') || '',
        filter: filter,
        fontSize: `${elem.attr('font-size') || ''}`,
        fontFamily: `${elem.attr('font-family') || ''}`,
        filterHTML: filter.length > 0 ? draw.findOne(`#${match[1]}`).svg() : '',
        textContent: elem.node.textContent || '',
        textContentOrigin: elem.attr(ORIG_PLACEHOLDER) || '',
        preserveAspectRatio: elem.attr('preserveAspectRatio') || '',
        href: elem.attr(XLINK_ATTR) || '',
        type: elem.type,
        adjustament: null,
    };
}

export const getCurruntCustomization = (stageId: string, draw: Svg) => {
    const imageGroup = getGroupUserImageArea(draw);
    const textGroup = getGroupUserTextArea(draw);
    const backgroundGroup = getGroupUserBkcolor(draw);
    const list = [...imageGroup, ...textGroup, ...backgroundGroup];

    return list.map((elem: Element) => {
        return elementAdapter(draw, stageId, elem);
    });
};

// SALVA IN STORE TUTTI GLI ELEMENTI CORRENTI
export const addToPreviewStore = (stageId: string, draw: Svg): Svg => {
    const { SvgPreviewStore } = PhotosiEditorSDK.getService();
    const currentStageData = getCurruntCustomization(stageId, draw);
    SvgPreviewStore.setCurrent(stageId, currentStageData);
    return draw;
};

interface HoleData {
    x: number;
    y: number;
    w: number;
    h: number;
}

export const getHoleOriginData = (draw: Svg, elem: Element): HoleData => {
    let originSvgHole: HoleData = {
        w: Number(elem.attr('width')) || 0,
        h: Number(elem.attr('height')) || 0,
        x: Number(elem.x()) || 0,
        y: Number(elem.y()) || 0,
    };

    const origin = draw.findOne(`#${elem.attr(ORIG_HOLE_REF_ID)}`);
    if (origin === null) return originSvgHole;

    const svgOrigin = SVG(origin);
    originSvgHole = {
        w: Number(svgOrigin.attr('width')) || 0,
        h: Number(svgOrigin.attr('height')) || 0,
        x: Number(svgOrigin.x()) || 0,
        y: Number(svgOrigin.y()) || 0,
    };

    return originSvgHole;
};

export const manageClipPath = (draw: Svg, element: Element) => {
    const originClipPathAttr = element.attr('clip-path');
    if (originClipPathAttr) {
        element.attr('clip-path', null);

        const originClipPathId = originClipPathAttr.replace(/(url\(|\)|#)/gi, '');
        const originClipPath = draw.node.getElementById(originClipPathId);

        const newClipPathId = `${originClipPathId}_${Date.now()}`;
        if (originClipPath) originClipPath.setAttribute('id', newClipPathId);

        if (!originClipPath) if (isDevEnv()) console.debug('originClipPath NOT FOUND');

        const editLayer = draw.node.getElementById('editLayer');
        if (editLayer) editLayer.setAttribute('clip-path', `url('#${newClipPathId}')`);
    }
    return element;
};

export const svgInit = (
    element: Element,
    imageSize: { w: number; h: number },
    holeSize: { w: number; h: number; x: number; y: number },
    imageURL: string
) => {
    if (imageURL && imageURL.length <= 0) {
        return element;
    }

    const moveX = 0 - (imageSize.w - holeSize.w) / 2;
    const moveY = 0 - (imageSize.h - holeSize.h) / 2;

    element.x(0);
    element.y(0);

    if (imageSize.w > holeSize.w) element.dx(moveX);

    if (imageSize.h > holeSize.h) element.dy(moveY);

    element.attr('orig-x', element.x());
    element.attr('orig-y', element.y());
    element.transform({
        origin: 'center',
        scale: 1,
        rotate: 0,
        translate: [0, 0],
    } as MatrixAlias);

    element.width(imageSize.w);
    element.height(imageSize.h);

    element.attr(XLINK_ATTR, imageURL);

    const spinnerId = element.attr(`${DATA_SPINNER_ID}`);
    if (spinnerId && spinnerId.length > 0) {
        const foreignObjectSpinner = document.getElementById(spinnerId);
        if (foreignObjectSpinner) foreignObjectSpinner.remove();
    }

    const rect = SVG().rect();
    rect.size(holeSize.w, holeSize.h);
    rect.x(holeSize.x);
    rect.y(holeSize.y);
    rect.id(`${SVG_PREFIX}_Rect_${element.id()}`);
    rect.attr('shape-rendering', 'crispEdges');

    element.attr(ORIG_HOLE_REF_ID, rect.id());

    const group = SVG().group();

    const clipPath = SVG().clip();
    clipPath.add(rect);

    const parent = element.parent();
    if (parent) parent.add(clipPath);

    group.clipWith(clipPath);
    group.node.style.transformOrigin = 'center';

    const elementWrapperIdPrefix = `${SVG_PREFIX}_wrapImage`;

    if (parent) parent.add(group);

    let elementWrapped = SVG(element.parent());
    if (parent && parent.id().indexOf(elementWrapperIdPrefix) === -1) {
        elementWrapped = SVG().add(element);
        group.add(elementWrapped);
    }

    elementWrapped.id(`${elementWrapperIdPrefix}_${getPseudoID()}`);
    elementWrapped.x(holeSize.x);
    elementWrapped.y(holeSize.y);
    elementWrapped.width(holeSize.w);
    elementWrapped.height(holeSize.h);

    return element;
};

export const initImageStores = (
    draw: Svg,
    element: Element,
    index: number,
    stageId: string,
    imageSize: { w: number; h: number },
    origHole: { w: number; h: number; x: number; y: number }
): void => {
    const { FilesPileStore, SvgPreviewStore } = PhotosiEditorSDK.getService();

    const tempAdjustStore = (() => AdjustPreviewStore.create(adjustPreviewStoreInitialstate))();

    FilesPileStore.enrichImage({
        stageId,
        index,
        draw,
        element,
    });

    const data = toJS(tempAdjustStore);
    const defaultSnap = data.stage;

    defaultSnap.transform.scale = defaultSnap.boundaries.scale.default;
    defaultSnap.transform.rotate = defaultSnap.boundaries.rotate.default;
    defaultSnap.transform.translate.x = defaultSnap.boundaries.translateX.default;
    defaultSnap.transform.translate.y = defaultSnap.boundaries.translateY.default;

    Object.assign(defaultSnap, { stageId: tempAdjustStore.stageId });
    Object.assign(defaultSnap, { id: tempAdjustStore.id });

    data.filter = DEFAULT_FILTER;

    data.stage = defaultSnap;

    data.stage.svghole.h = 1;
    data.stage.svghole.w = 1;

    data.orig.svghole.w = origHole.w;
    data.orig.svghole.h = origHole.h;

    data.orig.image.w = imageSize.w;
    data.orig.image.h = imageSize.h;

    data.id = element.id();

    data.stageId = stageId;
    data.index = index;
    const stageHoleSize = calculateAspectRatio(
        origHole.w,
        origHole.h,
        window.innerWidth * 0.8,
        window.innerHeight * 0.6,
        'fit'
    );

    if (imageSize.w + imageSize.h > 0) tempAdjustStore.setStage(data);
    tempAdjustStore.setDraw(draw);
    tempAdjustStore.setElement(element);
    tempAdjustStore.setStageHole({ w: stageHoleSize.width, h: stageHoleSize.height });

    const adjustment = SvgPreviewStore.getAdjustment(stageId, element.id());

    SvgPreviewStore.updateCurretByStageIdAndId(stageId, element.id(), {
        adjustament: adjustment || tempAdjustStore,
    });
};

export const imageInit = async (draw: Svg, element: Element, index: number, stageId: string): Promise<Element> => {
    const { FilesPileStore, SvgPreviewStore } = PhotosiEditorSDK.getService();

    const image = toJS(FilesPileStore.getImageByStageIdAndIndex(stageId, index));

    const origHole = getHoleOriginData(draw, element);

    draw.attr('xmlns:svgjs', null);
    draw.attr('style', 'filter: drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.3))');

    const elementLayer = draw.find('[id^="ELEMENT_LAYER" i],[id^="ELEMENTS_LAYER" i]');
    const rotationLabel = draw.attr(`${ROTATION_LABEL}`) || false;
    if (elementLayer && rotationLabel) elementLayer.forEach((item) => item.remove());

    let width = image.thumbnailSize.w,
        height = image.thumbnailSize.h;

    const currentImage = image.thumbnail;

    const aspectRatioSize = calculateAspectRatio(width, height, origHole.w, origHole.h, 'cover');

    const imageSizeParams = { w: aspectRatioSize.width, h: aspectRatioSize.height };

    initImageStores(draw, element, index, stageId, imageSizeParams, origHole);

    element = manageClipPath(draw, element);

    element = svgInit(element, imageSizeParams, origHole, currentImage);

    element.node.onclick = () => ShowAdjustImage(stageId, index);

    const adjustment = SvgPreviewStore.getAdjustment(stageId, element.id());
    if (adjustment) applyAdjustment(draw, element, adjustment);

    return element;
};

export const imageGroupInit = async (draw: Svg, stageId: string, index: number): Promise<Svg> => {
    const imageGroup = getGroupUserImageArea(draw);
    if (!imageGroup) return draw;

    for (const element of imageGroup) {
        await imageInit(draw, element, index, stageId);
    }

    return draw;
};

export const applyCustomizationToSvgPreview = async ({ draw, stageData }: ApplyCustumizationToSVGInterface) => {
    for (const current of stageData) {
        let elem: Element = draw.findOne(`[id^="${current.id}" i]`) as Element;
        let placeholder: Element = draw.findOne(`[id="placeholder_${current.id}" i]`) as Element;
        if (!elem) return;

        switch (current.type) {
            case 'rect':
                elem = rect(elem, current);
                break;
            case 'text':
                elem = fill(placeholder, current);
                elem = text(placeholder, current);
                if (placeholder) elem = fill(placeholder, current);
                if (placeholder) elem = text(placeholder, current);
                break;
        }
    }
    return draw;
};

export const lazyLoadStage = async (stageId: string, index: number, draw: Svg) => {
    const { SvgPreviewStore } = PhotosiEditorSDK.getService();

    const stageData = SvgPreviewStore.getStage(stageId);

    draw = await textGroupInit(draw, stageId);
    draw = await imageGroupInit(draw, stageId, index);

    await applyCustomizationToSvgPreview({ draw, stageData });

    draw.attr('width', '100%');

    return draw;
};

export const initStage = async (stageId: string, index: number, draw: Svg) => {
    draw = addToPreviewStore(stageId, draw);
    draw = await textGroupInit(draw, stageId);
    draw = await imageGroupInit(draw, stageId, index);
    draw.attr('width', '100%');

    return draw;
};

export const ShowAdjustImage = (stageId: string, index: number) => {
    const { FilesPileStore, SvgPreviewStore, AdjustPreviewStore } = PhotosiEditorSDK.getService();
    const { thumbnail, draw, element } = FilesPileStore.getImageByStageIdAndIndex(stageId, index);

    if (!draw || !element) throw 'failed to open adjust image';

    const elementId = `${element.id()}`;
    const adjustament = SvgPreviewStore.getAdjustment(stageId, elementId);

    const stageData = {
        ...adjustPreviewStoreInitialstate,
        ...adjustament,
        stageId,
        index,
        id: elementId,
        orig: {
            ...adjustament.orig,
            image: { w: element.width(), h: element.height() },
        },
        stage: {
            ...adjustament.stage,
        },
        image: thumbnail,
    };

    AdjustPreviewStore.setDraw(draw);
    AdjustPreviewStore.setElement(element);
    AdjustPreviewStore.setStage(stageData);
    AdjustPreviewStore.show();
};
