import { sizeCondition } from '../environment/sizeCondition';
import compressImage from '../helpers/compressImage';
import decodeImage from '../helpers/decodeImage';
import drawableToImageData from '../helpers/drawableToImageData';
import formatBytes from '../helpers/formatBytes';
import { ImageDataToJPEG } from '../helpers/imageDataToImage';
import isValidDrawable from '../helpers/isValidDrawable';
import math from '../helpers/math';
import { extension } from '../helpers/mimeType';
import removeImageObject from '../helpers/removeImageObject';
import resizeImage, { ResizeMode } from '../helpers/resizeImage';
import PhotosiEditorSDK from '../PhotosiEditorSDK';
import { MozjpegInterface } from './Mozjpeg';

let imageCompressedCounter = 1;

export type processMode = 'fast' | 'quality' | 'no-op';

export interface URLToMozJPEGInterface {
    mozjpeg: MozjpegInterface;
    file: File;
    maxSize?: number;
    mode?: processMode;
}

interface URLtoJPEGResultInterface {
    imageData: ImageData;
    compressionTime: number;
    resizeTime: number;
    initImageDataSize: number;
    ext?: string;
    originFile?: File;
    newFile?: File;
    fileOverweight?: boolean;
}

class ImageToJPEG {
    constructor(private mozjpeg: MozjpegInterface) {}
    /**
     * @desc set url and return jpg image (default self start the queue)
     * @param url
     * @param mode
     * @returns
     */
    public fromURL(file: File, maxSize?: number, mode: processMode = 'fast'): Promise<File> {
        if (!file) throw 'Compress JPEG Error:  File is undefined';

        return new Promise((resolve, reject) => {
            const start = performance.now();
            const processImageAction = async () => {
                let result: URLtoJPEGResultInterface = {
                    imageData: { data: { byteLength: 0 } } as ImageData,
                    compressionTime: 0,
                    resizeTime: 0,
                    initImageDataSize: 0,
                    ext: '',
                    originFile: undefined,
                    newFile: undefined,
                    fileOverweight: undefined,
                };

                try {
                    if (mode === 'no-op') {
                        if (PhotosiEditorSDK.debug()) console.debug(`no-op' ${file.name}`);
                        result.originFile = file;
                        result.newFile = file;
                    } else {
                        //@ts-ignore
                        result = await this.processImage(file, maxSize, mode);
                    }
                } catch (error) {
                    reject(error);
                    return;
                }

                if (!result.originFile) {
                    throw 'URL to JPEG Error: source file is lost';
                }

                if (!result.newFile) {
                    throw 'URL to JPEG Error: new file is lost';
                }

                if (PhotosiEditorSDK.debug()) {
                    console.debug(`%cnew Image (${imageCompressedCounter++})`, `color:#a100ff`);

                    console.debug(`
        isWeakDevice ${PhotosiEditorSDK.isWeakDevice()}

        URL:
            - source: ${file.name}
        
        Source File (${result.ext}):
            - size: ${formatBytes(result.originFile.size)}
            - overweight: ${result.fileOverweight}

        ImageData Resize ${math.round(result.resizeTime) <= 5 ? '(not resized)' : `(resized to ${maxSize}px)`}
            - before: ${formatBytes(result.initImageDataSize)}
            - after: ${formatBytes(result.imageData.data.byteLength)}

        File ${math.round(result.compressionTime) > 0 ? 'Compressed' : 'Not Compressed'} (${result.newFile.type})
            - size:  ${formatBytes(result.newFile.size)}

        new Image Time
            - resize: ${math.round(result.resizeTime)}ms
            - compress: ${math.round(result.compressionTime)}ms
            - encoder: ${
                mode === 'fast'
                    ? PhotosiEditorSDK.useOffscreenCanvas()
                        ? 'offscreen canvas (fast)'
                        : 'canvas (fast)'
                    : 'mozjpeg (quality)'
            }
            `);

                    const totalTime = math.round(performance.now() - start);
                    console.log(`%cProcess time: ${totalTime}ms`, `color:#${totalTime > 500 ? 'ea5d0b' : '5acec9'}`);
                }

                resolve(result.newFile);
            };

            const { mainQueue } = PhotosiEditorSDK.getGlobals();
            mainQueue.add(() => processImageAction(), { priority: 0 });
        });
    }
    private async processImage(file: File, maxSize?: number, quality?: ResizeMode) {
        if (PhotosiEditorSDK.isVerbose()) console.log(`processImage ${file.name}`);
        const originFile = file;

        let newFile = originFile;
        let compressionTime = 0;
        let resizeTime = 0;

        const ext = extension(file.type);
        const fileOverweight = sizeCondition(originFile.size);
        const tempURL = URL.createObjectURL(file);
        const imageObject = await decodeImage(tempURL);

        if (!isValidDrawable(imageObject)) return Promise.reject('processImage: failed to decode origin image');

        removeImageObject(imageObject);

        let imageData, initImageDataSize;
        if (!(fileOverweight || maxSize))
            return {
                imageData: { data: { byteLength: file.size } },
                ext,
                compressionTime,
                resizeTime,
                initImageDataSize,
                originFile,
                newFile,
                fileOverweight,
            };

        imageData = await drawableToImageData(imageObject);
        if (!imageData) return Promise.reject('processImage: image data is not valid');

        initImageDataSize = imageData.data.byteLength;

        const greaterSide = Math.max(
            imageObject.naturalWidth / window.devicePixelRatio,
            imageObject.naturalWidth / window.devicePixelRatio
        );

        const resizeStart = performance.now();

        if (maxSize && greaterSide > maxSize) imageData = await resizeImage(imageData, maxSize, quality);

        const resizeEnd = performance.now();
        resizeTime = resizeEnd - resizeStart;

        const compressStart = performance.now();

        switch (quality) {
            case 'quality':
                newFile = await compressImage(this.mozjpeg, imageData);
                break;
            default:
            case 'fast':
                if (initImageDataSize === imageData.data.byteLength && ext === 'jpg') {
                    newFile = originFile;
                } else {
                    newFile = await ImageDataToJPEG(imageData);
                }
                break;
        }

        const compressEnd = performance.now();

        compressionTime = compressEnd - compressStart;

        return {
            imageData: { data: { byteLength: imageData ? imageData.data.byteLength : file.size } },
            ext,
            compressionTime,
            resizeTime,
            initImageDataSize,
            originFile,
            newFile,
            fileOverweight,
        };
    }
}

export const imageToJpeg = async ({ mozjpeg, maxSize, file, mode = 'fast' }: URLToMozJPEGInterface): Promise<File> => {
    const imagetojpeg = new ImageToJPEG(mozjpeg);
    return await imagetojpeg.fromURL(file, maxSize, mode);
};

export default ImageToJPEG;
