import React, { useState } from 'react';
import PhotosiEditorSDKIntferace from '../interface/PhotosiEditorSDKIntferace';
import { StorageQuota } from './StorageQuota';

/*

import svgPreview from './Classic_Vertical_10x15.svg';
import svgProduction from './Classic_Vertical_10x15_Prod.svg';

import svgPreview from './PRE_Stampe_Vintage_20x25.svg';
import svgProduction from './PROD_Stampe_Vintage_20x25.svg';

import svgPreview from './calendario_preview.svg';
import svgProduction from './calendario_prod.svg';

import svgPreview from './../PRE_01.svg';
import svgProduction from './../PRO_01.svg';

*/

import vintageSvgPreview from './../PRE_Stampe_Vintage_20x25.svg';
import vintageSvgProduction from './../PROD_Stampe_Vintage_20x25.svg';

import classicSvgPreview from './../Classic_Vertical_10x15.svg';
import classicSvgProduction from './../Classic_Vertical_10x15_Prod.svg';

import calendarSvgPreview from './../PRE_01.svg';
import calendarSvgProduction from './../PRO_01.svg';

const productList = new Map();

productList.set('vintage', {
    svgPreview: vintageSvgPreview,
    svgProduction: vintageSvgProduction,
});

productList.set('classic', {
    svgPreview: classicSvgPreview,
    svgProduction: classicSvgProduction,
});

productList.set('calendar', {
    svgPreview: calendarSvgPreview,
    svgProduction: calendarSvgProduction,
});

import { MemoryQuota } from './MemoryQuota';
import styled from 'styled-components';
import PhotosiEditorSDK from './../PhotosiEditorSDK';

const chooseFiles = async (
    event: React.ChangeEvent<HTMLInputElement>,
    editorSDK: PhotosiEditorSDKIntferace.Method,
    svgPreview: string,
    svgProduction: string,
    setComponents: Function
) => {
    const outputSize = { w: 1080, h: 1920 };

    const svg = {
        preview: svgPreview,
        production: svgProduction,
        selfRotate: false,
    };

    const selfStart = true;

    const { target } = event;

    //@ts-ignore
    const files = Array.from(target.files);

    const promises = files.map(async (image, index) => {
        const stageId = `stage-id-${Date.now()}-${index}`;
        const options = {
            stageId,
            svg,
            outputSize,
            selfStart,
            priority: files.length - index,
        };
        return await editorComponent({ editorSDK, options, image });
    });

    const elements = [];
    for (let i = 0; i < promises.length; i++) {
        elements.push(await promises[i]);
    }

    return setComponents(elements);
};

const editorComponent = async ({
    editorSDK,
    options,
    image,
}: {
    editorSDK: PhotosiEditorSDKIntferace.Method;
    options: PhotosiEditorSDKIntferace.AddEditor;
    image: File;
}) => {
    const editor = await editorSDK.addEditor(options);
    await editor.replace(image);
    return editor.element(options.stageId);
};

interface DevIndexComponentInterface {
    editorSDK: PhotosiEditorSDKIntferace.Method;
}

const FixedWrapper = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 9;
`;

export const DevIndex: React.FC<DevIndexComponentInterface> = ({ editorSDK }) => {
    const [components, setComponents] = useState<JSX.Element[] | null>(null);
    const isWeakDevice = PhotosiEditorSDK.isWeakDevice();

    const [product, setProduct] = useState(productList.get('vintage'));

    return (
        <>
            <FixedWrapper>
                <StorageQuota />
                <MemoryQuota />
                <div style={{ padding: '5px', backgroundColor: '#ccc' }}>
                    <select onChange={(event) => setProduct(productList.get(event.target.value))}>
                        {Array.from(productList.keys()).map((key) => {
                            return (
                                <option key={key} value={key}>
                                    {key}
                                </option>
                            );
                        })}
                    </select>
                </div>
            </FixedWrapper>
            <div style={{ height: '90px' }}></div>
            {isWeakDevice ? '🌧️ ' : '💪🏻 '}
            <input
                type="file"
                accept=".jpg,.jpeg"
                multiple={true}
                onChange={(event) =>
                    chooseFiles(event, editorSDK, product.svgPreview, product.svgProduction, setComponents)
                }
            />
            {components?.map((stage, index) => {
                return (
                    <div key={`StageWrapper${index}`} style={{ minHeight: '100vh', width: '100%' }}>
                        {stage}
                    </div>
                );
            })}
        </>
    );
};
