import React from 'react';
import PhotosiEditorSDKIntferace from '../interface/PhotosiEditorSDKIntferace';
import PhotosiEditorSDK from '../PhotosiEditorSDK';
import { ImageUrlStoreExtendedInterface } from '../state-manager/ImageUrlStore';
import { ColorPalette } from '../components/ColorPalette';
import { addToPreviewStore, getBackgroundColor, setBackgroundColor } from './initialize';
import { getGroupUserBkcolor } from './selectorsHelpers';

export const applyColor = (color: PhotosiEditorSDKIntferace.Color, stageId: string) => {
    const { globalBottomDialog } = PhotosiEditorSDK.getGlobals();
    const { FilesPileStore } = PhotosiEditorSDK.getService();
    const image = FilesPileStore.getImageByStageId(stageId);
    setBackgroundColor(color.code, getGroupUserBkcolor(image.draw));
    addToPreviewStore(stageId, image.draw);
    globalBottomDialog.hide();
};

export const applyColorToAllPreview = (color: PhotosiEditorSDKIntferace.Color) => {
    const { globalBottomDialog } = PhotosiEditorSDK.getGlobals();
    const { FilesPileStore } = PhotosiEditorSDK.getService();
    FilesPileStore.images.forEach((image: ImageUrlStoreExtendedInterface) => {
        if (image.stageId && image.draw) {
            setBackgroundColor(color.code, getGroupUserBkcolor(image.draw));
            addToPreviewStore(image.stageId, image.draw);
        }
    });
    globalBottomDialog.hide();
};

export const ChooseBgColor = (stageId: string): boolean => {
    const { globalBottomDialog } = PhotosiEditorSDK.getGlobals();
    const { FilesPileStore } = PhotosiEditorSDK.getService();
    const image = FilesPileStore.getImageByStageId(stageId);
    const { backgroundPalette } = PhotosiEditorSDK.getConfig();

    const imageGroup = getGroupUserBkcolor(image.draw);
    if (imageGroup.length <= 0) {
        return false;
    }
    const currentColor = getBackgroundColor(imageGroup);

    const newColorList = (backgroundPalette || []).map((item: PhotosiEditorSDKIntferace.Color) => {
        if (item && currentColor && item.code && item.code.toLowerCase() === currentColor.toLowerCase()) {
            item.selected = true;
        } else {
            item.selected = false;
        }
        return item;
    });

    globalBottomDialog.setChildren(
        <ColorPalette
            stageId={stageId}
            colors={newColorList}
            actions={{
                applyColor: applyColor,
                applyColorToAllPreview: applyColorToAllPreview,
            }}
        />
    );
    globalBottomDialog.show();

    return true;
};
