import { getPseudoID } from '../helpers/getPseudoID';
import { extension } from '../helpers/mimeType';
import { PhotosiEditorSDK } from '../PhotosiEditorSDK';
import { ImageUrlStoreExtendedInterface } from '../state-manager/ImageUrlStore';
import { createProductionSVG } from '../svg-prod-export/createProductionSVG';
import { stringToSVG } from './SvgHandler';

export const productionSVGAction = async (
    { svgProduction, outputSize, stageId, index }: ImageUrlStoreExtendedInterface,
    resolveAddToQueue: Function
) => {
    const { axios } = PhotosiEditorSDK.getGlobals();

    let prodSvgTemplate: string;
    try {
        const { data } = await axios.get(svgProduction);
        prodSvgTemplate = data;
    } catch (error) {
        return Promise.reject('productionSVGAction: failed to get production svg');
    }

    const imageSize = { width: outputSize.w, height: outputSize.h };
    const svg = stringToSVG(prodSvgTemplate);

    const blob = await createProductionSVG(stageId, index, imageSize, svg);

    const ext = extension(blob.type);
    const newFile = new File([blob], `export_${getPseudoID()}.${ext}`, { type: blob.type });

    resolveAddToQueue(newFile);
    return newFile;
};

export const ExportProductionSVG = (stageId: string, index: number) => {
    const { mainQueue } = PhotosiEditorSDK.getGlobals();
    return new Promise<File>((resolveAddToQueue) => {
        const { FilesPileStore } = PhotosiEditorSDK.getService();
        const image = FilesPileStore.getImageByStageIdAndIndex(stageId, index);
        mainQueue.add(() => productionSVGAction(image, resolveAddToQueue));
    });
};
