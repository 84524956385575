import FilterSVG from './FilterSVG';
import { Element } from '@svgdotjs/svg.js';
import PhotosiEditorSDK from '../PhotosiEditorSDK';
import forceNodeRedraw from '../helpers/forceNodeRedraw';
import { DEFAULT_FILTER } from '../environment/const';

export type filterName =
    | 'unfilter'
    | 'sepia'
    | 'sepium'
    | 'oldtime'
    | 'coldlife'
    | 'blackandwhite'
    | 'colorize'
    | 'warmlight'
    | 'brannan'
    | 'earlybird'
    | 'loFi'
    | 'mayfair'
    | 'nashville'
    | 'glowsaturate'
    | '';

export function filtersList() {
    // crossbrowser
    const filterList = [
        {
            name: DEFAULT_FILTER,
            value: DEFAULT_FILTER,
        },
        {
            name: 'sepia',
            value: 'sepia',
        },
        {
            name: 'sepium',
            value: 'sepium',
        },
        {
            name: 'oldtime',
            value: 'oldtime',
        },
        {
            name: 'coldlife',
            value: 'coldlife',
        },
        {
            name: 'blackandwhite',
            value: 'blackandwhite',
        },
        {
            name: 'colorize',
            value: 'colorize',
        },
    ];

    const browser = PhotosiEditorSDK.getBrowser();
    switch (browser && browser.os) {
        case 'iOS':
            return filterList;
    }

    // questi danno problemi su safari
    /*
    filterList.push(
        {
            name: 'glowsaturate',
            value: 'glowsaturate',
        },
        { name: 'warmlight', value: 'warmlight' },
        { name: 'brannan', value: 'brannan' },
        { name: 'earlybird', value: 'earlybird' },
        { name: 'loFi', value: 'loFi' },
        { name: 'mayfair', value: 'mayfair' },
        { name: 'nashville', value: 'nashville' }
    );
    */
    return filterList;
}

// IMPOSTA FILTRI
export function setFilterToElements(filterName: filterName, elements: Array<Element>) {
    return elements.map((elem: Element) => {
        const filter = new FilterSVG(elem);
        let target: Element | undefined;
        filter.unfilter();

        switch (filterName) {
            case '':
            case DEFAULT_FILTER:
                filter.unfilter();
                break;
            case 'sepia':
                target = filter.sepia();
                break;
            case 'sepium':
                target = filter.sepium();
                break;
            case 'oldtime':
                target = filter.oldtime();
                break;
            case 'coldlife':
                target = filter.coldlife();
                break;
            case 'blackandwhite':
                target = filter.blackAndWhite();
                break;
            case 'colorize':
                target = filter.colorize();
                break;
            /*
            case 'brannan':
                target = filter.brannan();
                break;
            case 'earlybird':
                target = filter.earlybird();
                break;
            case 'glowsaturate':
                target = filter.glowsaturate();
                break;
            case 'warmlight':
                target = filter.warmlight();
                break;
            case 'loFi':
                target = filter.loFi();
                break;
            case 'mayfair':
                target = filter.mayfair();
                break;
            case 'nashville':
                target = filter.nashville();
                break;
                */
            default:
                console.error(`filter not supported: ${filterName}`);
                break;
        }

        if (target) forceNodeRedraw(target.node);

        return target;
    });
}
