import React from 'react';
import styled from 'styled-components';
import { GLOBAL_HORIZONTAL_LOADER_ID } from '../environment/const';

const Loader = styled.div`
    padding: 0px;
    position: fixed;
    bottom: 0px;
    right: 0px;
    z-index: 1000;
    width: 100%;
    height: 10px;
    margin: 0px;
    overflow: hidden;
    .hide {
        display: none;
    }

    svg {
        height: 100%;
        width: 100%;
        #bg-item {
            height: 10px;
            width: 100%;
            background: '#000000';
            opacity: 0.3;
        }
        #cursor {
            padding: 0;
            margin: 0;
            height: 10px;
            width: 10%;
            fill: ${(props) => props.theme.colors.photosi.green};
        }
    }
`;

export const GlobalLoaderHorizontal: React.FC = () => {
    return (
        <Loader id={GLOBAL_HORIZONTAL_LOADER_ID} className="hide">
            <svg xmlns="http://www.w3.org/2000/svg" height="100%" width="100%">
                <rect id="bg-item"></rect>
                <rect id="cursor">
                    <animate attributeName="x" values="0%;90%;0%" dur="5s" repeatCount="indefinite" />
                </rect>
            </svg>
        </Loader>
    );
};

export default GlobalLoaderHorizontal;
