import { inject } from 'mobx-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import HidingObservableComponentInterface from '../../interface/HidingObservableComponentInterface';
import { ProvidedService } from '../../interface/ProvidedService';
import { FilesPileStoreInterface } from '../../state-manager/FilesPileStore';
import { MainThemeInterface } from '../../theme/main';
import Icon from '../Icon';
import ColorChooserIcon from './../../../assets/images/svg/raggruppa777.svg';
import { ChooseBgColor } from '../../stage-helpers/backgroundColors';

export interface ColorPickerComponentInterface {
    readonly globalBottomDialog?: HidingObservableComponentInterface;
    readonly filesPileStore?: FilesPileStoreInterface;
    readonly theme?: MainThemeInterface;
    stageId: string;
}

export const ColorPicker: React.FC<any> = inject((provide: ProvidedService) => ({
    globalBottomDialog: provide.globals.globalBottomDialog,
    filesPileStore: provide.service.FilesPileStore,
    theme: provide.theme,
}))(({ globalBottomDialog, theme, stageId, filesPileStore }) => {
    if (!globalBottomDialog || !theme || !filesPileStore) {
        return <>failed to initialize color picker</>;
    }

    const { t } = useTranslation('color-picker');
    return (
        <Icon
            label={t('colore')}
            src={ColorChooserIcon}
            onClick={() => {
                ChooseBgColor(stageId);
            }}
        />
    );
});
