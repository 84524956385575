import { MozjpegInterface, MozjpegWorkerMessageOutput } from '../service/Mozjpeg';
import { imageDataToPNG } from './imageDataToImage';

export const compressImage = (mozjpeg: MozjpegInterface, imageData: ImageData): Promise<File> => {
    return new Promise((resolve, reject) => {
        mozjpeg.postMessage(
            {
                action: '/compress',
                imageData: imageData,
            },
            async (message: MozjpegWorkerMessageOutput) => {
                if (!message.error) {
                    resolve(message.output as File);
                } else {
                    console.error(message.error);
                    // SE IL WASM SI E' ROTTO, TORNA IL FILE ORIGINALE
                    if (message.output instanceof ImageData) {
                        const newOrigin = await imageDataToPNG(message.output);
                        resolve(newOrigin);
                    } else if (message.output instanceof File) {
                        resolve(message.output);
                    } else {
                        console.error('compressImage (mozjpeg) failed', message.error);
                        reject(message.error);
                    }
                }
            }
        );
    });
};
export default compressImage;
