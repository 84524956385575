import { clearCanvas } from './clearCanvas';
import { clearCanvasRenderingContext2D } from './clearCanvasRenderingContext2D';
import { createCanvasElement } from './createCanvasElement';

export const canvasEncoder = async (data: ImageData, type?: string, quality?: number): Promise<Blob> => {
    let canvas = createCanvasElement();
    if ('id' in canvas) canvas.id = 'canvasEncoder';
    canvas.width = data.width;
    canvas.height = data.height;

    let ctx = canvas.getContext('2d');
    if (!ctx) throw Error('Canvas not initialized');
    ctx.putImageData(data, 0, 0);

    let blob: Blob = new Blob();

    try {
        if ('convertToBlob' in canvas) {
            blob = await canvas.convertToBlob({
                type,
                quality,
            });
        } else if ('toBlob' in canvas) {
            const createBlob = new Promise<Blob>((resolve, reject) => {
                //@ts-ignore
                canvas.toBlob(
                    (blob: Blob) => {
                        if (blob.size <= 0) return Promise.reject('canvasEncoder: Blob is empty (0)');
                        if (!blob) return reject('canvasEncoder: create blob failed');
                        return resolve(blob);
                    },
                    type,
                    quality
                );
            });

            blob = await createBlob;
        }
    } catch (error) {
        clearCanvasRenderingContext2D(ctx, data.width, data.height);
        clearCanvas(canvas);
        return Promise.reject(error);
    }

    clearCanvasRenderingContext2D(ctx, data.width, data.height);
    clearCanvas(canvas);

    if (blob.size <= 0) return Promise.reject('canvasEncoder: Blob is empty (1)');
    if (!blob) return Promise.reject('canvasEncoder: Encoding failed');

    return blob;
};

export default canvasEncoder;
