import { SvgEditedElementsStoreInterface } from '../../state-manager/SvgEditedElementsStore';
import { Element } from '@svgdotjs/svg.js';
export const text = (elem: Element, current: SvgEditedElementsStoreInterface) => {
    if (current.textContent === current.textContentOrigin || current.textContentOrigin === '') return elem;
    elem.node.textContent = '';
    elem.node.textContent = `${current.textContent}`;
    elem.attr('font-family', current.fontFamily);
    elem.attr('font-size', current.fontSize);

    return elem;
};

export default text;
