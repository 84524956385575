import { BENCH_TEST_RESIZE_EXEC_MS, BENCH_TEST_RESULT_STORAGE_KEY } from '../environment/const';
import decodeImage from '../helpers/decodeImage';
import drawableToImageData from '../helpers/drawableToImageData';
import { PhotosiEditorSDK } from '../PhotosiEditorSDK';
import { StorageWrapperInterface } from '../service/storage/StorageWrapper';
import resizeImage from '../helpers/resizeImage';
import { testImage } from '../helpers/testImageBase64';
import { toMB } from '../helpers/toMB';
import math from '../helpers/math';

export type BenchTestResizeResult = 'ok' | 'timeout' | 'fail' | 'weak' | 'powerless';

export const getBenchResizeStatus = (storage: StorageWrapperInterface): BenchTestResizeResult | void => {
    return storage.load(BENCH_TEST_RESULT_STORAGE_KEY) as BenchTestResizeResult;
};

const hardwarePreEvaluation = (): BenchTestResizeResult | void => {
    let result: BenchTestResizeResult | undefined;

    const deviceMemory = window.navigator?.deviceMemory || false;
    const hardwareConcurrency = window.navigator.hardwareConcurrency || false;
    const heapSizeJS = window.performance.memory?.jsHeapSizeLimit || false;

    // JS MEMORY
    if (!result && typeof heapSizeJS === 'number' && toMB(heapSizeJS) <= 1500) result = 'powerless';

    // RAM
    if (!result && typeof deviceMemory === 'number' && deviceMemory <= 3) result = 'weak';

    //CORE CPU
    if (!result && typeof hardwareConcurrency === 'number' && hardwareConcurrency <= 2) result = 'weak';

    return result;
};

const tryResize = async (imageData: ImageData): Promise<BenchTestResizeResult> => {
    let result: BenchTestResizeResult = 'fail';

    const controller = new AbortController();
    const { signal: abortSignal } = controller;

    try {
        const start = window.performance.now();
        const timeout = setTimeout(() => {
            controller.abort();
            clearTimeout(timeout);
        }, BENCH_TEST_RESIZE_EXEC_MS * 1.01);

        await resizeImage(imageData, 100, 'quality', abortSignal);

        const end = window.performance.now();
        const execTime = end - start;
        if (PhotosiEditorSDK.debug()) console.log(`bench test resize: ${math.roundFixedTwo(execTime)} ms`);

        if (execTime < BENCH_TEST_RESIZE_EXEC_MS) {
            result = 'ok';
        } else {
            result = 'timeout';
        }

        if (timeout) clearTimeout(timeout);
    } catch (err) {
        try {
            if (imageData) imageData.data.set(new Uint8ClampedArray([0]));
        } catch (error) {
            if (PhotosiEditorSDK.debug()) console.error('failed to unload image data', error);
        }
        if (PhotosiEditorSDK.debug()) console.error('failed to run bench test for resize', err);
        result = 'timeout';
    }

    return result;
};

export const BenchTestResize = async (): Promise<BenchTestResizeResult> => {
    const hardwareEvaluationResult = hardwarePreEvaluation();
    if (hardwareEvaluationResult) return hardwareEvaluationResult;

    const decoded = await decodeImage(testImage());
    const imageData = await drawableToImageData(decoded);
    decoded.remove();

    if (!imageData) throw 'BenchTestResize: failed to get image data';

    const result = await tryResize(imageData);

    if (PhotosiEditorSDK.debug()) console.log(`bench test resize result is: ${result}`);

    return result === 'fail' && hardwareEvaluationResult ? hardwareEvaluationResult : result;
};
