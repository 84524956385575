import math from './math';

/**
    @desc THANKS ytiurin
    @github https://github.com/ytiurin/downscale/blob/master/src/downsample.js
*/
export function imageDataMathDownsample(
    sourceImageData: ImageData,
    destWidth: number,
    destHeight: number,
    sourceX: number = 0,
    sourceY: number = 0,
    sourceWidth: number = sourceImageData.width,
    sourceHeight: number = sourceImageData.height
) {
    const dest = new ImageData(destWidth, destHeight);

    const { height: destImageHeight, width: destImageWidth } = dest;

    const SOURCE_WIDTH = sourceImageData.width;

    const SOURCE_BUFFER = sourceImageData.data.buffer;
    const DEST_BUFFER = dest.data.buffer;

    const SOURCE_DATA = new Int32Array(SOURCE_BUFFER);
    const DEST_DATA = new Int32Array(DEST_BUFFER);
    const DEST_WIDTH = destImageWidth;

    const SCALE_FACTOR_X = destImageWidth / sourceWidth;
    const SCALE_FACTOR_Y = destImageHeight / sourceHeight;
    const SCALE_RANGE_X = math.round(1 / SCALE_FACTOR_X);
    const SCALE_RANGE_Y = math.round(1 / SCALE_FACTOR_Y);
    const SCALE_RANGE_SQR = SCALE_RANGE_X * SCALE_RANGE_Y;

    for (let destRow = 0; destRow < destImageHeight; destRow++) {
        for (let destCol = 0; destCol < DEST_WIDTH; destCol++) {
            let sourceInd =
                sourceX +
                math.round(destCol / SCALE_FACTOR_X) +
                (sourceY + math.round(destRow / SCALE_FACTOR_Y)) * SOURCE_WIDTH;

            let destRed = 0;
            let destGreen = 0;
            let destBlue = 0;
            let destAlpha = 0;

            for (let sourceRow = 0; sourceRow < SCALE_RANGE_Y; sourceRow++)
                for (let sourceCol = 0; sourceCol < SCALE_RANGE_X; sourceCol++) {
                    let sourcePx = SOURCE_DATA[sourceInd + sourceCol + sourceRow * SOURCE_WIDTH];
                    destRed += (sourcePx << 24) >>> 24;
                    destGreen += (sourcePx << 16) >>> 24;
                    destBlue += (sourcePx << 8) >>> 24;
                    destAlpha += sourcePx >>> 24;
                }

            destRed = math.round(destRed / SCALE_RANGE_SQR);
            destGreen = math.round(destGreen / SCALE_RANGE_SQR);
            destBlue = math.round(destBlue / SCALE_RANGE_SQR);
            destAlpha = math.round(destAlpha / SCALE_RANGE_SQR);

            DEST_DATA[destCol + destRow * DEST_WIDTH] =
                (destAlpha << 24) | (destBlue << 16) | (destGreen << 8) | destRed;
        }
    }

    return dest;
}
