import React, { Suspense } from 'react';
import { ThemeProvider } from 'styled-components';
import { GLOBAL_CONTAINER_ID, PREVIEV_CONTAINER_ID } from '../environment/const';
import { ProvidedService } from '../interface/ProvidedService';

import { GlobalStyle } from '../theme/GlobalStyle';
import { AdjustImage } from './AdjustImage';
import { CustomProvider } from './CustomProvider';
import { GlobalBottomDialog } from './GlobalBottomDialog';
import { GlobalLoaderHorizontal } from './GlobalLoaderHorizontal';
import { GlobalSpinner } from './GlobalSpinner';
import { PreviewContainer } from './PreviewContainer';

export interface PhotoEditorInterface {
    provide: ProvidedService;
}

export const PhotoEditor: React.FC<PhotoEditorInterface> = ({ provide }) => {
    return (
        <div id={GLOBAL_CONTAINER_ID}>
            <ThemeProvider theme={provide.theme}>
                <GlobalStyle />
                <GlobalSpinner />
                <GlobalLoaderHorizontal />
                <Suspense fallback={<></>}>
                    <CustomProvider service={provide}>
                        <GlobalBottomDialog key={`GlobalBottomDialog`} />
                        <AdjustImage key={`AdjustImage`} />
                    </CustomProvider>
                </Suspense>
                <PreviewContainer id={PREVIEV_CONTAINER_ID}></PreviewContainer>
            </ThemeProvider>
        </div>
    );
};

export default PhotoEditor;
