import { Provider } from 'mobx-react';
import React, { Suspense } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { PhotosiEditorSDK } from '../PhotosiEditorSDK';
import { MainTheme } from '../theme/main';
import { DevActionButton } from '../components/DevActionButton';
import { Stage } from '../components/Stage';
import { Subject } from 'rxjs';

export interface CreatePreviewInterface {
    index: number;
    stageId: string;
}

export interface PreviewComponent {
    forceRedraw: Subject<number>;
}

export const StageContainer = styled.div`
    text-align: center;
    padding: 0.1rem;
    margin-left: auto;
    margin-right: auto;
    height: 100%;
    .grid {
        margin: auto;
        width: 100%;
        display: grid;
        grid-template-columns: 25% 25% 25% 25%;
        .column {
            select {
                width: 6rem;
            }
            margin: auto;
        }
    }
`;

export const CreatePreview = async ({
    index,
    stageId,
}: CreatePreviewInterface): Promise<React.FC<PreviewComponent>> => {
    const Component: React.FC<PreviewComponent> = ({ forceRedraw }) => {
        const provide = PhotosiEditorSDK.Provider();

        const editorConfig = PhotosiEditorSDK.getConfig();

        let stageButton;
        if (editorConfig.previewStage) ({ stageButton } = editorConfig.previewStage);

        return (
            <Suspense fallback={<></>}>
                <ThemeProvider theme={MainTheme}>
                    <Provider {...provide}>
                        <StageContainer
                            {...{ index, stageId }}
                            key={`SvgPreview-${index}-${stageId}`}
                            style={{ margin: 'auto' }}
                        >
                            <Stage {...{ stageId, index, forceRedraw }}>
                                {stageButton && <DevActionButton {...{ stageId, index }} />}
                            </Stage>
                        </StageContainer>
                    </Provider>
                </ThemeProvider>
            </Suspense>
        );
    };

    return Component;
};
