import PhotosiEditorSDK from './../PhotosiEditorSDK';
import calcOriginSize from '../helpers/calcOriginSize';
import decodeImage from '../helpers/decodeImage';
import { imageSizeClamp } from '../helpers/imageSizeCalc';
import removeImageObject from '../helpers/removeImageObject';
import PhotosiEditorSDKIntferace from '../interface/PhotosiEditorSDKIntferace';
import { isCached } from './CacheStorage';

import { EditorFactory, EditorFactoryOutputInterface } from './EditorFactory';
import NormalizeSVGOrientation from './NormalizeSVGOrientation';
import { getSVGFeatures, stringToSVG } from './SvgHandler';
import { getImageCacheName } from '../helpers/getImageCacheName';
import { addToPreviewStore } from '../stage-helpers/initialize';

const SIZE_TOLERANCE_MULTIPLIER = 1.5;

export const ProcessEditor = async ({
    service,
    globals,
    image,
    stageId,
    index,
    svg,
    outputSize,
    presignedImageURL = '',
    priority,
    replace = false,
    selfStart = true,
}: PhotosiEditorSDKIntferace.ProcessEditor): Promise<EditorFactoryOutputInterface> => {
    const { serviceQueue, axios } = globals;

    const addEditorPromise = new Promise<any>((resolve) => {
        const addEditorAction = async () => {
            if (typeof index === 'number' && typeof stageId === 'string') {
                service.FilesPileStore.remove(stageId, index);
            }

            const cacheName = getImageCacheName({ name: image?.name || '', size: image?.size || 0 });

            ({ index } = service.FilesPileStore.put({
                index,
                stageId,
                svgPreview: svg.preview,
                svgProduction: svg.production,
                svgRotate: svg.selfRotate,
                presignedImageURL,
                outputSize,
                cached: image && image?.name ? await isCached(cacheName) : false,
            }));

            const { data: svgTemplatePreview } = await axios.get(svg.preview);
            const { svgFeatures } = await getSVGFeatures(svgTemplatePreview);

            const previewCustomizations = service.SvgPreviewStore.getStage(stageId);
            if (previewCustomizations && replace === true) {
                service.SvgPreviewStore.resetImageAdjustment(stageId);
            } else {
                addToPreviewStore(stageId, stringToSVG(svgTemplatePreview));
            }

            if (svgFeatures.userImagesCount > 0 && service.SvgPreviewStore.length() <= 0) {
                return Promise.reject('ProcessEditor userImage not found in preview svg');
            }

            if (image) {
                const origin = URL.createObjectURL(image);
                const imageObject = await decodeImage(origin);

                const originSize = calcOriginSize(imageObject);

                const { name, type, size } = image;

                const { maxHoleSize } = svgFeatures;

                const maxSize = Math.max(
                    maxHoleSize.width,
                    maxHoleSize.height,
                    window.innerHeight * SIZE_TOLERANCE_MULTIPLIER,
                    window.innerWidth * SIZE_TOLERANCE_MULTIPLIER
                );

                let thumbnailSize = imageSizeClamp(originSize, maxSize);

                const config = PhotosiEditorSDK.getConfig();
                if (config.thumbnailSize) {
                    thumbnailSize = imageSizeClamp(
                        originSize,
                        Math.max(config.thumbnailSize.width, config.thumbnailSize.height)
                    );
                }

                ({ svg, outputSize } = await NormalizeSVGOrientation({
                    svg,
                    outputSize,
                    originSize,
                    svgDocument: svgTemplatePreview,
                    maxHoleSize,
                }));

                service.FilesPileStore.replaceImage({
                    index,
                    stageId,
                    svgPreview: svg.preview,
                    svgProduction: svg.production,
                    holeSize: { w: maxHoleSize.width, h: maxHoleSize.height },
                    thumbnailSize,
                    originSize,
                    name,
                    type,
                    size,
                });

                removeImageObject(imageObject);
            }

            const start = async () => {
                if (typeof index !== 'number') throw 'failed to assign index. is not valid';
                if (typeof stageId !== 'string') throw 'failed to assign stageId, is not valid';

                if (!image) return console.warn('start without image');

                return service.editor.setImage({
                    index,
                    stageId,
                    priority,
                    image,
                });
            };

            if (typeof index !== 'number') throw 'failed to assign index to new editor';

            if (replace === true) {
                const editorItems = service.EditorPileStore.getEditor(stageId);

                const result = {
                    ...editorItems,
                    svgFeatures: { ...svgFeatures },
                    start,
                };

                resolve(result);
                return result;
            }

            const editorItems = await EditorFactory({ index, stageId, service, globals });

            const result = {
                ...editorItems,
                svgFeatures: { ...svgFeatures },
                start,
            };

            service.EditorPileStore.setEditor(stageId, editorItems);

            resolve(result);

            return result;
        };

        serviceQueue.add(() => addEditorAction());
    });

    const editor = await addEditorPromise;
    if (selfStart === true) editor.start();

    return editor;
};

export default ProcessEditor;
