import { ConnectableObservable, Observable, of, throwError } from 'rxjs';
import { mergeMap, publish } from 'rxjs/operators';
import { getPseudoID } from '../helpers/getPseudoID';
import { fromWorkerEvent } from '../observable/fromWorkerEvent';
import PhotosiEditorSDK from '../PhotosiEditorSDK';

export class AbstractWorker {
    private callbackIndex = '__callbackHash';
    private worker$: Observable<any>;
    private callbackPile: object = {};
    private wasm?: object = {};
    private worker: Worker;
    constructor(private origWorker: any, wasm?: object) {
        this.worker = new this.origWorker();
        this.worker.onerror = (error: ErrorEvent) => {
            console.error('worker error', error);
            //worker.terminate();
        };
        this.worker$ = fromWorkerEvent(this.worker);
        this.workerOutput$().connect();
        this.wasm = wasm;
    }
    private assignCallback(message: object, callback: Function): object {
        if (callback && typeof callback === 'function') {
            let indexHash: string = `${getPseudoID()}_${Date.now()}`;
            //@ts-ignore
            while (typeof this.callbackPile[indexHash] !== 'undefined') {
                const newHash = `R${indexHash}_${Date.now()}}`;
                if (PhotosiEditorSDK.debug()) {
                    console.debug(`AbstractWorker duplicate hash ${indexHash} replaced with ${newHash}`);
                }
                indexHash = newHash;
            }

            Object.assign(this.callbackPile, { [indexHash]: callback });

            return Object.assign(message, { [this.callbackIndex]: indexHash });
        }
        return message;
    }
    private outputCallback(args?: any) {
        return (message$: Observable<any>): Observable<any> => {
            return message$.pipe(
                mergeMap((message) => {
                    if (message.data === null) {
                        // RESET WORKER
                        this.worker.terminate();
                        this.worker = new this.origWorker();
                        this.worker$ = fromWorkerEvent(this.worker);
                        this.workerOutput$().connect();
                        return throwError(`Abstract worker error: "message is null"`);
                    }

                    try {
                        const indexHash: string = message.data[this.callbackIndex];
                        if (indexHash && indexHash.length > 0) {
                            //@ts-ignore
                            if (typeof this.callbackPile[indexHash] === 'function') {
                                //@ts-ignore
                                this.callbackPile[indexHash]({ ...message.data, ...args });
                                //@ts-ignore
                                this.callbackPile[indexHash] = null;
                                //@ts-ignore
                                delete this.callbackPile[indexHash];
                            }
                            //@ts-ignore
                            if (this.callbackPile[indexHash] instanceof Promise) {
                                (async () => {
                                    //@ts-ignore
                                    await this.callbackPile[indexHash]({ ...message.data, ...args });
                                    //@ts-ignore
                                    this.callbackPile[indexHash] = null;
                                    //@ts-ignore
                                    delete this.callbackPile[indexHash];
                                })();
                            }
                        }
                    } catch (err) {
                        console.error('callback function fail', err);
                        console.error({ message });
                        return throwError(err);
                    }

                    return of(message);
                })
            );
        };
    }
    protected workerPostMessage(message: object, transfer: Transferable[] = [], callback?: Function): void {
        if (callback && typeof callback === 'function') {
            this.assignCallback(message, callback);
        }
        if (this.wasm) Object.assign(message, { wasm: this.wasm });
        this.worker.postMessage(message, transfer);
    }
    protected workerOutput$(args?: any) {
        return this.worker$.pipe(this.outputCallback(args), publish()) as ConnectableObservable<any>;
    }
}
