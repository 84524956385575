import { SVG_PREFIX } from './../environment/const';
import { ImageUrlStoreInterfaceSnapshotIn } from '../state-manager/ImageUrlStore';

export const ImagesPileLoader = (imagesPile: ImageUrlStoreInterfaceSnapshotIn[]): ImageUrlStoreInterfaceSnapshotIn[] =>
    imagesPile.map((images, index: number) => {
        const newImages: ImageUrlStoreInterfaceSnapshotIn = { ...images };
        if (newImages.index) newImages.index = index;
        if (newImages.stageId) newImages.stageId = `${SVG_PREFIX}_Stage_${newImages.index || index}`;
        return newImages;
    });
