import { Svg, SVG } from '@svgdotjs/svg.js';
import React from 'react';
import EditText from '../components/EditText';
import { ORIG_PLACEHOLDER } from '../environment/const';
import translate from '../helpers/translate';
import { PhotosiEditorSDK } from '../PhotosiEditorSDK';
import { addToPreviewStore } from './initialize';
import { getGroupUserTextArea } from './selectorsHelpers';

export interface ApplyTextInterface {
    text: string;
    font: string;
    color: string;
}

export const textGroupInit = async (draw: Svg, stageId: string): Promise<Svg> => {
    const textGroup = getGroupUserTextArea(draw);
    if (!textGroup) return draw;

    for (const elem of textGroup) {
        const { globalBottomDialog } = PhotosiEditorSDK.getGlobals();
        if (elem.node.textContent || ''.length > 0) {
            const translation = await translate({
                ns: 'product_placeholder',
                key: `${elem.node.textContent}`,
            });
            const newPlaceholder = translation || elem.node.textContent || '';
            elem.node.textContent = newPlaceholder.substr(0, elem.attr('data-maxlength'));
            elem.attr(ORIG_PLACEHOLDER, newPlaceholder);
        }

        const textBox = elem.bbox();

        /**
         * Aggiungi un area cliccabile leggermente + grande dell'elemento di testo
         */
        const textAreaScale = 1.2;
        const rect = SVG().rect();
        rect.width(textBox.w * textAreaScale);
        rect.height(textBox.h * textAreaScale);
        rect.x(textBox.x - ((textBox.w * textAreaScale) / 2 - textBox.w / 2));
        rect.y(textBox.y - ((textBox.h * textAreaScale) / 2 - textBox.h / 2));
        rect.fill('transparent');
        rect.node.style.cursor = 'pointer';

        rect.on('click', () => {
            const applyText = ({ text, font, color }: ApplyTextInterface) => {
                elem.node.textContent = text.length <= 0 ? elem.node.dataset.origPlaceholder || '' : text;
                elem.attr('font-family', font);
                elem.attr('fill', color);
                addToPreviewStore(stageId, draw);

                globalBottomDialog.hide();
            };
            globalBottomDialog.setChildren(
                <EditText
                    actions={{ applyText }}
                    maxLength={elem.attr('data-maxlength') || null}
                    current={{
                        orig: elem.node.dataset.origPlaceholder || '',
                        text:
                            elem.node.dataset.origPlaceholder !== elem.node.textContent
                                ? elem.node.textContent || ''
                                : '',
                        color: elem.attr('fill') || '',
                        font: elem.attr('font-family') || '',
                    }}
                />
            );
            globalBottomDialog.show();
        });

        draw.add(rect);
    }

    return draw;
};
