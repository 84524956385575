import 'blueimp-canvas-to-blob';
import { detect } from 'detect-browser';
import { BenchTestResize, BenchTestResizeResult, getBenchResizeStatus } from './components/BenchTest';
import { defualtConfigPhotoEditorSdk } from './config/defaultConfigPhotosiEditorSDK';
import { photoEditorSDKMethod } from './config/photoEditorSDKMethod';
import { queueConcurrency } from './config/queueConcurrency';
import { BENCH_TEST_RESULT_STORAGE_KEY, GLOBAL_CONTAINER_ID } from './environment/const';
import { PhotosiEditorSDKIntferace } from './interface/PhotosiEditorSDKIntferace';
import { ProvidedService } from './interface/ProvidedService';
import PhotosiEditorManager from './PhotosiEditorManager';
import { MainThemeInterface } from './theme/main';
import { parse } from 'query-string';

export class PhotosiEditorSDK {
    private static config: PhotosiEditorSDKIntferace.Config;
    private static cacheStorage: boolean = false;
    private static verbose: boolean = false;
    private static provide: ProvidedService;
    static async initialize(config: PhotosiEditorSDKIntferace.Config): Promise<PhotosiEditorSDKIntferace.Method> {
        const defultConfig = defualtConfigPhotoEditorSdk();
        this.config = Object.assign(defultConfig, config);

        __webpack_public_path__ = this.config.publicPath || '';

        this.verbose = config.verbose || false;
        this.cacheStorage = config.cacheStorage || false;

        const editor = new PhotosiEditorManager(config);
        this.provide = await editor.initializeEditor();

        const { service, globals } = this.provide;

        let benchResult: BenchTestResizeResult = 'fail';
        try {
            benchResult = await BenchTestResize();
        } catch (err) {
            if (this.debug()) console.error('bench test failed', err);
        }

        service.storage.persist(BENCH_TEST_RESULT_STORAGE_KEY, benchResult);

        const concurrency = queueConcurrency(benchResult);

        // update queue concurrenby by device bench test result
        globals.mainQueue.concurrency = concurrency;

        if (this.debug()) console.log(`PhotoEditorSDK Config: queue Concurrency: ${concurrency}`);

        return photoEditorSDKMethod({ editor, provide: this.provide });
    }
    static isVerbose(): boolean {
        return this.verbose;
    }
    static isCacheEnabled(): boolean {
        return this.cacheStorage;
    }
    static Provider() {
        if (!this.provide.service) throw 'editor service not ready';
        return this.provide;
    }
    static getGlobalContainer(): HTMLElement | null {
        return document.getElementById(GLOBAL_CONTAINER_ID);
    }
    static getService(): ProvidedService['service'] {
        if (!this.provide.service) throw 'editor service not ready';
        return this.provide.service;
    }
    static getUtility(): ProvidedService['utility'] {
        if (!this.provide.utility) throw 'editor service not ready';
        return this.provide.utility;
    }
    static getGlobals(): ProvidedService['globals'] {
        if (!this.provide.globals) throw 'editor globals not ready';
        return this.provide.globals;
    }

    static getConfig(): PhotosiEditorSDKIntferace.Config {
        return this.config;
    }
    static useOffscreenCanvas(): boolean {
        if (!window.OffscreenCanvas || !createImageBitmap) {
            return false;
        }

        return this.getConfig().offscreenCanvas || false;
    }
    static isWeakDevice(): boolean {
        switch (this.getConfig().overrideDeviceType) {
            case 'strong':
                return false;
            case 'weak':
                return true;
        }

        return !(getBenchResizeStatus(this.provide.service.storage) === 'ok');
    }
    static getBrowser() {
        return detect();
    }
    static getTheme(): MainThemeInterface {
        if (!this.provide.theme) throw 'editor theme not ready';
        return this.provide.theme;
    }
    static getDOMinfo() {
        let countListeners = 0;
        const listenersInfo = Array.from(document.querySelectorAll('*')).reduce(function (acc: any, dom) {
            if (!window.getEventListeners) throw 'failed to get dom info';

            let eventListeners = window.getEventListeners(dom);
            Object.keys(eventListeners).forEach(function (key) {
                if (typeof acc[key] === 'undefined') acc[key] = 0;

                countListeners++;
                acc[key] += eventListeners[key].length;
            });
            return acc;
        }, {});

        return { listenersInfo, countListeners, domNodeQty: document.querySelectorAll('*').length };
    }
    static debug(): boolean {
        const { debug } = parse(window.location.search, { parseBooleans: true });
        return !!debug;
    }
}

export default PhotosiEditorSDK;
