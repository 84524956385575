export async function decodeImage(url: string): Promise<HTMLImageElement> {
    const img = new Image();
    img.decoding = 'async';
    img.crossOrigin = 'anonymous';

    const loaded = new Promise<void>((resolve, reject) => {
        img.onload = () => resolve();
        img.onerror = (err: unknown) => {
            console.error('Image loading error', JSON.stringify(err));
            reject('Image loading error');
        };
        img.src = url;
    });

    try {
        await loaded;
    } catch (error) {
        return Promise.reject('Failed to decode Image');
    }

    return img;
}

export default decodeImage;
