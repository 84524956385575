import {
    GenericSizeStoreInterfaceSnapshotIn,
    GenericSizeStoreInterfaceSnapshotOut,
} from './../state-manager/GenericSizeStore';
import { getImageOrientation } from '../helpers/getImageOrientation';
import { HoleSizeObject } from '../helpers/getMaxHoleSize';
import { rotateSvgImageHole } from './RotateSVG';

export interface PrepareImageFlowOInterface {
    svg: { preview: string; production: string; selfRotate?: boolean };
    originSize: GenericSizeStoreInterfaceSnapshotIn;
    outputSize: GenericSizeStoreInterfaceSnapshotIn;
    svgDocument: string;
    maxHoleSize: HoleSizeObject;
}

export interface NormalizedSVGInterface {
    svg: { preview: string; production: string; selfRotate?: boolean };
    outputSize: GenericSizeStoreInterfaceSnapshotOut;
    originSize: GenericSizeStoreInterfaceSnapshotOut;
    orientation: 'wide' | 'tall';
    maxHoleSize: HoleSizeObject;
    holeOrientation: 'wide' | 'tall';
    svgDocument: string;
}

export const NormalizeSVGOrientation = async ({
    svg,
    outputSize,
    originSize,
    svgDocument,
    maxHoleSize,
}: PrepareImageFlowOInterface): Promise<NormalizedSVGInterface> => {
    const orientation = getImageOrientation(originSize.w, originSize.h);
    const holeOrientation = getImageOrientation(maxHoleSize.width, maxHoleSize.height);

    let svgPreview = svg.preview;
    let svgProduction = svg.production;

    if (svg.selfRotate && orientation !== holeOrientation) {
        outputSize = { w: outputSize.h, h: outputSize.w };
        const svgPreviewRotated = await rotateSvgImageHole(svgDocument);
        if (svgPreviewRotated) svgPreview = svgPreviewRotated;
    }

    return {
        svg: { preview: svgPreview, production: svgProduction, selfRotate: svg.selfRotate },
        outputSize,
        orientation,
        maxHoleSize,
        holeOrientation,
        originSize,
        svgDocument,
    };
};

export default NormalizeSVGOrientation;
