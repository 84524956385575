import { STAGE_MULTIPLIER } from '../environment/const';
import { clamp } from '../helpers/clamp';

interface ImageSizeInterface {
    imageW: number;
    imageH: number;
}

export interface HoleSizeInterface {
    holeW: number;
    holeH: number;
}

interface BoundXYInterface {
    boundX: number;
    boundY: number;
}

export interface GenericXYInterface {
    x: number;
    y: number;
}

export interface BoundariesRangeInterface {
    x: { min: number; max: number };
    y: { min: number; max: number };
}

interface CalcRangeBoundInterface extends ImageSizeInterface, HoleSizeInterface {}

interface GetBoundInterface extends ImageSizeInterface, HoleSizeInterface {}

export class AdjustBoundaries {
    static getBound({ imageW, imageH, holeW, holeH }: GetBoundInterface) {
        if (!holeH || !holeW) {
            console.error('failed to get boundaries', { imageW, imageH, holeW, holeH });
        }

        const boundX = Math.abs((holeW - imageW) / STAGE_MULTIPLIER);
        const boundY = Math.abs((holeH - imageH) / STAGE_MULTIPLIER);
        return { boundX, boundY };
    }
    static translateRange({ boundX, boundY }: BoundXYInterface): BoundariesRangeInterface {
        return { x: { min: 0 - boundX, max: boundX }, y: { min: 0 - boundY, max: boundY } };
    }
    static transformClamp(
        currentX: number,
        currentY: number,
        translateRange: BoundariesRangeInterface
    ): GenericXYInterface {
        const x = clamp(currentX, translateRange.x.min, translateRange.x.max);
        const y = clamp(currentY, translateRange.y.min, translateRange.y.max);
        return { x, y };
    }
    static calcRangeBound({ imageW, imageH, holeW, holeH }: CalcRangeBoundInterface): BoundariesRangeInterface {
        if (!imageW || !imageH || !holeW || !holeH) {
            console.error('failed to calc range of boundaries ', { imageW, imageH, holeW, holeH });
        }
        const boundaries = AdjustBoundaries.getBound({
            holeW,
            holeH,
            imageW,
            imageH,
        });

        return AdjustBoundaries.translateRange(boundaries);
    }
}

export default AdjustBoundaries;
