export const clearCanvasRenderingContext2D = (
    ctx: CanvasRenderingContext2D | OffscreenCanvasRenderingContext2D | null,
    width: number,
    height: number
) => {
    if (!ctx) return;
    if ('beginPath' in ctx) ctx.beginPath();
    if ('clearRect' in ctx) ctx.clearRect(0, 0, width, height);

    ctx = null;
};
