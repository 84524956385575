export const extension = (mime: string) => {
    if (mime.indexOf(';') !== -1) {
        mime = mime.split(';')[0];
    }

    switch (mime) {
        case 'image/jpeg':
        case 'image/jpg':
            return 'jpg';
        case 'image/png':
            return 'png';
        case 'image/svg':
        case 'image/svg+xml':
            return 'svg';
        default:
            return;
    }
};
