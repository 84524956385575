import {
    ADJUST_MIN_SCALE,
    ADJUST_MAX_SCALE,
    ADJUST_MAX_ROTATION,
    ADJUST_MIN_ROTATION,
    DEFAULT_FILTER,
} from '../../environment/const';
import { AdjustPreviewStoreInterfaceSnapshotIn } from '../AdjustPreviewStore';

const boundaries = {
    translateX: {
        default: 0,
        step: 0.01,
        min: -2,
        max: 2,
    },
    translateY: {
        default: 0,
        step: 0.01,
        min: -2,
        max: 2,
    },
    scale: {
        default: 1,
        step: 0.01,
        min: ADJUST_MIN_SCALE,
        max: ADJUST_MAX_SCALE,
    },
    rotate: {
        default: 0,
        step: 1,
        min: ADJUST_MIN_ROTATION,
        max: ADJUST_MAX_ROTATION,
    },
};

const transform = {
    translate: { x: 0, y: 0 },
    rotate: 0,
    scale: ADJUST_MIN_SCALE,
};

export const stageInitialData = {
    transform,
    boundaries,
    size: { w: window.innerWidth, h: window.innerHeight * 0.675 },
    image: { w: 0, h: 0 },
    svghole: { w: 0, h: 0, x: 0, y: 0 },
};

export const adjustPreviewStoreInitialstate: AdjustPreviewStoreInterfaceSnapshotIn = {
    index: 0,
    stageId: '',
    visible: false,
    id: '',
    image: '',
    stage: stageInitialData,
    orig: stageInitialData,
    filter: DEFAULT_FILTER,
    mode: 'transform',
};

export default adjustPreviewStoreInitialstate;
