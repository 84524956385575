import React, { useState } from 'react';
import { useEffect } from 'react';
import styled from 'styled-components';
import { math } from '../helpers/math';
import { toMB } from '../helpers/toMB';
import PhotosiEditorSDK from '../PhotosiEditorSDK';

const QuotaPercentageContainer = styled.div`
    background: #bfbfbf;
    color: #ffffff;
    width: 100%;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    &.alert {
        background: #ff0000;
    }
`;

const deleteCache = () => {
    (async () => {
        const { cachesStorage } = PhotosiEditorSDK.getService();
        const keys = await cachesStorage.keys();
        const promises = keys.flatMap((key) => {
            return cachesStorage.delete(key);
        });

        await Promise.all(promises)
            .then(() => {})
            .catch(console.error)
            .finally(() => alert('cache deleted'));
    })();
};

export const StorageQuota: React.FC = () => {
    const [quotaString, setQuotaString] = useState<string>('');
    const [quotaPercentage, setQuotaPercentage] = useState<number>(0);

    useEffect(() => {
        if (!navigator.storage?.estimate) return;

        const action = () => {
            navigator.storage.estimate().then((estimate) => {
                if (estimate.usage !== undefined && estimate.quota !== undefined) {
                    const percentage = math.round((estimate.usage / estimate.quota) * 100);
                    const current = `
                        Storage
                        ${toMB(estimate.usage)}MB / ${toMB(estimate.quota)}MB
                        [ ${percentage}% ]
                    `;

                    setQuotaPercentage(percentage);
                    setQuotaString(current);
                }
            });
        };

        action();

        const interval = setInterval(() => action(), 3000);

        return () => {
            clearInterval(interval);
        };
    }, []);

    return (
        <QuotaPercentageContainer className={quotaPercentage > 90 ? 'alert' : ''}>
            {quotaString}
            <button style={{ marginLeft: '10px' }} onClick={deleteCache}>
                clean
            </button>
        </QuotaPercentageContainer>
    );
};
