import styled from 'styled-components';

export const CloseIcon = styled.div`
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
    display: flex;
    font-size: 24px;
    height: 27px;
    line-height: 25px;
    width: 27px;
    border-radius: 300px;
    color: ${({ theme }) => theme.colors.main.white};
    background-color: ${({ theme }) => theme.colors.background.lightgray};
    div {
        margin: auto;
        height: 27px;
        width: 27px;
    }
`;

export default CloseIcon;
