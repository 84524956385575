import { Observable, fromEventPattern } from 'rxjs';

// HELPER
export const fromWorkerEvent = (worker: Worker, options?: any): Observable<any> => {
    const eventName: string = options && options.event ? options.event : 'message';

    return fromEventPattern(
        (handler: any) => worker.addEventListener(eventName, handler),
        (handler: any) => worker.removeEventListener(eventName, handler)
    );
};
