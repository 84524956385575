import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree';
import AdjustRangeBoundariesStore from './AdjustRangeBoundariesStore';

export interface AdjustPreviewBoundariesStoreInterface extends Instance<typeof AdjustPreviewBoundariesStore> {}
export interface AdjustPreviewBoundariesStoreInterfaceSnapshotIn
    extends SnapshotIn<typeof AdjustPreviewBoundariesStore> {}
export interface AdjustPreviewBoundariesStoreInterfaceSnapshotOut
    extends SnapshotOut<typeof AdjustPreviewBoundariesStore> {}

export const AdjustPreviewBoundariesStore = types
    .model({
        translateX: AdjustRangeBoundariesStore,
        translateY: AdjustRangeBoundariesStore,
        scale: AdjustRangeBoundariesStore,
        rotate: AdjustRangeBoundariesStore,
    })
    .actions((self) => ({
        set(data: AdjustPreviewBoundariesStoreInterfaceSnapshotIn): void {
            self.translateX.set(data.translateX);
            self.translateY.set(data.translateY);
            self.scale.set(data.scale);
            self.rotate.set(data.rotate);
        },
    }));

export default AdjustPreviewBoundariesStore;
