import React from 'react';
import styled from 'styled-components';
import { GLOBAL_SPINNER_ID } from '../environment/const';
import { TransNS } from './TransNS';

const Spinner = styled.div`
    position: fixed;
    top: 0px;
    right: 0px;
    z-index: 1000;
    width: 100%;
    height: 100%;
    background-color: ${(props) => props.theme.colors.main.blackTransparent};
    margin: auto;
    display: flex;
    .hide {
        display: none;
    }
    .spinner-label-area {
        font-size: 3em;
        color: ${(props) => props.theme.colors.main.white};
        margin: auto;
    }
`;

export const GlobalSpinner: React.FC = () => {
    return (
        <Spinner id={GLOBAL_SPINNER_ID} className="hide">
            <div className="spinner-label-area">
                <b>
                    <TransNS i18nKey="spinner:loading">Caricamento</TransNS>
                </b>
            </div>
        </Spinner>
    );
};

export default GlobalSpinner;
