import { ImageSize } from '../interface/ImageSize';

export const sizeReduceByLimit = (img: ImageSize, maxSize: number, force?: boolean): ImageSize => {
    const result = { width: 0, height: 0 };

    if (img.width > img.height) {
        if (img.width < maxSize && !force) {
            maxSize = img.width;
        }

        result.height = (img.height / img.width) * maxSize;
        result.width = maxSize;
    } else {
        if (img.height < maxSize && !force) {
            maxSize = img.height;
        }

        result.width = (img.width / img.height) * maxSize;
        result.height = maxSize;
    }

    return { height: result.height, width: result.width };
};

export const imageSizeClamp = (image: { w: number; h: number }, maxSize: number) => {
    let result = { ...image };

    //ridimensiona
    if (Math.min(image.w, image.h) > maxSize) {
        if (image.w > image.h) {
            result.w = (image.w / image.h) * maxSize;
            result.h = maxSize;
        } else {
            result.h = (image.h / image.w) * maxSize;
            result.w = maxSize;
        }

        result = { w: Math.round(result.w), h: Math.round(result.h) };
    }

    return result;
};
