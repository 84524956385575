import HidingObjectInteface from '../interface/HidingObjectInteface';

export const hidingObjectsById = (id: string, init: boolean = false): HidingObjectInteface => {
    const object = { visible: init };
    Object.defineProperty(object, 'visible', {
        set: function (visible: boolean) {
            this.status = visible;
            const container = document.getElementById(id);
            if (container) {
                if (this.status === true) {
                    container.classList.remove('hide');
                } else {
                    container.classList.add('hide');
                }
            }
        },
        get: function (): boolean {
            return this.status || false;
        },
    });

    return object;
};

export default hidingObjectsById;
