import { clearCanvas } from './clearCanvas';
import { clearCanvasRenderingContext2D } from './clearCanvasRenderingContext2D';
import { createCanvasElement } from './createCanvasElement';
import { isValidDrawable } from './isValidDrawable';

export async function drawableToImageData(drawable: HTMLImageElement): Promise<ImageData | void> {
    if (!isValidDrawable(drawable)) return;
    return drawableToImageDataCanvas(drawable);
}

export const drawableToImageDataCanvas = (drawable: HTMLImageElement): ImageData => {
    const { naturalHeight, naturalWidth } = drawable;

    const width = naturalWidth / window.devicePixelRatio;
    const height = naturalHeight / window.devicePixelRatio;

    const canvas = createCanvasElement();

    if ('id' in canvas) canvas.id = 'drawableToImageDataCanvas';
    canvas.width = width;
    canvas.height = height;

    // Draw image onto canvas
    let ctx = canvas.getContext('2d');
    if (!ctx) throw new Error('Could not create canvas context');

    if ('imageSmoothingEnabled' in ctx) ctx.imageSmoothingEnabled = false;
    ctx.drawImage(drawable, 0, 0, naturalWidth, naturalHeight, 0, 0, width, height);
    const imageData = ctx.getImageData(0, 0, width, height);

    clearCanvasRenderingContext2D(ctx, width, height);
    clearCanvas(canvas);

    return imageData;
};

export default drawableToImageData;
