import React from 'react';
import { PhotosiEditorSDKIntferace } from '../interface/PhotosiEditorSDKIntferace';
import { ProvidedService } from '../interface/ProvidedService';
import { PhotosiEditorManager } from '../PhotosiEditorManager';
import { ImageUrlStoreInterfaceSnapshotOut } from '../state-manager/ImageUrlStore';
import { ProcessEditor } from './../service/ProcessEditor';

export interface photoEditorSDKMethodInterface {
    editor: PhotosiEditorManager;
    provide: ProvidedService;
}

export const photoEditorSDKMethod = ({
    editor,
    provide,
}: photoEditorSDKMethodInterface): PhotosiEditorSDKIntferace.Method => {
    const { service, globals } = provide;

    return {
        globalComponents: async (): Promise<React.FC> => await editor.editorComponent(),
        editorComponent: async ({ index, stageId }) => await provide.service.editor.get({ index, stageId }),
        getImagePile: (): ImageUrlStoreInterfaceSnapshotOut[] => provide.service.FilesPileStore.getImages(),
        getService: () => provide.service,
        getGlobals: () => provide.globals,
        addEditor: (args: PhotosiEditorSDKIntferace.AddEditor) => ProcessEditor({ ...args, service, globals }),
    };
};
