import { Svg, MatrixAlias, Element } from '@svgdotjs/svg.js';
import { filterName, setFilterToElements } from '../adjust-helpers/filtersHelper';
import { DEFAULT_FILTER } from '../environment/const';
import PhotosiEditorSDK from '../PhotosiEditorSDK';
import { AdjustPreviewStoreInterfaceSnapshotOut } from '../state-manager/AdjustPreviewStore';

export const applyAdjustmentOnSvg = (elem: Element, snap: AdjustPreviewStoreInterfaceSnapshotOut): Element => {
    elem.transform({
        origin: 'center',
        scale: snap.orig.transform.scale,
        rotate: snap.orig.transform.rotate,
        translate: [snap.orig.transform.translate.x, snap.orig.transform.translate.y],
    } as MatrixAlias);

    setFilterToElements(snap.filter as filterName, [elem]);

    // SAFARI FORCE REDRAW
    const browser = PhotosiEditorSDK.getBrowser();
    switch (browser && browser.name) {
        case 'ios':
        case 'safari':
            elem.node.style.display = 'unset';
            setTimeout(() => (elem.node.style.display = 'block'), 100);
            break;
    }

    return elem;
};

export const applyAdjustment = (draw: Svg, elem: Element, snap: AdjustPreviewStoreInterfaceSnapshotOut) => {
    const { SvgPreviewStore } = PhotosiEditorSDK.getService();

    elem = applyAdjustmentOnSvg(elem, snap);

    let filterElement = '';
    if (snap.filter !== DEFAULT_FILTER && elem.attr('filter')) {
        const filterId = elem.attr('filter').replace(/url\(\#|\)/gi, '');
        filterElement = draw.findOne(`#${filterId}`).svg();
    }

    SvgPreviewStore.updateCurretByStageIdAndId(snap.stageId, snap.id, {
        adjustament: snap,
        filter: elem.attr('filter') || '',
        filterHTML: filterElement,
    });
};
