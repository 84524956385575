import React, { useState } from 'react';
import { useEffect } from 'react';
import styled from 'styled-components';
import { math } from '../helpers/math';
import { toMB } from '../helpers/toMB';

const MemoryQuotaContainer = styled.div`
    background: #666666;
    color: #ffffff;
    width: 100%;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    &.alert {
        background: #ff0000;
    }
`;

const useHeapSize = () => {
    const [memoryPeak, setMemoryPeak] = useState<number>(window.performance?.memory?.usedJSHeapSize || 0);

    useEffect(() => {
        if (!window.performance?.memory?.usedJSHeapSize) return;

        const updateMemoryPeak = () => {
            if (window.performance.memory.usedJSHeapSize <= memoryPeak) return;
            setMemoryPeak(window.performance.memory.usedJSHeapSize);
        };

        const intervalMemoryPeak = setInterval(() => updateMemoryPeak(), 10);

        return () => {
            clearInterval(intervalMemoryPeak);
        };
    }, [memoryPeak]);

    return memoryPeak;
};

const useUsagePercentage = () => {
    const [memoryDetails, setMemoryDetails] = useState<string>('');
    const [memoryUsagePercentage, setMemoryUsagePercentage] = useState<number>(0);

    useEffect(() => {
        if (!window.performance?.memory?.usedJSHeapSize) return;

        const action = () => {
            const { memory } = window.performance;

            const percentage = math.round((memory.usedJSHeapSize / memory.jsHeapSizeLimit) * 100);
            const current = `
                        Memory
                        ${toMB(memory.usedJSHeapSize)}MB / ${toMB(memory.jsHeapSizeLimit)}MB
                        [ ${percentage}% ] 
                    `;

            setMemoryUsagePercentage(percentage);
            setMemoryDetails(current);
        };

        action();

        const interval = setInterval(() => action(), 1000);

        return () => {
            clearInterval(interval);
        };
    }, [memoryDetails, memoryUsagePercentage]);

    return { memoryDetails, memoryUsagePercentage };
};

export const MemoryQuota: React.FC = () => {
    const memoryPeak = useHeapSize();
    const { memoryDetails, memoryUsagePercentage } = useUsagePercentage();

    return (
        <MemoryQuotaContainer className={memoryUsagePercentage > 90 ? 'alert' : ''}>
            {memoryDetails} {memoryPeak > 0 && `(⛰️ ${toMB(memoryPeak)}MB)`}
        </MemoryQuotaContainer>
    );
};
