import { inject } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import PhotosiEditorSDK from '../PhotosiEditorSDK';
import { ProvidedService } from '../interface/ProvidedService';
import { MainThemeInterface } from '../theme/main';

const Container = styled.div`
    display: flex;
    height: 60px;
    width: 70px;
    flex-direction: column;
    align-items: center;

    svg {
        margin: auto;
        height: 34px;
        width: 34px;
    }
    .svgContainer {
        height: 34px;
        width: 34px;
        margin: auto;
    }
`;

interface IconButtonInterface {
    readonly theme?: MainThemeInterface;
    selected?: boolean;
    icon?: string;
    children: any;
    onClick: Function;
}

const CustomButton = styled.button`
    cursor: pointer;
    background-color: transparent;
    color: ${({ theme }) => theme.colors.main.white};
    border: none;
    font-weight: normal;
    display: flex;
    flex-direction: column;
    margin: 5px;
    &.highlighted {
        color: ${({ theme }) => theme.colors.photosi.green};
    }
`;

export const IconButton: React.FC<IconButtonInterface> = inject((provide: ProvidedService) => ({
    theme: provide.theme,
}))(({ children, onClick, icon, selected, theme }) => {
    const [svg, setSvg] = useState<string>('');
    const { axios } = PhotosiEditorSDK.getGlobals();

    useEffect(() => {
        if (!icon) return;
        axios
            .get(icon)
            .then(({ data: svgData }) => {
                if (selected) {
                    const photosiGreen = theme ? theme.colors.photosi.green : '#fff';
                    if (svgData && svgData.replaceAll) svgData = svgData.replaceAll('#fff', photosiGreen);
                }
                setSvg(svgData);
            })
            .catch((err) => {
                console.error('IconButton: failed to load icon', err);
            });
    }, [selected]);

    return (
        <Container onClick={() => onClick()}>
            <div className="svgContainer" dangerouslySetInnerHTML={{ __html: svg }}></div>
            <CustomButton className={selected ? 'highlighted' : ''}>{children}</CustomButton>
        </Container>
    );
});

export default IconButton;
