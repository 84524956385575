import { IMAGE_TYPE_JPEG } from '../environment/const';
import { imageEncoder } from '../helpers/canvasEncode';
import { consoleTime, consoleTimeEnd } from '../helpers/console';
import { decodeImage } from '../helpers/decodeImage';
import { drawableToImageData } from '../helpers/drawableToImageData';
import { formatBytes } from '../helpers/formatBytes';
import { getImageCacheName } from '../helpers/getImageCacheName';
import { isValidDrawable } from '../helpers/isValidDrawable';
import { math } from '../helpers/math';
import { removeImageObject } from '../helpers/removeImageObject';
import { resizeImage } from '../helpers/resizeImage';
import { ImageSize } from '../interface/ImageSize';
import { PhotosiEditorSDK } from '../PhotosiEditorSDK';
import { GenericSizeStoreInterfaceSnapshotIn } from '../state-manager/GenericSizeStore';
import { getCache, imageIsCached, putCache } from './CacheStorage';

export interface ImageFlowItemsInterface {
    stageId: string;
    index: number;
    replace?: boolean;
    priority?: number;
}

export interface PersistThumbnailParams {
    image: File;
    index: number;
    stageId: string;
}

export interface AddImageInterface extends ImageFlowItemsInterface {
    image: File;
}

export const generateThumbnail = async (
    thumbnailSize: GenericSizeStoreInterfaceSnapshotIn,
    imageData: ImageData
): Promise<Blob> => {
    const start = performance.now();

    const thumbnailImageData = await resizeImage(imageData, Math.max(thumbnailSize.w, thumbnailSize.h), 'fast');

    const resizeEnd = performance.now();
    const encodeStart = performance.now();

    let thumbnailBlob;
    try {
        thumbnailBlob = await imageEncoder(thumbnailImageData, IMAGE_TYPE_JPEG);
    } catch (error) {
        return Promise.reject('newThumbnail: faield to encode thumbnail');
    }

    const encodeEnd = performance.now();
    const end = performance.now();

    if (PhotosiEditorSDK.isVerbose()) console.log(`%cnew Thumbnail ${JSON.stringify(thumbnailSize)} `, 'color:#aeff00');
    if (PhotosiEditorSDK.debug()) {
        console.debug(`
        isWeakDevice ${PhotosiEditorSDK.isWeakDevice()}

        Image File:
            - after: ${formatBytes(thumbnailBlob.size)}
            
        Process Time
            - resize: ${math.round(resizeEnd - start)}ms
            - encode: ${math.round(encodeEnd - encodeStart)}ms`);

        const totalTime = math.round(end - start);
        console.debug(`%cMake thumbnail time: ${totalTime}ms`, `color:#${totalTime > 200 ? 'ea5d0b' : '5acec9'}`);
    }

    return thumbnailBlob;
};

const persistThumbnail = async ({ image, index, stageId }: PersistThumbnailParams): Promise<Blob | void> => {
    const { FilesPileStore } = PhotosiEditorSDK.getService();

    const { thumbnailSize } = FilesPileStore.getImageByStageIdAndIndex(stageId, index);

    let thumbnailBlob;
    try {
        const generateString = `Generate thumbnail ${stageId} ${index}`;
        consoleTime(generateString);

        thumbnailBlob = await createThumbnail(image, { width: thumbnailSize.w, height: thumbnailSize.h });

        consoleTimeEnd(generateString);
    } catch (error) {
        console.log(error);
        return Promise.reject(`persistThumbnail: failed to create new thumbnail, ${error}`);
    }

    const { name, size } = FilesPileStore.getImageByStageIdAndIndex(stageId, index);
    if (!name) return Promise.reject('Persist: Failed to put image in cache');
    if (typeof size !== 'number') return Promise.reject('Persist: Failed to put image in cache');

    const persistString = `Persist thumbnail ${stageId} ${index}`;
    consoleTime(persistString);

    const cacheName = getImageCacheName({ name, size });

    const cachedImage = await putCache(thumbnailBlob, cacheName);

    if (cachedImage instanceof Blob) FilesPileStore.replaceImage({ stageId, index, cached: true });

    consoleTimeEnd(persistString);

    return cachedImage;
};

export const createThumbnail = async (file: File, size: ImageSize): Promise<Blob> => {
    try {
        const cacheName = getImageCacheName({ name: file.name, size: file.size });

        const cachedThumbnail = await getCache(cacheName);
        if (!cachedThumbnail) throw 'file not found in cache';

        const blob = await cachedThumbnail.blob();
        if (!blob) throw 'failed to create blob from cache';

        return Promise.resolve(blob);
    } catch (error) {
        if (PhotosiEditorSDK.debug()) console.debug(`createThumbnail: ${file.name} not load from cache:`, error);
    }

    const fileURL = URL.createObjectURL(file);

    const imageObject = await decodeImage(fileURL);

    if (!isValidDrawable(imageObject)) return Promise.reject('Image Element is not valid');

    let imageData: ImageData | void;
    try {
        imageData = await drawableToImageData(imageObject);
    } catch (error) {
        removeImageObject(imageObject);
        return Promise.reject('createThumbnail: failed to get image data (1)');
    }

    if (!imageData) return Promise.reject('createThumbnail: image data is empty');

    removeImageObject(imageObject);

    return await generateThumbnail({ w: size.width, h: size.height }, imageData);
};

export const StartThumbnailProcessing = async ({
    image,
    stageId,
    index,
    replace = false,
    priority = 0,
}: AddImageInterface): Promise<void> => {
    const { FilesPileStore } = PhotosiEditorSDK.getService();
    const { mainQueue } = PhotosiEditorSDK.getGlobals();

    let thumbnailIsCached = false;
    let action: Function | undefined;

    if (!replace) thumbnailIsCached = await imageIsCached({ stageId, index });

    if (!thumbnailIsCached) {
        action = () => persistThumbnail({ image, index, stageId });
    } else {
        action = () => {
            if (PhotosiEditorSDK.isVerbose()) {
                console.log(`Load image '${getImageCacheName({ name: image.name, size: image.size })}' from cache`);
            }
            FilesPileStore.replaceImage({ stageId, index, cached: true });
        };
    }

    if (typeof action !== 'function') return Promise.reject('StartThumbnailProcessing: action is not a function');

    mainQueue.add(() => (action ? action() : null), { priority: 2000 + priority });
};
