import { onAction } from 'mobx-state-tree';
import PQueue from 'p-queue';
import React from 'react';
import { BehaviorSubject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { BottomDialog$ } from './components/GlobalBottomDialog/BottomDialogObservable';
import { PhotoEditor } from './components/PhotoEditor';
import { i18nextInitalize } from './config/i18n-config';
import { initializeStoreMiddleware } from './config/initializeStoreMiddleware';
import {
    GLOBAL_CONTAINER_ID,
    GLOBAL_HORIZONTAL_LOADER_ID,
    GLOBAL_SPINNER_ID,
    UMD_PATH,
    WINDOW_INIT_INFO_KEY,
} from './environment/const';
import { getImageCacheName } from './helpers/getImageCacheName';
import { hidingObjectsById } from './helpers/hidingObjectById';
import { ImageSize } from './interface/ImageSize';
import { PhotosiEditorSDKIntferace } from './interface/PhotosiEditorSDKIntferace';
import { ProvidedService } from './interface/ProvidedService';
import { PhotosiEditorSDK } from './PhotosiEditorSDK';
import { AdjustPreviewEvent } from './service/AdjustPreviewEvent';
import { AxiosCachedClient } from './service/AxiosCachedClient';
import { CacheStorage, getCache, isCached, putCache } from './service/CacheStorage';
import { CreatePreview } from './service/CreatePreview';
import { createThumbnail, StartThumbnailProcessing } from './service/ImageFlow';
import { ImagesPileLoader } from './service/ImagesPileLoader';
import { imageToJpeg, processMode } from './service/ImageToJPEG';
import { Mozjpeg } from './service/Mozjpeg';
import { StorageWrapper } from './service/storage/StorageWrapper';
import { ExportProductionSVG } from './service/SVGProductionFlow';
import { ChooseBgColor } from './stage-helpers/backgroundColors';
import { ShowAdjustImage } from './stage-helpers/initialize';
import { AdjustPreviewStore } from './state-manager/AdjustPreviewStore';
import { EditorPileStore } from './state-manager/EditorPileStore';
import { FilesPileStore } from './state-manager/FilesPileStore';
import { ImageUrlStoreInterfaceSnapshotIn } from './state-manager/ImageUrlStore';
import { adjustPreviewStoreInitialstate } from './state-manager/initial-config/adjustPreviewStoreInitialState';
import { SvgPreviewStore } from './state-manager/SvgPreviewStore';
import { MainTheme } from './theme/main';

export class PhotosiEditorManager {
    private language: string = navigator.language;
    private imagesPile: ImageUrlStoreInterfaceSnapshotIn[] = [];
    private initialized: boolean = false;
    private i18nConfig: object | undefined;
    private config: PhotosiEditorSDKIntferace.Config = {};
    constructor(config: PhotosiEditorSDKIntferace.Config) {
        if (config.language) this.language = config.language;
        if (config.i18nConfig) this.i18nConfig = Object.assign(this.i18nConfig, config.i18nConfig);
        if (config.imagesPile) this.imagesPile = config.imagesPile;
        Object.assign(this.config, config);

        if (config.wakeLock && 'wakeLock' in window.navigator) {
            const wakeLock = window.navigator.wakeLock;

            if (!wakeLock?.request) return;

            wakeLock
                .request('screen')
                .then(() => {})
                .catch((err: Error) => {
                    console.warn(`WakeLock not enabled ${err.name}, ${err.message}`);
                });
        }
    }
    public isInitialized(): boolean {
        return this.initialized;
    }
    public async getWorker() {
        let MozjpegWorker: Worker, mozjpegEncWasm;

        mozjpegEncWasm = `${this.config.publicPath}/${UMD_PATH}/mozjpeg_enc.wasm`;
        if (this.config.wasm && this.config.wasm.mozjpeg) mozjpegEncWasm = this.config.wasm.mozjpeg;

        try {
            MozjpegWorker = require('worker-loader?inline=fallback!./workers/mozjpeg').default;
        } catch (error) {
            console.error('MozjpegWorker loading failed');
            return Promise.reject(error);
        }

        return {
            mozjpeg: new Mozjpeg(MozjpegWorker, { mozjpeg: mozjpegEncWasm }),
        };
    }

    private async getService(): Promise<ProvidedService['service']> {
        const storage = new StorageWrapper();
        const sessionStorage = new StorageWrapper(window.sessionStorage);
        const previewStore = SvgPreviewStore.create({ current: [] });
        const filesPileStore = FilesPileStore.create({ images: ImagesPileLoader(this.imagesPile) }); //JSON.parse(storage.load(FILE_PILES_STORE_KEY)) || { images: [] });
        const adjustPreviewStore = AdjustPreviewStore.create(adjustPreviewStoreInitialstate);
        const cachesStorage = await CacheStorage();
        const editorPileStore = EditorPileStore.create({ editors: [] });

        let worker;
        try {
            worker = await this.getWorker();
        } catch (error) {
            return Promise.reject(error);
        }

        return {
            editor: {
                get: CreatePreview,
                adjust: ShowAdjustImage,
                export: ExportProductionSVG,
                setImage: StartThumbnailProcessing,
                chooseBgColor: ChooseBgColor,
            },
            i18n: i18nextInitalize(this.i18nConfig, this.language, this.config.publicPath),
            SvgPreviewStore: previewStore,
            FilesPileStore: filesPileStore,
            EditorPileStore: editorPileStore,
            AdjustPreviewStore: adjustPreviewStore,
            storage: storage,
            sessionStorage: sessionStorage,
            adjustPreviewEvent: new AdjustPreviewEvent(`[id="${GLOBAL_CONTAINER_ID}"]`),
            cachesStorage,
            ...worker,
        };
    }

    public async initializeEditor(): Promise<ProvidedService> {
        if (this.isInitialized() === true) return Promise.reject('editor has alredy started');

        let service: any;
        try {
            service = await this.getService();
        } catch (error) {
            return Promise.reject(error);
        }

        const globalSpinner = hidingObjectsById(GLOBAL_SPINNER_ID);
        const globalHorizontalLoader = hidingObjectsById(GLOBAL_HORIZONTAL_LOADER_ID);

        const bottomDialog = BottomDialog$();

        const mainQueue = new PQueue({
            concurrency: 1,
            autoStart: true,
            interval: 10,
        });

        const serviceQueue = new PQueue({
            concurrency: 1,
            autoStart: true,
            interval: 10,
        });

        const subject = new BehaviorSubject<'play' | 'pause'>('play');
        const debounceSubject = subject.pipe(debounceTime(100));

        debounceSubject.subscribe(() => {
            if (mainQueue.isPaused) mainQueue.start();
        });

        serviceQueue.on('add', () => {
            subject.next('pause');
            mainQueue.pause();
        });

        serviceQueue.on('completed', () => {
            subject.next('play');
        });

        const initialService: ProvidedService = {
            config: this.config,
            service,
            theme: { ...MainTheme },
            globals: {
                axios: AxiosCachedClient(),
                serviceQueue,
                mainQueue,
                responsive: { isDesktop: false, isMobile: false },
                globalSpinner: globalSpinner,
                globalHorizontalLoader: globalHorizontalLoader,
                globalBottomDialog: bottomDialog,
            },
            utility: {
                imageToJpeg: (file: File, maxSize?: number, mode: processMode = 'fast'): Promise<File> => {
                    const { mozjpeg } = service;
                    return imageToJpeg({ mozjpeg, file, maxSize, mode });
                },
                createThumbnail: (file: File, thumbnailSize?: ImageSize) => {
                    return new Promise<Blob>((resolve, reject) => {
                        const action = async () => {
                            const cacheName = getImageCacheName({ name: file.name, size: file.size });
                            
                            const thumbnailIsCached = await isCached(cacheName);
                            if (thumbnailIsCached) {
                                const cachedThumbnail = await getCache(cacheName);
                                if (!cachedThumbnail) return Promise.reject('Failed to get image from cache');
                                const blob = await cachedThumbnail.blob();
                                return resolve(blob);
                            }

                            if (!thumbnailSize) ({ thumbnailSize } = PhotosiEditorSDK.getConfig());
                            if (!thumbnailSize) return reject('Thumbnail size is not valid');

                            let thumbnail: Blob = await createThumbnail(file, thumbnailSize);
                            try {
                                const cachedThumbnail = await putCache(thumbnail, cacheName);
                                if (!cachedThumbnail) throw 'Put image in cache return undefined';
                                thumbnail = cachedThumbnail;
                            } catch (error) {
                                console.error('createThumbnail: Failed put image in cache ', error);
                            }

                            if (!thumbnail) return reject('createThumbnail: thumbnail is undefined');

                            return resolve(thumbnail);
                        };
                        mainQueue.add(() => action());
                    });
                },
            },
        };

        if (this.config.verbose) initializeStoreMiddleware(service);

        // ADJSUT PREVIEW STORE ACTION MIDDLEWARE
        onAction(service.AdjustPreviewStore, (action) => {
            switch (action.name) {
                case 'show':
                    initialService.service.adjustPreviewEvent.view();
                    break;
            }
        });

        if (this.config.verbose) {
            console.log(`%cPhotosiEditorSDK`, 'color:#5acec9');
            console.log(`
        Device:
            - ram: ${window.navigator.deviceMemory}
            - core: ${window.navigator.hardwareConcurrency}
            - version: ${window.navigator.appVersion}
            - platform: ${window.navigator.platform}
            - vendor: ${window.navigator.vendor}

        `);
        }

        initialService.service.sessionStorage.persist(
            WINDOW_INIT_INFO_KEY,
            JSON.stringify({ window: { height: window.innerHeight, width: window.innerWidth } })
        );

        this.initialized = true;
        return initialService;
    }

    public editorComponent(): React.FC {
        return () => {
            const provide = PhotosiEditorSDK.Provider();
            return <PhotoEditor provide={provide} />;
        };
    }
}

export default PhotosiEditorManager;
