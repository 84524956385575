import PhotosiEditorSDK from '../PhotosiEditorSDK';

export const consoleTime = (string: string) => {
    if (!PhotosiEditorSDK.debug()) return;
    console.time(string);
};

export const consoleTimeEnd = (string: string) => {
    if (!PhotosiEditorSDK.debug()) return;
    console.timeEnd(string);
};
