import { imageSizeClamp } from './imageSizeCalc';
import { PhotosiEditorSDK } from '../PhotosiEditorSDK';
import { getBenchResizeStatus } from '../components/BenchTest';
//import Jimp from 'jimp/browser/lib/jimp';
import { imageDataMathDownsample } from './imageDataMathDownsample';
import { ImageSize } from '../interface/ImageSize';

export type ResizeMode = 'fast' | 'quality';

export const resizeImage = async (
    imageData: ImageData,
    maxSize: number,
    forceMode?: ResizeMode,
    abortSignal?: AbortSignal
): Promise<ImageData> => {
    const { storage } = PhotosiEditorSDK.getService();

    if (abortSignal) {
        abortSignal.addEventListener('abort', () => {
            Promise.reject(new DOMException('Aborted resize image', 'AbortError'));
        });
    }

    const newSize = imageSizeClamp({ w: imageData.width, h: imageData.height }, maxSize);

    if (newSize.w === imageData.width && newSize.h === imageData.height) {
        if (PhotosiEditorSDK.debug()) console.debug(`resizeImage return original image size`);
        return imageData;
    }

    const benchTestResult = getBenchResizeStatus(storage);

    if (PhotosiEditorSDK.debug()) {
        console.debug(`
Resize (${forceMode || benchTestResult === 'timeout' ? 'fast' : 'quality'})

        Source Image resolution;
            - width: ${imageData.width}px
            - height: ${imageData.height}px 

        New Image resolution: 
            - width: ${newSize.w}px
            - height: ${newSize.h}px
        `);
    }

    return await browserResizeMaxSize(imageData, { width: newSize.w, height: newSize.h });
};

export const browserResizeMaxSize = async (imageData: ImageData, newSize: ImageSize): Promise<ImageData> => {
    return new Promise((resolve) => {
        resolve(imageDataMathDownsample(imageData, newSize.width, newSize.height));
    });
};

export default resizeImage;
