import { inject } from 'mobx-react';
import React, { useEffect, useRef, useState } from 'react';
import { HIGHLIGHT_CLASS, WINDOW_INIT_INFO_KEY } from '../environment/const';
import { stripInvalidChar } from '../helpers/stripInvalidChar';
import { FontTypes } from '../interface/FontTypes';
import HidingObservableComponentInterface from '../interface/HidingObservableComponentInterface';
import PhotosiEditorSDKIntferace from '../interface/PhotosiEditorSDKIntferace';
import { ProvidedService } from '../interface/ProvidedService';
import WindowSizeInterface from '../interface/WindowInitInfoInterface';
import { PhotosiEditorSDK } from '../PhotosiEditorSDK';
import { StorageWrapperInterface } from '../service/storage/StorageWrapper';
import { MainThemeInterface } from '../theme/main';
import {
    Input,
    Line,
    Title,
    CloseIconEditText,
    SaveButton,
    EditTextContainer,
    TextInputArea,
    LetterCounter,
    Subtitle,
    FormatTextArea,
    Label,
    ColorTextArea,
    ColorLabel,
} from './EditText/styleCompCollection';
import { TransNS } from './TransNS';

export interface EditTextComponentInterface {
    readonly globalBottomDialog?: HidingObservableComponentInterface;
    readonly sessionStorage?: StorageWrapperInterface;
    readonly theme?: MainThemeInterface;
    maxLength?: number;
    current: { text: string; font: FontTypes; color: string; orig: string };
    actions: { applyText: Function };
}

const highlightByClass = (current: string, match: string) => {
    return current === match ? `${current} ${HIGHLIGHT_CLASS}` : `${current}`;
};

export const EditText: React.FC<EditTextComponentInterface> = inject((provide: ProvidedService) => ({
    globalBottomDialog: provide.globals.globalBottomDialog,
    sessionStorage: provide.service.sessionStorage,
    theme: provide.theme,
}))(({ globalBottomDialog, maxLength, actions, current, theme, sessionStorage }) => {
    if (!globalBottomDialog || !theme || !sessionStorage) {
        return <>failed to initialize edit text</>;
    }

    const refLetterCounter = useRef(null);
    const refInputText = useRef(null);
    const refButtonApply = useRef(null);

    const [font, setFont] = useState<FontTypes>(current.font || 'SFProTextMedium');
    const [color, setColor] = useState(current.color || '');

    const textPreviewlabel = 'Aa';

    const addhighlight = (event: React.MouseEvent<HTMLElement>) => {
        event.currentTarget
            .closest('ul')
            ?.querySelectorAll('.highlight')
            .forEach((item) => {
                item.classList.remove(HIGHLIGHT_CLASS);
            });

        event.currentTarget.classList.add(HIGHLIGHT_CLASS);
    };

    const { textPalette } = PhotosiEditorSDK.getConfig();

    useEffect(() => {
        const editTextOnScreenResize = () => {
            const windowSize: WindowSizeInterface = JSON.parse(sessionStorage.load(WINDOW_INIT_INFO_KEY)) || {
                window: { height: window.innerHeight, width: window.innerWidth },
            };

            //const diffHeight = window.innerHeight - windowSize.window.height;
            if (window.innerHeight < windowSize.window.height) {
                globalBottomDialog.setStyle({ top: '0px' });
            } else {
                globalBottomDialog.setStyle({ top: 'initial' });
            }
        };

        window.addEventListener('resize', editTextOnScreenResize);

        return () => {
            window.removeEventListener('resize', editTextOnScreenResize);
        };
    }, []);

    useEffect(() => {
        if (textPalette) {
            const colorsPalette = textPalette.map((current: PhotosiEditorSDKIntferace.Color) => {
                return current.code.toLowerCase();
            });

            if (colorsPalette.includes(color.toLowerCase()) === false) {
                setColor('#000000');
            }
        }
    }, [color]);

    return (
        <div>
            <Title>
                <TransNS i18nKey="edit-text:add">Modifica testo</TransNS>
            </Title>
            <CloseIconEditText
                onClick={() => {
                    globalBottomDialog.hide();
                }}
            >
                <div>×</div>
            </CloseIconEditText>
            <SaveButton
                ref={refButtonApply}
                className="slim hollow"
                onClick={() => {
                    //@ts-ignore
                    const textInput: HTMLInputElement = refInputText.current;
                    actions.applyText({ text: textInput.value, font, color });
                }}
            >
                <TransNS i18nKey="edit-text:save">Salva</TransNS>
            </SaveButton>
            <EditTextContainer>
                <TextInputArea>
                    <Input
                        className={font}
                        ref={refInputText}
                        maxLength={maxLength}
                        placeholder={current.orig.slice(0, maxLength)}
                        defaultValue={current.text.slice(0, maxLength)}
                        onKeyUp={(event: React.KeyboardEvent<HTMLInputElement>) => {
                            event.currentTarget.value = stripInvalidChar(event.currentTarget.value);

                            if (refLetterCounter && refLetterCounter.current && maxLength) {
                                //@ts-ignore
                                const target: HTMLDivElement = refLetterCounter.current;
                                target.innerText = `${event.currentTarget.value.length}/${maxLength}`;
                            }

                            switch (event.key) {
                                case 'Enter':
                                    //@ts-ignore
                                    if (refInputText && refInputText.current) refInputText.current.blur();
                                    //@ts-ignore
                                    if (refButtonApply && refButtonApply.current) refButtonApply.current.click();
                                    break;
                            }
                        }}
                        onChange={(event) => {
                            event.target.value = stripInvalidChar(event.target.value);
                        }}
                        onBlur={(event) => {
                            event.target.value = stripInvalidChar(event.target.value);
                        }}
                    />
                    {maxLength && (
                        <LetterCounter ref={refLetterCounter}>
                            {current.text.slice(0, maxLength).length}/{maxLength}
                        </LetterCounter>
                    )}
                </TextInputArea>
                <Subtitle>
                    <TransNS i18nKey="edit-text:format">Carattere</TransNS>
                </Subtitle>
                <FormatTextArea>
                    <ul>
                        <li
                            className={`${highlightByClass(font, 'AmaticSC-Regular')}`}
                            onClick={(event) => {
                                addhighlight(event);
                                setFont('AmaticSC-Regular');
                            }}
                        >
                            <Label className="AmaticSC">{textPreviewlabel}</Label>
                        </li>
                        <li
                            className={`${highlightByClass(font, 'Caveat-Regular')}`}
                            onClick={(event) => {
                                addhighlight(event);
                                setFont('Caveat-Regular');
                            }}
                        >
                            <Label className="Caveat">{textPreviewlabel}</Label>
                        </li>
                        <li
                            className={`${highlightByClass(font, 'Inconsolata-Regular')}`}
                            onClick={(event) => {
                                addhighlight(event);
                                setFont('Inconsolata-Regular');
                            }}
                        >
                            <Label className="Inconsolata">{textPreviewlabel}</Label>
                        </li>
                        <li
                            className={`${highlightByClass(font, 'PlayfairDisplay-Regular')}`}
                            onClick={(event) => {
                                addhighlight(event);
                                setFont('PlayfairDisplay-Regular');
                            }}
                        >
                            <Label className="PlayfairDisplay">{textPreviewlabel}</Label>
                        </li>
                    </ul>
                </FormatTextArea>
                <Line />
                <Subtitle>
                    <TransNS i18nKey="edit-text:color">Colore</TransNS>
                </Subtitle>
                <ColorTextArea>
                    <ul>
                        {textPalette &&
                            textPalette.map((current: PhotosiEditorSDKIntferace.Color, index: number) => (
                                <li
                                    key={`ColorTextArea-Item-${index}`}
                                    className={`${
                                        color.toLowerCase() === current.code.toLowerCase() ? HIGHLIGHT_CLASS : ''
                                    }`}
                                    onClick={(event) => {
                                        addhighlight(event);
                                        setColor(current.code);
                                    }}
                                >
                                    <ColorLabel style={{ backgroundColor: current.code }} />
                                </li>
                            ))}
                    </ul>
                </ColorTextArea>
            </EditTextContainer>
        </div>
    );
});
export default EditText;
