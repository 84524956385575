import { Element, SVG, Svg } from '@svgdotjs/svg.js';
import { IMAGETYPE_SVG, ROTATION_LABEL } from '../environment/const';
import { getPseudoID } from '../helpers/getPseudoID';
import { validateXML } from '../helpers/validateXML';
import { ApplyCustumizationToSVGInterfaceProduction } from '../interface/ApplyCustumizationToSVGInterface';
import { ImageSize } from '../interface/ImageSize';
import { PhotosiEditorSDK } from '../PhotosiEditorSDK';
import { getSVGFeatures } from '../service/SvgHandler';
import { replaceDataXlink } from '../stage-helpers/replaceDataXLink';
import { SvgEditedElementsStoreInterface } from '../state-manager/SvgEditedElementsStore';
import { fill } from './elementTypes/fill';
import { image } from './elementTypes/image';
import { rect } from './elementTypes/rect';
import text from './elementTypes/text';

const getElementFromDraw = async (draw: Svg, current: SvgEditedElementsStoreInterface): Promise<Element> => {
    const element = draw.findOne(`[id^="${current.id}" i]`) as Element;
    if (!element) return Promise.reject('getElementFromDraw: element not found');
    return element;
};

const applyCustomizationToSvg = async ({
    draw,
    stageId,
    index,
    stageData,
}: ApplyCustumizationToSVGInterfaceProduction) => {
    for (const current of stageData) {
        let elem = await getElementFromDraw(draw, current);

        switch (current.type) {
            case 'rect':
                elem = rect(elem, current);
                break;
            case 'text':
                elem = fill(elem, current);
                elem = text(elem, current);
                break;
            case 'image':
                elem = await image(elem, current, { stageId, index, draw });
                break;
        }
    }
    return draw;
};

export const createProductionSVG = async (
    stageId: string,
    index: number,
    { width, height }: ImageSize,
    svgProd: Svg
): Promise<File> => {
    const { SvgPreviewStore } = PhotosiEditorSDK.getService();
    const stageData = SvgPreviewStore.getStage(stageId);

    const { svgFeatures } = await getSVGFeatures(svgProd.svg());

    if (svgFeatures.userImagesCount > 0 && SvgPreviewStore.length() <= 0) {
        return Promise.reject('createProductionSVG: stage data is empty');
    }

    await applyCustomizationToSvg({ draw: svgProd, stageId, index, stageData });

    const options = { type: IMAGETYPE_SVG };

    const newSVG = replaceDataXlink(SVGForceVertical(svgProd.node.outerHTML, { width, height }));

    const svgText = newSVG.documentElement.outerHTML;

    const blob = new Blob([svgText], options);

    const file = new File([blob], `export_${getPseudoID()}.svg`, options);

    const isValidSVG = validateXML(svgText);

    if (PhotosiEditorSDK.isVerbose()) {
        console.log(
            `createProductionSVG Details StageId: ${stageId}, index: ${index}, is valid SVG: ${isValidSVG}`,
            file,
            { svgText }
        );
    }

    if (!isValidSVG) return Promise.reject('createProductionSVG: svg is not valid');

    if (PhotosiEditorSDK.isVerbose()) console.log('createProductionSVG: promises is finished');
    return Promise.resolve(file);
};

export default createProductionSVG;

/*

    il rasterizzatore back-end è ragionato per stampare partendo solo da formati verticali

 */
const SVGForceVertical = (svgText: string, { width, height }: ImageSize): string => {
    if (width > height && svgText.indexOf(`${ROTATION_LABEL}="true"`) !== -1) {
        const svgProd = SVG(svgText) as Svg;

        const viewBox = svgProd.attr('viewBox');
        const viewBoxArray = viewBox.split(' ');
        const newViewBox = `${viewBoxArray[0]} ${viewBoxArray[1]} ${viewBoxArray[3]} ${viewBoxArray[2]}`;
        svgProd.viewbox(newViewBox);

        const editLayer = svgProd.find('[id="editLayer"]');

        editLayer.forEach((element) => {
            element.attr(`data-force-to-vertical-orientation`, true);
            element.attr(
                'transform',
                `rotate(90 ${Math.round(viewBoxArray[3] / 2)} ${Math.round(viewBoxArray[3] / 2)})`
            );
        });

        return svgProd.svg();
    }

    return svgText;
};
