import { SvgEditedElementsStoreInterface } from '../../state-manager/SvgEditedElementsStore';
import { Element, Svg } from '@svgdotjs/svg.js';
import { PhotosiEditorSDK } from '../../PhotosiEditorSDK';
import { toJS } from 'mobx';
import { calculateAspectRatio } from '../../helpers/calculateAspectRatio';
import { applyAdjustmentOnSvg } from '../../stage-helpers/applyAdjustment';
import { getHoleOriginData, svgInit } from '../../stage-helpers/initialize';
import { DATA_XLINK_HREF } from '../../environment/const';

export const image = async (
    elem: Element,
    current: SvgEditedElementsStoreInterface,
    {
        stageId,
        index,
        draw,
        enablePresignedImageURL = true,
    }: { stageId: string; index: number; draw: Svg; enablePresignedImageURL?: boolean }
) => {
    const { FilesPileStore } = PhotosiEditorSDK.getService();

    const fileStore = toJS(FilesPileStore.getImageByStageIdAndIndex(stageId, index));

    const origHole = getHoleOriginData(draw, elem);

    const imageSize = calculateAspectRatio(
        fileStore.originSize.w,
        fileStore.originSize.h,
        origHole.w,
        origHole.h,
        'cover'
    );

    const imageSizeParams = { w: imageSize.width, h: imageSize.height }; // TODO RETURN NAN WTF!

    if (enablePresignedImageURL && fileStore.presignedImageURL.length <= 0) {
        return Promise.reject(
            `rejected promise of svg production, presignedURL value is empty: 
        - svg: ${fileStore.svgProduction}   
        - index: ${fileStore.index}
        - stageId: ${fileStore.stageId}
        - thumbnail: ${fileStore.thumbnail}
                                            `
        );
    }

    svgInit(elem, imageSizeParams, origHole, fileStore.thumbnail);

    // in caso di presigned url, sostituisci il blob url corrente con il presigned url
    if (enablePresignedImageURL) elem.attr(DATA_XLINK_HREF, `${fileStore.presignedImageURL}`);

    if (!current.adjustament) return Promise.reject('create svg errors adjustment not found');

    applyAdjustmentOnSvg(elem, current.adjustament);

    return Promise.resolve(elem);
};

export default image;
