import { Svg, SVG } from '@svgdotjs/svg.js';
import PhotosiEditorSDK from './../PhotosiEditorSDK';
import { HoleSizeObject, getMaxHoleSize } from '../helpers/getMaxHoleSize';
import { getGroupUserBkcolor, getGroupUserImageArea } from '../stage-helpers/selectorsHelpers';

export interface SVGHandlerData {
    svg: { preview: string; production: string; selfRotate?: boolean };
}

export interface SVGFeaturesInterface {
    svgFeatures: {
        userImagesCount: number;
        userBackgroundColorEditable: boolean;
        maxHoleSize: HoleSizeObject;
    };
}

export const stringToSVG = (text: string): Svg => {
    const container = document.createElement('div');
    container.innerHTML = text;
    return SVG(container.querySelector('svg')) as Svg;
};

export const getSVGFeatures = async (svgTemplate: string): Promise<SVGFeaturesInterface> => {
    const newSvg: Svg = stringToSVG(svgTemplate);
    const userImageElements = getGroupUserImageArea(newSvg);
    const userBackgroundColorsEditable = getGroupUserBkcolor(newSvg);

    let maxHoleSize: HoleSizeObject = { height: 0, width: 0, svgSize: { height: 0, width: 0 } };
    try {
        maxHoleSize = await getMaxHoleSize(svgTemplate);
    } catch (error) {
        if (PhotosiEditorSDK.debug()) console.warn(`Failed to get image hole`, error);
    }

    const result = {
        svgFeatures: {
            userImagesCount: userImageElements.length,
            userBackgroundColorEditable: userBackgroundColorsEditable.length > 0,
            maxHoleSize,
        },
    };

    newSvg.remove();

    return result;
};
