import React from 'react';
import { getPseudoID } from '../helpers/getPseudoID';
import { extension } from '../helpers/mimeType';
import { ExportProductionSVG } from '../service/SVGProductionFlow';
import PhotosiEditorSDK from './../PhotosiEditorSDK';
import { Button } from './Button';

export interface DevActionButtonComponentInterface {
    stageId: string;
    index: number;
}

export const DevActionButton: React.FC<DevActionButtonComponentInterface> = ({ stageId, index }) => {
    const { FilesPileStore, EditorPileStore } = PhotosiEditorSDK.getService();

    return (
        <div className="dev-action-button">
            <Button
                onClick={() => {
                    (async () => {
                        const pickerOpts = {
                            types: [
                                {
                                    description: 'Images',
                                    accept: {
                                        'image/*': ['.jpeg', '.jpg'],
                                    },
                                },
                            ],
                            excludeAcceptAllOption: true,
                            multiple: false,
                        };

                        if (!window.showOpenFilePicker)
                            return Promise.reject('browser dont support showOpenFilePicker');

                        let [fileHandle] = await window.showOpenFilePicker(pickerOpts);
                        const image: File = await fileHandle.getFile();

                        const editor = EditorPileStore.getEditor(stageId);
                        if (!editor) return Promise.reject('Replace: Failed to get editor');

                        if (!('replace' in editor)) return Promise.reject('Replace: action not found in editor');

                        return await editor.replace(image, stageId, index);
                    })();
                }}
            >
                replace
            </Button>

            <Button
                onClick={() => {
                    FilesPileStore.replaceImage({
                        index,
                        stageId,
                        presignedImageURL: 'https://www.FAKE_PRESIGNED_URL.com',
                    });
                    console.log('image is now presigned');
                }}
            >
                Presign
            </Button>

            <Button
                onClick={() => {
                    ExportProductionSVG(stageId, index)
                        .then((blob) => {
                            const ext = extension(blob.type);

                            const originURL = URL.createObjectURL(blob);
                            const previewContainer = document.getElementById('__previewContainer');

                            if (previewContainer) {
                                fetch(originURL)
                                    .then(async (response) => {
                                        previewContainer.innerHTML = await response.text();

                                        const link = document.createElement('a');
                                        link.href = originURL;
                                        link.download = `export_${getPseudoID()}.${ext}`;
                                        link.click();
                                        URL.revokeObjectURL(originURL);
                                    })
                                    .catch((err) => {
                                        console.log(`previewContainer => originURL ${originURL}`);
                                        throw err;
                                    });
                            } else {
                                const link = document.createElement('a');
                                link.href = originURL;
                                link.download = `export_${getPseudoID()}.${ext}`;
                                link.click();
                                URL.revokeObjectURL(originURL);
                            }
                        })
                        .catch((err) => console.error('DEVAction ExportProductionSVG', err));
                }}
            >
                SVG
            </Button>
        </div>
    );
};
