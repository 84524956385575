import { SVG } from '@svgdotjs/svg.js';
import React, { useEffect, useRef, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { Subject } from 'rxjs';
import styled from 'styled-components';
import { XLINK_ATTR } from '../environment/const';
import { setXLink } from '../helpers/setXLink';
import PhotosiEditorSDK from '../PhotosiEditorSDK';
import { getImageFromCache } from '../service/CacheStorage';
import { lazyLoadStage, ShowAdjustImage } from '../stage-helpers/initialize';
import EditIcon from './../../assets/images/svg/iconEdit.svg';
import { Icon } from './Icon';
import { ColorPicker } from './SvgPreview/ColorPicker';

export const StageView = styled.div`
    height: 100%;
`;

export const Preview = styled.div`
    display: flex;
    justify-content: space-around;
    height: 100%;
    text-align: center;
    svg {
        display: flex;
        max-width: 100%;
        max-height: 100%;
        margin: auto;
    }
`;

interface StageComponentInterface {
    stageId: string;
    index: number;
    forceRedraw: Subject<number>;
}

export const Stage: React.FC<StageComponentInterface> = ({ stageId, index, children, forceRedraw }) => {
    const { ref, inView, entry } = useInView({ delay: 100 });
    const [redraw, setRedraw] = useState<number>(0);
    const stageContainerRef = useRef<HTMLDivElement>(null);

    const { FilesPileStore } = PhotosiEditorSDK.getService();

    const editableImage = FilesPileStore.isValidOriginImage(stageId, index);

    const editorConfig = PhotosiEditorSDK.getConfig();

    let adjustImage, backgroundColor;
    if (editorConfig.previewStage) ({ adjustImage, backgroundColor } = editorConfig.previewStage);

    useEffect(() => {
        const subscription = forceRedraw.subscribe(setRedraw);
        return () => subscription.unsubscribe();
    }, []);

    useEffect(() => {
        if (entry?.target) entry.target.innerHTML = '';

        if (!inView) return;

        const { axios } = PhotosiEditorSDK.getGlobals();
        const { svgPreview } = FilesPileStore.getImageByStageIdAndIndex(stageId, index);

        if (typeof svgPreview !== 'string') {
            if (PhotosiEditorSDK.isVerbose()) console.debug('svg preview is not string', svgPreview);
            return;
        }

        axios
            .get(svgPreview)
            .then(({ data }) => {
                let current = entry?.target as HTMLDivElement;

                if (!current) throw 'svg stage not found';
                current.innerHTML = data;

                const target = current?.querySelector('svg');
                if (!target) throw 'target not found';

                return lazyLoadStage(stageId, index, SVG(target));
            })
            .catch((err) => {
                console.error('Failed to create svg preview in <Stage>:', err);
            })
            .finally(() => {
                if (!stageContainerRef.current) return;

                const { element, cached } = FilesPileStore.getImageByStageIdAndIndex(stageId, index);
                if (!element || !cached) return;

                URL.revokeObjectURL(element.node.getAttribute(XLINK_ATTR) || '');

                getImageFromCache({ stageId, index })
                    .then((image) => {
                        setXLink(element.node, image);
                        if (PhotosiEditorSDK.debug()) console.debug(`Load image from cache ${stageId} 📸`);
                    })
                    .catch((err) => {
                        console.error('StageComponent error: ', err);
                    });
            });

        return () => {};
    }, [inView, redraw]);

    return (
        <StageView ref={stageContainerRef}>
            <Preview
                {...{
                    ref: ref,
                    id: stageId,
                    key: `svg-stage-view-${stageId}`,
                }}
            ></Preview>
            {inView && (
                <>
                    {children && <div className="in-view-children">{children}</div>}
                    <div className="grid">
                        {adjustImage && editableImage && (
                            <div className="column">
                                <Icon
                                    src={EditIcon}
                                    onClick={() => {
                                        ShowAdjustImage(stageId, index);
                                    }}
                                />
                            </div>
                        )}

                        {backgroundColor && editableImage && (
                            <div className="column">
                                <ColorPicker stageId={stageId} />
                            </div>
                        )}
                    </div>
                </>
            )}
        </StageView>
    );
};

export default Stage;
