import { cast, Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree';
import { EditorFactoryOutputInterface } from '../service/EditorFactory';

export interface EditorPileStoreInterface extends Instance<typeof EditorPileStore> {}
export interface EditorPileStoreInterfaceSnapshotIn extends SnapshotIn<typeof EditorPileStore> {}
export interface EditorPileStoreInterfaceSnapshotOut extends SnapshotOut<typeof EditorPileStore> {}

export const EditorItem = types
    .model({
        stageId: types.string,
    })
    .volatile<{ editor: EditorFactoryOutputInterface | null }>((self) => ({ editor: null }))
    .actions((self) => ({
        set({ stageId, editor }: { stageId: string; editor: EditorFactoryOutputInterface }) {
            self.stageId = stageId;
            self.editor = editor;
        },
    }));

export const EditorPileStore = types
    .model({
        editors: types.array(EditorItem),
    })
    .actions((self) => ({
        setEditor(stageId: string, editor: EditorFactoryOutputInterface) {
            const target = self.editors.filter((current) => {
                if (current.stageId === stageId) {
                    current.set({ stageId, editor });
                    return true;
                }
                return false;
            });

            if (target.length <= 0) {
                const newEditor = EditorItem.create({ stageId });
                newEditor.set({ stageId, editor });
                self.editors.push(newEditor);
            }
        },
        getEditor(stageId: string) {
            return self.editors.reduce((acc: EditorFactoryOutputInterface | undefined, value: any) => {
                if (value.stageId === stageId) acc = value.editor;
                return acc;
            }, undefined);
        },
        remove(stageId: string) {
            self.editors = cast(
                self.editors.filter((item) => {
                    if (item.stageId === stageId) {
                        return false;
                    }
                    return true;
                })
            );
        },
    }));
