import { Image as ImageSvg } from '@svgdotjs/svg.js';
import { ADJUST_MIN_SCALE, ADJUST_MAX_SCALE } from '../environment/const';
import { clamp } from '../helpers/clamp';
import { PhotosiEditorSDK } from '../PhotosiEditorSDK';
import Hammer from 'hammerjs';

export const initializeDesktopDragAndDrop = (stageTarget: HTMLElement, svgPreview: ImageSvg) => {
    const { AdjustPreviewStore } = PhotosiEditorSDK.getService();

    const mc = new Hammer.Manager(stageTarget);
    mc.add(new Hammer.Pan());

    let translateX = AdjustPreviewStore.stage.transform.translate.x;
    let translateY = AdjustPreviewStore.stage.transform.translate.y;

    mc.on('pan panend', function (ev) {
        let scale = AdjustPreviewStore.stage.transform.scale;
        let rotate = AdjustPreviewStore.stage.transform.rotate;

        if (ev.type === 'pan') {
            scale = clamp(AdjustPreviewStore.stage.transform.scale * ev.scale, ADJUST_MIN_SCALE, ADJUST_MAX_SCALE);

            AdjustPreviewStore.calcRangeBoundaries('stage');

            translateX = clamp(
                AdjustPreviewStore.stage.transform.translate.x + ev.deltaX,
                AdjustPreviewStore.stage.boundaries.translateX.min,
                AdjustPreviewStore.stage.boundaries.translateX.max
            );

            translateY = clamp(
                AdjustPreviewStore.stage.transform.translate.y + ev.deltaY,
                AdjustPreviewStore.stage.boundaries.translateY.min,
                AdjustPreviewStore.stage.boundaries.translateY.max
            );

            svgPreview.transform({ translate: { x: translateX, y: translateY }, scale, rotate });
        }

        setTimeout(() => {
            if (ev.type === 'panend') {
                AdjustPreviewStore.setStageTransform({
                    rotate,
                    scale,
                    translate: { x: translateX, y: translateY },
                });
            }
        }, 100);
    });

    AdjustPreviewStore.calcRangeBoundaries('stage');
};
