import { getSnapshot, Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree';
import { ADJUST_MIN_SCALE } from '../environment/const';
import math from '../helpers/math';
import { GenericXYStore } from './GenericXYStore';

export interface AdjustPreviewTrasformStoreInterface extends Instance<typeof AdjustPreviewTrasformStore> {}
export interface AdjustPreviewTrasformStoreInterfaceSnapshotIn extends SnapshotIn<typeof AdjustPreviewTrasformStore> {}
export interface AdjustPreviewTrasformStoreInterfaceSnapshotOut
    extends SnapshotOut<typeof AdjustPreviewTrasformStore> {}

export const AdjustPreviewTrasformStore = types
    .model({
        translate: GenericXYStore,
        rotate: types.number,
        scale: types.number,
    })
    .actions((self) => ({
        get(): AdjustPreviewTrasformStoreInterfaceSnapshotOut {
            const data = getSnapshot(self);
            return {
                translate: {
                    x: math.round(data.translate.x * data.scale),
                    y: math.round(data.translate.y * data.scale),
                },
                rotate: math.roundFixedTwo(data.rotate),
                scale: math.roundFixedTwo(data.scale),
            };
        },
    }))
    .actions((self) => ({
        set(data: AdjustPreviewTrasformStoreInterfaceSnapshotIn): AdjustPreviewTrasformStoreInterfaceSnapshotOut {
            self.translate.set(data.translate);
            self.rotate = math.roundFixedTwo(data.rotate);
            self.scale = math.roundFixedTwo(data.scale);
            return self.get();
        },
        clear() {
            self.rotate = 0;
            self.scale = ADJUST_MIN_SCALE;
            self.translate.set({ x: 0, y: 0 });
        },
    }));

export default AdjustPreviewTrasformStore;
