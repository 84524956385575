export const SVG_PREVIEW_ITEMKEY_STORAGE = 'esvg-preview-svg';
export const FILES_PILE_ITEMKEY_STORAGE = 'esvg-file-pile';
export const ADJUST_PREVIEW_ITEMKEY_STORAGE = 'esvg-adjust-preview';
export const IMAGE_MAX_SIZE = 2000;
export const ADJUST_STAGE_DIV_ID = 'adjustStageDivId';
//export const XHR_TIMEOUT: number = (Number(process.env.REACT_APP_XHR_TIMEOUT) || 0) as number;

/**
 * The name of the cookie for the language
 */
export const LANGUAGE_COOKIE: string = 'phs_choosen_language';

/**
 * Language
 */
export const DEFAULT_LANGUAGE = `${process.env.REACT_APP_DEFAULT_LANGUAGE}`;
export const SUPPORTED_LANGUAGES = `${process.env.REACT_APP_SUPPORTED_LANGUAGES}`.split(',');
export const I18N_PATH = '/i18n';

export const ACCEPTED_QUERY_STRING_KEY = ['guid', 'token', 'status', 'message', 'nextUrl'];
export const NAVIGATOR_DENIED_QUERY_STRING_KEY = ['token'];

export const IMAGE_TYPE_PNG = 'image/png';
export const IMAGE_TYPE_SVG = 'image/svg+xml';

export const SVG_PREFIX = 'esvg';

export const GLOBAL_SPINNER_ID = `${SVG_PREFIX}-global-spinner`;
export const GLOBAL_HORIZONTAL_LOADER_ID = `${SVG_PREFIX}-global-horizontal-loader`;
export const GLOBAL_BOTTOM_DIALOG_ID = `${SVG_PREFIX}-global-bottom-dialog`;

export const ADJUST_MIN_SCALE = 1;
export const ADJUST_MAX_SCALE = 2;

export const STAGE_MULTIPLIER = 2;

export const GLOBAL_SERVICE_INDEX = 'service';

export const ORIG_HOLE_REF_ID = 'data-orig-hole-id';

export const PROMISE_COMPRESS_IMAGE = 'compress image';
export const PROMISE_RESIZE = 'resize image';

export const ACCEPTED_IMAGE = 'image/jpeg';

export const CACHES_BUCKET = `${SVG_PREFIX}-caches-bucket`;
export const FILE_PILES_STORE_KEY = `${SVG_PREFIX}-files-pile-store`;
export const PREVIEW_STORE_KEY = `${SVG_PREFIX}-edited-item-preview-store`;
export const ADJUST_STORE_KEY = `${SVG_PREFIX}-adjust-picture-modal-store`;
export const EDITOR_STORE_KEY = `${SVG_PREFIX}-editor-pile-store`;

export const WINDOW_INIT_INFO_KEY = `${SVG_PREFIX}-window-info`;
export const GLOBAL_CONTAINER_ID = `${SVG_PREFIX}-editor-global-container`;
export const PREVIEV_CONTAINER_ID = `${SVG_PREFIX}-editor-previews-list`;

export const CDN_UMD = `${process.env.REACT_APP_CDN_UMD}`;

export const ADJUST_MIN_ROTATION = -180;
export const ADJUST_MAX_ROTATION = 180;

export const DEFAULT_FILTER = 'unfilter';

export const UMD_PATH = 'umd/package';

export const IMAGETYPE_SVG = 'image/svg+xml;charset=utf-8';
export const IMAGE_TYPE_JPEG = 'image/jpeg';

export const MAIN_QUEUE_CONCURRENCY = 1;

export const ORIG_PLACEHOLDER = 'data-orig-placeholder';

export const DATA_XLINK_HREF = 'data-xlink-href';
export const DATA_SPINNER_ID = 'data-spinner-id';

export const FOREIGN_LOADER_ID = 'foreignPreviewLoader';

export const CACHED_LOCALLY_HEADER_KEY = 'Cached-Locally';

export const ROTATION_LABEL = `${SVG_PREFIX}-rotate`;

export const THUMBNAIL_CACHE_STORAGE_KEY = 'thumbanail-store';

export const FILTER_ELEM_WIDTH = 44;

export const BENCH_TEST_RESIZE_EXEC_MS = 20;
export const BENCH_TEST_RESULT_STORAGE_KEY = `${SVG_PREFIX}-bench-test-resize-status`;

export const XLINK_ATTR = 'xlink:href';

export const HIGHLIGHT_CLASS = 'highlight';
