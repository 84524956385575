import styled from 'styled-components';
import { HIGHLIGHT_CLASS } from '../../environment/const';
import Button from '../Button';
import CloseIcon from '../CloseIcon';

/*
    TODO: Questo file deve essere rifattorizzato
    - i componenti vanno splittati e organizzati in modo più ritulizzabile
*/

export const EditTextContainer = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
`;

export const TextInputArea = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
`;

export const FormatTextArea = styled.div`
    width: 100%;
    ul {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        margin: 0px;
        padding: 0px;
    }
    li {
        cursor: pointer;
        border: 1px solid transparent;
        list-style: none;
        border-radius: 30px;
        height: 40px;
        width: 40px;
        margin: 5px;
        margin-bottom: 20px;
        padding: 3px;
        text-transform: capitalize;
        text-align: center;
        line-height: 30px;
        position: relative;
    }
    li.${HIGHLIGHT_CLASS} {
        border: 1px solid ${({ theme }) => theme.colors.main.orange};
        &::before {
            color: ${({ theme }) => theme.colors.main.orange};
        }
    }
    li::before {
        font-family: 'arial';
        font-size: 18px;
        line-height: 18px;
        color: transparent;
        position: absolute;
        top: 40px;
        left: 40%;
        content: '•';
    }
`;

export const ColorTextArea = styled.div`
    width: 100%;
    ul {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        margin: 0px;
        padding: 0px;
    }
    li {
        cursor: pointer;
        border: 1px solid transparent;
        list-style: none;
        border-radius: 30px;
        height: 30px;
        width: 30px;
        margin: 3px;
        margin-bottom: 20px;
        text-transform: capitalize;
        text-align: center;
        position: relative;
    }
    li.${HIGHLIGHT_CLASS} {
        border: 1px solid ${({ theme }) => theme.colors.main.orange};
        &::before {
            color: ${({ theme }) => theme.colors.main.orange};
        }
    }
    li::before {
        font-family: 'arial';
        font-size: 18px;
        line-height: 18px;
        color: transparent;
        position: absolute;
        top: 34px;
        left: 40%;
        content: '•';
    }
`;

export const Input = styled.input`
    height: 30px;
    width: 100%;
    font-size: 24px;
    border: 1px solid transparent;
    border-bottom: 1px solid ${({ theme }) => theme.colors.main.lightgray};
`;

export const LetterCounter = styled.div`
    font-size: 14px;
    color: ${({ theme }) => theme.colors.main.darkgray};
    width: 70px;
    margin-left: -90px;
    text-align: right;
    margin-top: 8px;
`;

export const SaveButton = styled(Button)`
    position: absolute;
    right: 18px;
    top: 10px;
    height: 42px;
    font-size: 16px;
`;

export const CloseIconEditText = styled(CloseIcon)`
    right: initial;
    top: 18px;
    left: 18px;
`;

export const Title = styled.h3`
    margin: 5px;
    margin-bottom: 20px;
`;

export const Subtitle = styled.h5`
    font-weight: 200;
    text-align: left;
    margin: 5px;
    margin-top: 10px;
    margin-bottom: 10px;
`;

export const Line = styled.hr`
    border-top: 1px solid ${({ theme }) => theme.colors.main.lightgray};
    border-bottom: 0px;
    border-left: 0px;
    border-right: 0px;
    margin: 5px;
    width: 100%;
`;

export const Label = styled.div`
    border-radius: 30px;
    height: 32px;
    width: 32px;
    box-shadow: 0 0 10px ${({ theme }) => theme.colors.main.lightgray};
`;

export const ColorLabel = styled.div`
    border-radius: 30px;
    height: 24px;
    width: 24px;
    margin: 2px;
    box-shadow: 0 0 10px ${({ theme }) => theme.colors.main.lightgray};
`;
