import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree';
import { math } from '../helpers/math';

export interface AdjustRangeBoundariesStoreInterface extends Instance<typeof AdjustRangeBoundariesStore> {}
export interface AdjustRangeBoundariesStoreInterfaceSnapshotIn extends SnapshotIn<typeof AdjustRangeBoundariesStore> {}
export interface AdjustRangeBoundariesStoreInterfaceSnapshotOut
    extends SnapshotOut<typeof AdjustRangeBoundariesStore> {}

export const AdjustRangeBoundariesStore = types
    .model({
        default: types.number,
        step: types.number,
        min: types.number,
        max: types.number,
    })
    .actions((self) => ({
        set(data: AdjustRangeBoundariesStoreInterfaceSnapshotIn) {
            self.default = data.default;
            self.step = data.step;
            self.min = math.roundFixedTwo(data.min);
            self.max = math.roundFixedTwo(data.max);
        },
    }));

export default AdjustRangeBoundariesStore;
