import canvasEncoder from './canvasEncoderToURL';
import { IMAGE_TYPE_JPEG } from '../environment/const';
import { JPEG_QUALITY } from '../environment/compressionConfig';

/**
 * Encode some image data in a given format using the browser's encoders
 *
 * @param {ImageData} data
 * @param {string} type A mime type, eg image/jpeg.
 * @param {number} [quality] Between 0-1.
 */
export async function imageEncoder(
    data: ImageData,
    type: string = IMAGE_TYPE_JPEG,
    quality: number = JPEG_QUALITY
): Promise<Blob> {
    let result = new Blob();
    try {
        result = await canvasEncoder(data, type, quality);
    } catch (error) {
        console.error('canvasEncoder error', error);
    }
    return result;
}

export default imageEncoder;
