import styled from 'styled-components';

export const Button = styled.button`
    width: auto;
    font-size: 0.8rem;
    max-width: 20rem;
    border-radius: 0.7rem;
    padding: 0rem 1rem 0rem 1rem;
    height: 35px;
    margin: auto;
    color: ${({ theme }) => theme.colors.main.white};
    border: 1px solid ${({ theme }) => theme.colors.main.seafoamblue};
    background-color: ${({ theme }) => theme.colors.main.seafoamblue};
    &.hollow {
        background-color: ${({ theme }) => theme.colors.main.white};
        color: ${({ theme }) => theme.colors.main.seafoamblue};
        border: 1px solid ${({ theme }) => theme.colors.main.seafoamblue};
    }
    &.secondary {
    }
    &.primary {
    }
    &[disabled] {
        border: 1px solid ${({ theme }) => theme.colors.main.lightgray};
        background-color: ${({ theme }) => theme.colors.main.lightgray};
    }
    &.rounded {
        border-radius: 300px;
    }
    &.hollow {
        color: ${({ theme }) => theme.colors.photosi.green};
        border: 1px solid ${({ theme }) => theme.colors.main.white};
        background-color: transparent !important;
    }
    &.hollow-white {
        color: ${({ theme }) => theme.colors.main.white};
        border: 1px solid ${({ theme }) => theme.colors.main.white};
        background-color: transparent !important;
    }
    &.slim {
        border: 0px;
        margin: 0px;
        padding: 0px;
    }
`;

export default Button;
