import PhotosiEditorSDK from './../PhotosiEditorSDK';

export interface TranslateProps {
    ns: string;
    key: string;
}
export const translate = async ({ ns, key }: TranslateProps): Promise<string> => {
    const { i18n } = PhotosiEditorSDK.getService();
    await i18n.loadNamespaces(ns);
    return await i18n.getResource(i18n.language.split('-')[0], ns, key);
};

export default translate;
