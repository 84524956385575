import React from 'react';
import { render } from 'react-dom';
import { DevIndex } from './components/DevIndex';
import PhotosiEditorSDKIntferace from './interface/PhotosiEditorSDKIntferace';
import { PhotosiEditorSDK } from './PhotosiEditorSDK';

import { MainTheme } from './theme/main';

window.PhotosiEditorSDK = PhotosiEditorSDK;

window.addEventListener('unhandledrejection', (event) => {
    console.warn(`UNHANDLED PROMISE REJECTION: ${event.reason}`);
});

window.addEventListener('DOMContentLoaded', async () => {
    document.body.style.textAlign = 'center';

    const theme = MainTheme;

    const backgroundPalette: Array<PhotosiEditorSDKIntferace.Color> = [
        { code: theme.colors.background.white, label: 'Bianco' },
        { code: theme.colors.background.black, label: 'Nero' },
        { code: theme.colors.background.turquoise, label: 'Turchese' },
        { code: theme.colors.background.blue, label: 'Blu' },
        { code: theme.colors.background.mint, label: 'Menta' },
        { code: theme.colors.background.yellow, label: 'Giallo' },
        { code: theme.colors.background.salmon, label: 'Salmone' },
        { code: theme.colors.background.red, label: 'Rosso' },
        { code: theme.colors.background.pink, label: 'Rosa' },
        { code: theme.colors.background.fuchsia, label: 'Fucsia' },
    ];

    const textPalette: Array<PhotosiEditorSDKIntferace.Color> = [
        { code: theme.colors.text.gray, label: '' },
        { code: theme.colors.text.white, label: '' },
        { code: theme.colors.text.black, label: '' },
        { code: theme.colors.text.red, label: '' },
        { code: theme.colors.text.blue, label: '' },
        { code: theme.colors.text.green, label: '' },
        { code: theme.colors.text.militaryGreen, label: '' },
    ];

    //const localData = localStorage.getItem(`${SVG_PREFIX}-filesPileStore`);
    //const imagesPile = localData !== null ? JSON.parse(localData).images : [];

    const config: PhotosiEditorSDKIntferace.Config = {
        verbose: true,
        backgroundPalette,
        textPalette,
        wakeLock: true,
        previewStage: {
            stageButton: true,
            replaceImage: true,
            adjustImage: true,
            backgroundColor: true,
            textInput: true,
            textColor: true,
            removeImage: true,
        },
    };

    const editorSDK = await PhotosiEditorSDK.initialize(config);
    const PhotoEditorGlobalComponent = await editorSDK.globalComponents();

    const container = document.getElementById('PhotoEditor');
    render(
        <>
            <DevIndex {...{ editorSDK }} />
            <PhotoEditorGlobalComponent />
        </>,
        container
    );
});

export default PhotosiEditorSDK;
