import { Image as ImageSvg, Polyline, SVG, TimeLike } from '@svgdotjs/svg.js';
import { toJS } from 'mobx';
import { inject, observer } from 'mobx-react';
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { setFilterToElements } from '../adjust-helpers/filtersHelper';
import { initializeStage } from '../adjust-helpers/initializeAdjustStage';
import { initializeDesktopDragAndDrop } from '../adjust-helpers/initializeDesktopDragAndDrop';
import { initializeTouchGesture } from '../adjust-helpers/initializeTouchGesture';
import { ADJUST_STAGE_DIV_ID, SVG_PREFIX } from '../environment/const';
import { drawBestFit } from '../helpers/drawBestFit';
import PageScroll from '../helpers/pageScroll';
import GlobalsResponsive from '../interface/GlobalResponsive';
import { HidingObjectInteface } from '../interface/HidingObjectInteface';
import { ProvidedService } from '../interface/ProvidedService';
import { AdjustPreviewEvent } from '../service/AdjustPreviewEvent';
import { AdjustPreviewStoreInterface } from '../state-manager/AdjustPreviewStore';
import { MainThemeInterface } from '../theme/main';
import FilterIcon from './../../assets/images/svg/filters-icon.svg';
import TransformIcon from './../../assets/images/svg/rotate-and-scale-icon.svg';
import PhotosiEditorSDK from './../PhotosiEditorSDK';
import { applyAdjustPreview } from './AdjustImage/applyAdjustPreview';
import { Filter } from './AdjustImage/Filters';
import { RotationController } from './AdjustImage/RotationController';
import { Button } from './Button';
import { CloseIcon } from './CloseIcon';
import { ContainerAdjustImage } from './ContainerAdjustImage';
import { IconButton } from './IconButton';
import { StageSVG } from './StageSVG';
import { TransNS } from './TransNS';

export interface EditorImagePreviewComponentInterface {
    readonly adjustPreviewStore?: AdjustPreviewStoreInterface;
    readonly globalAdjustImage?: HidingObjectInteface;
    readonly responsive?: GlobalsResponsive;
    readonly theme?: MainThemeInterface;
    readonly adjustPreviewEvent?: AdjustPreviewEvent;
}

const Controls = styled.div`
    height: 90px;
    width: 100%;
`;

const BottomButtons = styled.div`
    margin: 10px;
    display: flex;
    justify-content: space-evenly;
`;

export const AdjustImage: React.FC<EditorImagePreviewComponentInterface> = inject((provide: ProvidedService) => ({
    adjustPreviewStore: provide.service.AdjustPreviewStore,
    adjustPreviewEvent: provide.service.adjustPreviewEvent,
}))(
    observer(({ adjustPreviewStore, adjustPreviewEvent }) => {
        const theme = PhotosiEditorSDK.getTheme();
        const { responsive } = PhotosiEditorSDK.getGlobals();

        if (!adjustPreviewStore || !adjustPreviewEvent) {
            return <TransNS i18nKey="adjustimage:error">Errore caricamento servizi</TransNS>;
        }

        const refStage = useRef<HTMLDivElement>(null);
        const refContainerAdjustImage = useRef<HTMLDivElement>(null);

        const [svgPreview, setSvgPreview] = useState<ImageSvg>(SVG().image());
        const [holeBound, setHoleBound] = useState<Polyline>(SVG().polyline());

        const hideAdjustImage = () => adjustPreviewStore.hide();

        // LOAD CURRENT IMAGE FROM CACHE
        useLayoutEffect(() => {
            PageScroll.unlock();
            URL.revokeObjectURL(adjustPreviewStore.image);
            if (!adjustPreviewStore.visible) return;

            // BLOCK PAGE SCROLL ON SHOW ADJUST PANEL
            PageScroll.lock();

            adjustPreviewStore.getImage().then((cachedImage) => {
                adjustPreviewStore.setImage(cachedImage);
            });
        }, [adjustPreviewStore.visible]);

        // INITIALIZE STAGE
        useEffect(() => {
            if (!adjustPreviewStore.visible) return;
            if (!refStage.current) return;
            if (!adjustPreviewStore.image) return;

            const { imagePreview, holeBoundLine } = initializeStage(refStage.current, adjustPreviewStore.image, theme);
            if (!(imagePreview && holeBoundLine)) return;

            setSvgPreview(imagePreview);
            setHoleBound(holeBoundLine);

            if (responsive.isMobile) initializeTouchGesture(refStage.current, imagePreview, holeBoundLine);
            if (responsive.isDesktop) initializeDesktopDragAndDrop(refStage.current, imagePreview);
        }, [adjustPreviewStore.image, adjustPreviewStore.mode, adjustPreviewStore.visible]);

        if (!adjustPreviewStore.visible) return <></>;

        return (
            <ContainerAdjustImage id={`${SVG_PREFIX}-adjust-image-container`} ref={refContainerAdjustImage}>
                <StageSVG ref={refStage} id={ADJUST_STAGE_DIV_ID}></StageSVG>
                <CloseIcon onClick={() => hideAdjustImage()}>
                    <div>×</div>
                </CloseIcon>
                <Controls>
                    {adjustPreviewStore.mode === 'filter' && <Filter {...{ svgPreview }} />}
                    {adjustPreviewStore.mode === 'transform' && (
                        <RotationController
                            initialValue={adjustPreviewStore.stage.transform.rotate}
                            action={(rotate: number) => {
                                const { translate, scale } = toJS(adjustPreviewStore.stage.transform);
                                const transformation = drawBestFit({ rotate, translate, scale }, svgPreview, holeBound);
                                svgPreview.transform(transformation);
                                adjustPreviewStore.setStageTransform(transformation);
                            }}
                        ></RotationController>
                    )}
                </Controls>

                <BottomButtons>
                    <Button
                        className="rounded hollow-white"
                        onClick={() => {
                            const transform = {
                                rotate: adjustPreviewStore.stage.boundaries.rotate.default,
                                scale: adjustPreviewStore.stage.boundaries.scale.default,
                                translate: {
                                    x: adjustPreviewStore.stage.boundaries.translateX.default,
                                    y: adjustPreviewStore.stage.boundaries.translateY.default,
                                },
                            };

                            svgPreview
                                .animate({ duration: 100 } as TimeLike)
                                .transform(transform)
                                .after(() => {
                                    setFilterToElements('', [svgPreview]);
                                    adjustPreviewStore.setFilter('');
                                    svgPreview.transform(adjustPreviewStore.setStageTransform(transform));
                                });
                        }}
                    >
                        <TransNS i18nKey="adjustimage:reset">Reset</TransNS>
                    </Button>
                    {PhotosiEditorSDK.getConfig().showFilter && (
                        <IconButton
                            icon={FilterIcon}
                            selected={adjustPreviewStore.mode === 'filter'}
                            onClick={() => {
                                adjustPreviewStore.setMode('filter');
                                adjustPreviewEvent.selectFilterTab();
                            }}
                        >
                            <TransNS i18nKey="adjustimage:filter">Filtri</TransNS>
                        </IconButton>
                    )}
                    <IconButton
                        selected={adjustPreviewStore.mode === 'transform'}
                        icon={TransformIcon}
                        onClick={() => {
                            adjustPreviewStore.setMode('transform');
                            adjustPreviewEvent.selectTransformTab();
                        }}
                    >
                        <TransNS i18nKey="adjustimage:transform">Trasforma</TransNS>
                    </IconButton>
                    <Button
                        className="rounded"
                        onClick={() => {
                            const adjustPreviewStoreData = toJS(adjustPreviewStore);

                            applyAdjustPreview({
                                stageId: adjustPreviewStoreData.stageId,
                                id: adjustPreviewStoreData.id,
                                adjustPreviewStoreData,
                            });

                            hideAdjustImage();
                        }}
                    >
                        <TransNS i18nKey="adjustimage:apply">Applica</TransNS>
                    </Button>
                </BottomButtons>
            </ContainerAdjustImage>
        );
    })
);

export default AdjustImage;
