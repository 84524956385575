import React, { MouseEventHandler } from 'react';
import styled from 'styled-components';
import { TransNS } from '../TransNS';
import rotateIcon from './../../../assets/images/svg/rotate-left.svg';

interface RotateIconComponentInterface {
    direction: 'left' | 'right';
}
const RotateIcon = styled.div<RotateIconComponentInterface>`
    position: absolute;
    z-index: 11;
    img {
        margin: auto;
        transform: scaleX(${({ direction }) => (direction === 'left' ? '1' : '-1')});
    }
    ${({ direction }) => direction}:0;
    color: white;
    width: 34px;
    margin: 10px;
    margin-left: 20px;
    margin-right: 20px;
`;

interface RotateItemComponentInterface extends RotateIconComponentInterface {
    onClick?: MouseEventHandler<HTMLDivElement>;
}
export const RotateItem: React.FC<RotateItemComponentInterface> = ({ direction, onClick }) => {
    return (
        <>
            <RotateIcon direction={direction} onClick={onClick}>
                <img height="24" width="24" src={rotateIcon} />
                <label>
                    <TransNS i18nKey="adjustimage:rotate">Ruota</TransNS>
                </label>
            </RotateIcon>
        </>
    );
};
