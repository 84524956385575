import { DATA_XLINK_HREF, IMAGE_TYPE_SVG } from '../environment/const';
import { setXLink } from '../helpers/setXLink';

export const replaceDataXlink = (svgText: string): Document => {
    const parser = new DOMParser();
    const newSVG = parser.parseFromString(svgText, IMAGE_TYPE_SVG);

    const dataXlink = newSVG.querySelectorAll(`[${DATA_XLINK_HREF}]`);
    dataXlink.forEach((item) => {
        const xlink = item.getAttribute(DATA_XLINK_HREF) || '';
        item.removeAttribute(DATA_XLINK_HREF);
        setXLink(item, xlink);
    });

    return newSVG;
};

export default replaceDataXlink;
