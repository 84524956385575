import React from 'react';
import styled from 'styled-components';

const IconContainer = styled.div`
    flex-direction: row;
    text-transform: capitalize;
    img {
        cursor: pointer;
    }
`;

export interface IconComponentInterface {
    src: string;
    label?: string;
    onClick?: Function;
}

export const Icon: React.FC<IconComponentInterface> = ({ src, label, onClick }) => {
    return (
        <IconContainer onClick={() => (onClick ? onClick() : null)}>
            <img src={src}></img>
            <div>{label}</div>
        </IconContainer>
    );
};

export default Icon;
