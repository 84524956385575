import { addMiddleware, getSnapshot } from 'mobx-state-tree';
import { PREVIEW_STORE_KEY, FILE_PILES_STORE_KEY, ADJUST_STORE_KEY, EDITOR_STORE_KEY } from '../environment/const';
import { ProvidedService } from '../interface/ProvidedService';
export const initializeStoreMiddleware = (service: ProvidedService['service']) => {
    const { SvgPreviewStore, FilesPileStore, AdjustPreviewStore, EditorPileStore } = service;

    addMiddleware(SvgPreviewStore, (call: any, next: any) => {
        next(call, (value: any) => {
            localStorage.setItem(PREVIEW_STORE_KEY, JSON.stringify(getSnapshot(SvgPreviewStore)));
            return value;
        });
    });

    addMiddleware(FilesPileStore, (call: any, next: any) => {
        next(call, (value: any) => {
            localStorage.setItem(FILE_PILES_STORE_KEY, JSON.stringify(getSnapshot(FilesPileStore)));
            return value;
        });
    });

    addMiddleware(AdjustPreviewStore, (call: any, next: any) => {
        next(call, (value: any) => {
            localStorage.setItem(ADJUST_STORE_KEY, JSON.stringify(getSnapshot(AdjustPreviewStore)));
            return value;
        });
    });

    addMiddleware(EditorPileStore, (call: any, next: any) => {
        next(call, (value: any) => {
            localStorage.setItem(EDITOR_STORE_KEY, JSON.stringify(getSnapshot(EditorPileStore)));
            return value;
        });
    });
};
