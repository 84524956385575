import { Element, Svg } from '@svgdotjs/svg.js';
import { ADJUST_MAX_SCALE } from '../environment/const';
import { ImageSize } from '../interface/ImageSize';
import { stringToSVG } from '../service/SvgHandler';
import { getGroupUserImageArea } from '../stage-helpers/selectorsHelpers';

export interface HoleSizeObject extends ImageSize {
    svgSize: ImageSize;
    width: number;
    height: number;
}

export const getMaxHoleSize = async (svgTemplate: string): Promise<HoleSizeObject> => {
    const draw: Svg = stringToSVG(svgTemplate);

    const { height, width } = draw.bbox();

    const images = getGroupUserImageArea(draw);

    const currentImage = images.reduce<Element>((acc: Element, value: Element) => {
        const size = Math.max(Number(acc.width()), Number(acc.height()));
        const newSize = Math.max(Number(value.width()), Number(value.height()));

        if (size < newSize) {
            return value;
        } else {
            return acc;
        }
    }, new Svg());

    const result = { height: Number(currentImage.height()), width: Number(currentImage.width()) };

    currentImage.remove();

    return {
        width: result.width * ADJUST_MAX_SCALE,
        height: result.height * ADJUST_MAX_SCALE,
        svgSize: { width: width, height: height },
    };
};

export default getMaxHoleSize;
