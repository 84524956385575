import path from 'path';
import { THUMBNAIL_CACHE_STORAGE_KEY } from '../environment/const';
import { getImageCacheName } from '../helpers/getImageCacheName';
import { PhotosiEditorSDK } from './../PhotosiEditorSDK';

export const CacheStorage = async () => {
    return await window.caches.open(THUMBNAIL_CACHE_STORAGE_KEY);
};

export const createVary = (filename: string, size: number) => `origin-name: ${filename} thumb-size: ${size}`;

export const putCache = async (blob: Blob, filename: string): Promise<Blob | undefined> => {
    const caches = await window.caches.open(THUMBNAIL_CACHE_STORAGE_KEY);

    const headers = new Headers({
        'Content-Type': blob.type,
        Vary: createVary(filename, blob.size),
    });

    const response = new Response(blob, {
        headers: { ...headers, Vary: createVary(filename, blob.size) },
    });

    const { ext, name } = path.parse(filename);

    const cacheAddress = `/cache/thumbnail/${name}${ext}`;

    await caches.put(cacheAddress, response);

    return blob;
};

export const getCache = async (url: string): Promise<Response | void> => {
    const options = {
        ignoreSearch: true,
        ignoreMethod: true,
        ignoreVary: true,
        cacheName: THUMBNAIL_CACHE_STORAGE_KEY,
    };

    let request: Request | false = false;
    try {
        const cache = await caches.open(THUMBNAIL_CACHE_STORAGE_KEY);

        const cachePile = await cache.keys();

        request = cachePile
            .filter((request) => request.url.endsWith(url))
            .reduce((acc: Request | false, value) => {
                return value;
            }, false);
    } catch (error) {
        return Promise.reject(error);
    }

    if (!request) return Promise.resolve();
    return await caches.match(request, options);
};

export const imageIsCached = async ({ stageId, index }: { stageId: string; index: number }): Promise<boolean> => {
    const { FilesPileStore } = PhotosiEditorSDK.getService();

    const fileData = FilesPileStore.getImageByStageIdAndIndex(stageId, index);

    if (!fileData.name || typeof fileData.size === null) return Promise.reject('isCached: file data is not valid');

    const cacheName = getImageCacheName({ name: fileData.name, size: fileData.size || 0 });

    return await isCached(cacheName);
};

export const isCached = async (filename: string): Promise<boolean> => {
    let cachedThumbnail;
    try {
        cachedThumbnail = await getCache(filename);
    } catch (error) {
        console.error('isCached: ', error);
        return Promise.resolve(false);
    }
    return !!cachedThumbnail;
};

export const getImageFromCache = async ({ stageId, index }: { stageId: string; index: number }): Promise<string> => {
    const { FilesPileStore } = PhotosiEditorSDK.getService();

    const { name, size, cached } = FilesPileStore.getImageByStageIdAndIndex(stageId, index);

    if (!cached) return Promise.reject('getImageFromCache: not cached');

    if (typeof name !== 'string') return Promise.reject('getImageFromCache: Filename is not valid');
    if (typeof size !== 'number') return Promise.reject('getImageFromCache: size is not valid');

    if (name.length <= 0) return Promise.reject('getImageFromCache: Filename is empty');

    let cachedThumbnail;
    try {
        const cacheName = getImageCacheName({ name, size });
        cachedThumbnail = await getCache(cacheName);
    } catch (error) {
        return Promise.reject(error);
    }

    if (!cachedThumbnail) return Promise.reject('getImageFromCache: cached image not found');

    const blob = await cachedThumbnail.blob();

    if (!blob) return Promise.reject('getImageFromCache: Failed to create blob');

    return URL.createObjectURL(blob);
};
