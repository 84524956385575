import { css } from 'styled-components';
import { MainThemeInterface } from './main';

export const FontsCSS = css`
    @font-face {
        font-family: 'AmaticSC';
        font-style: normal;
        font-weight: 400;
        font-display: swap;
        src: url(https://fonts.gstatic.com/s/amaticsc/v16/TUZyzwprpvBS1izr_vOECuSfU5cP1Q.woff2) format('woff2');
    }

    @font-face {
        font-family: 'Caveat';
        font-style: normal;
        font-weight: 400;
        font-display: swap;
        src: url(https://fonts.gstatic.com/s/caveat/v10/WnznHAc5bAfYB2QRah7pcpNvOx-pjfJ9eIWpYT5Mmgo.woff2)
            format('woff2');
    }

    @font-face {
        font-family: 'Inconsolata';
        font-style: normal;
        font-weight: 400;
        font-stretch: 100%;
        font-display: swap;
        src: url(https://fonts.gstatic.com/s/inconsolata/v21/QldgNThLqRwH-OJ1UHjlKENVzkWGVkL3GZQmAwLYxYWI2qfdm7Lpp4U8WR32l3Wbycs.woff2)
            format('woff2');
    }

    @font-face {
        font-family: 'PlayfairDisplay';
        font-style: normal;
        font-weight: 400;
        font-display: swap;
        src: url(https://fonts.gstatic.com/s/playfairdisplay/v25/nuFvD-vYSZviVYUb_rj3ij__anPXJzDwcbmjWBN2PKdFvXDXbtPK_l2q.woff2)
            format('woff2');
    }

    @font-face {
        font-family: '${({ theme }: { theme: MainThemeInterface }) => theme.fonts.SFProTextMedium.name}';
        font-style: normal;
        font-weight: 400;
        font-display: swap;
        src: url(${({ theme }: { theme: MainThemeInterface }) => theme.fonts.SFProTextMedium.url.woff2}) format('woff2');
    }

    .AmaticSC,
    .AmaticSC-Regular {
        font-family: 'AmaticSC' !important;
    }
    .Caveat,
    .Caveat-Regular {
        font-family: 'Caveat' !important;
    }
    .Inconsolata,
    .Inconsolata-Regular {
        font-family: 'Inconsolata' !important;
    }
    .PlayfairDisplay,
    .PlayfairDisplay-Regular {
        font-family: 'PlayfairDisplay' !important;
    }
    .SFProTextMedium,
    .SFProTextMedium-Regular {
        font-family: ${({ theme }: { theme: MainThemeInterface }) => theme.fonts.SFProTextMedium.name};
    }

    svg {
        [font-family='AmaticSC-Regular'] {
            font-family: 'AmaticSC' !important;
        }

        [font-family='PlayfairDisplay-Regular'] {
            font-family: 'PlayfairDisplay' !important;
        }

        [font-family='Caveat-Regular'] {
            font-family: 'Caveat' !important;
        }

        [font-family='Inconsolata-Regular'] {
            font-family: 'Inconsolata' !important;
        }
    }
`;
