import { AbstractWorker } from './AbstractWorker';
import { Observable } from 'rxjs';

export type MozjpegWorkerAction = '/compress';

export interface MozjpegizerEventInput {
    data: MozjpegWorkerMessageInput;
}

export interface MozjpegWasm {
    mozjpeg: string;
}

export interface MozjpegWorkerMessageInput {
    action: MozjpegWorkerAction;
    imageData: ImageData;
    wasm?: MozjpegWasm;
}

export interface MozjpegWorkerMessageOutput {
    action: MozjpegWorkerAction;
    output: File | ImageData;
    error: Error | undefined;
}

export interface MozjpegInterface {
    postMessage(message: MozjpegWorkerMessageInput, callback?: Function): void;
    output$(args?: any): Observable<MozjpegWorkerMessageOutput>;
}

export class Mozjpeg extends AbstractWorker implements MozjpegInterface {
    constructor(worker: Worker, wasm: object) {
        super(worker, wasm);
    }
    postMessage(message: MozjpegWorkerMessageInput, callback?: Function): void {
        this.workerPostMessage(message, [], callback);
    }
    output$(args?: any): Observable<MozjpegWorkerMessageOutput> {
        return this.workerOutput$(args);
    }
}
