// my-theme.ts
import { DefaultTheme } from 'styled-components';
import SFProTextMedium from './../../assets/fonts/SFProText-Medium.ttf';
import SFProTextMediumWOFF from './../../assets/fonts/SFProText-Medium.woff';

interface FontData {
    name: string;
    url: { ttf: string; woff: string; woff2: string };
}
export interface MainThemeInterface extends DefaultTheme {
    colors: any;
    fonts: {
        SFProTextMedium: FontData;
    };
}

const MainTheme: MainThemeInterface = {
    fonts: {
        SFProTextMedium: {
            name: 'SFProText-Medium',
            url: { ttf: SFProTextMedium, woff: SFProTextMediumWOFF, woff2: SFProTextMediumWOFF },
        },
    },
    colors: {
        photosi: {
            green: '#5acec9',
            orange: '#EA5D0B',
        },
        main: {
            black: '#000000',
            blackTransparent: 'rgba(0,0,0,0.3)',
            darkgray: '#333333',
            gray: '#666666',
            lightgray: 'rgba(0,0,0,0.15)',
            white: '#ffffff',
            orange: '#eb5d0b',
            seafoamblue: 'rgb(90,207,201)',
        },
        background: {
            black: '#000000',
            darkgray: '#333333',
            gray: '#666666',
            lightgray: '#C8C7CC',
            softgray: '#d5d5d5',
            white: '#ffffff',
            turquoise: '#95D0E8',
            blue: '#3D64CD',
            mint: '#6DC1AE',
            yellow: '#FCE665',
            salmon: '#FFA599',
            red: '#F46060',
            pink: '#F1C1D2',
            fuchsia: '#F96EA7',
        },
        text: {
            gray: '#d5d5d5',
            black: '#000000',
            white: '#ffffff',
            red: '#ED3131',
            green: '#34AE6F',
            blue: '#3D64CD',
            militaryGreen: '#ABA63D',
        },
    },
};

export { MainTheme };
