import styled from 'styled-components';

export const ContainerAdjustImage = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    text-align: center;
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-color: ${(props) => props.theme.colors.main.black};
    z-index: 10;
`;

export default ContainerAdjustImage;
