import { inject } from 'mobx-react';
import React, { useLayoutEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import HidingObservableComponentInterface from '../interface/HidingObservableComponentInterface';
import PhotosiEditorSDKIntferace from '../interface/PhotosiEditorSDKIntferace';
import { ProvidedService } from '../interface/ProvidedService';
import Button from './Button';
import CloseIcon from './CloseIcon';
import { TransNS } from './TransNS';

const HIGHLIGHTED_CLASS = 'highlighted';

interface SingleColorComponentInterface {
    color: string;
}

const ColorPaletteGrid = styled.div`
    display: grid;
    grid-template-columns: 25% 25% 25% 25%;
    grid-template-rows: auto auto auto;
`;

const SingleColorFrame = styled.div`
    display: flex;
    width: 3rem;
    height: 3rem;
    text-align: center;
    margin-bottom: 1rem;
    margin-left: auto;
    margin-right: auto;
    border-radius: 300px;
    border: 1px solid rgba(255, 255, 255, 0);
    &.highlighted {
        border: 1px solid ${({ theme }) => theme.colors.main.orange} !important;
    }
`;

const SingleColor = styled.div<SingleColorComponentInterface>`
    background-color: ${({ color }) => color};
    border-radius: 300px;
    width: 2.3rem;
    margin: 0.35rem;
    box-shadow: 0px 5px 10px ${({ theme }) => theme.colors.main.lightgray};
`;

const PaletteContainer = styled.div`
    text-align: center;
    font-size: 0.8rem;
    padding: 1rem;
`;

export interface ColorPaletteComponentInterface {
    readonly globalBottomDialog?: HidingObservableComponentInterface;
    actions: { applyColor: Function; applyColorToAllPreview: Function };
    colors: Array<PhotosiEditorSDKIntferace.Color>;
    stageId: string;
}

export const ColorPalette: React.FC<ColorPaletteComponentInterface> = inject((provide: ProvidedService) => ({
    globalBottomDialog: provide.globals.globalBottomDialog,
}))(({ colors, globalBottomDialog, actions, stageId }) => {
    if (!globalBottomDialog) {
        return <>failed to initialize color palette</>;
    }

    const colorPaletteGrid = useRef<HTMLDivElement>(null);

    const color = colors.filter((color: PhotosiEditorSDKIntferace.Color) => {
        return color.selected === true;
    });

    const [choosedColor, setChoosedColor] = useState<PhotosiEditorSDKIntferace.Color | null>(color[0] || null);

    const isSelected = (color: PhotosiEditorSDKIntferace.Color): boolean => {
        if (choosedColor && choosedColor.code) {
            if (choosedColor && choosedColor.code && choosedColor.code.toLowerCase() === color.code.toLowerCase()) {
                return true;
            }
        } else {
            if (color.selected === true) {
                return true;
            }
        }
        return false;
    };

    useLayoutEffect(() => {
        const existsSelectedColor: boolean = colors
            .map((color: PhotosiEditorSDKIntferace.Color) => {
                return isSelected(color);
            })
            .reduce((acc, value) => {
                return value === true ? (acc = value) : (acc = acc);
            }, false);

        if (existsSelectedColor === false) {
            colors[0].selected = true;
            setChoosedColor(colors[0]);
        }
    }, []);

    return (
        <div>
            <h3>
                <TransNS i18nKey={'color-palette:choose-single-color'}>Scegli un colore</TransNS>
            </h3>
            <CloseIcon
                onClick={() => {
                    globalBottomDialog.hide();
                }}
            >
                <div>×</div>
            </CloseIcon>
            <ColorPaletteGrid ref={colorPaletteGrid}>
                {colors &&
                    colors.map((color: PhotosiEditorSDKIntferace.Color, index: number) => {
                        const SingleColorFrameId = `SingleColorFrame-${index}`;

                        return (
                            <PaletteContainer
                                key={`PaletteContainer-${index}-${Date.now()}`}
                                onClick={() => {
                                    setChoosedColor(color);
                                }}
                            >
                                <SingleColorFrame
                                    className={isSelected(color) ? HIGHLIGHTED_CLASS : ''}
                                    id={SingleColorFrameId}
                                    key={`SingleColorFrame-${index}-${Date.now()}`}
                                >
                                    <SingleColor key={`SingleColor-${index}-${Date.now()}`} color={color.code} />
                                </SingleColorFrame>
                                <label>{color.label}</label>
                            </PaletteContainer>
                        );
                    })}
            </ColorPaletteGrid>

            <Button
                onClick={() => actions.applyColorToAllPreview(choosedColor || colors[0], stageId)}
                className="hollow"
            >
                <TransNS i18nKey="color-palette:all">A tutte</TransNS>
            </Button>
            <Button onClick={() => actions.applyColor(choosedColor || colors[0], stageId)}>
                <TransNS i18nKey="color-palette:choose-one">Solo a questa</TransNS>
            </Button>
        </div>
    );
});
