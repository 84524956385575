import { Svg, List, Element } from '@svgdotjs/svg.js';

// RIMUOVI PLACEHOLDER
export function getPlaceholder(value: string) {
    return value.replace('placeholder_', '');
}

// SELETTORE IMMAGINI
export function getGroupUserImageArea(draw: Svg): List<Element> {
    return draw.find(`[id^="UserImageArea" i]`);
}

// SELETTORE TESTI
export function getGroupUserTextArea(draw: Svg): List<Element> {
    return draw.find(`[id^="placeholder_userText" i]`);
}
// SELETTORE BACKGROUND
export function getGroupUserBkcolor(draw: Svg): List<Element> {
    return draw.find(`[id^="userBkcolor" i]`);
}
