import { inject, observer } from 'mobx-react';
import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { GLOBAL_BOTTOM_DIALOG_ID } from '../environment/const';
import HidingObservableComponentInterface from '../interface/HidingObservableComponentInterface';
import { ProvidedService } from '../interface/ProvidedService';

const Background = styled.div`
    transition: opacity 0.3s;
    position: fixed;
    opacity: 0;
    top: 0px;
    left: 0px;
    width: ${window.innerWidth}px;
    height: ${window.innerHeight}px;
    background-color: ${(props) => props.theme.colors.main.blackTransparent};
    margin: 0px;
    padding: 0px;
    z-index: 500;
    &.solid {
        opacity: 1;
    }
    &.hide {
        display: none !important;
    }
`;

const BottomDialogContainer = styled.div`
    position: fixed;
    z-index: 1;
`;

const BottomDialog = styled.div`
    transition: transform 0.3s;
    transform: translateY(100%);
    position: fixed;
    bottom: 0px;
    left: 0px;
    z-index: 501;
    background-color: ${(props) => props.theme.colors.main.white};
    border-radius: 20px 20px 0px 0px;
    width: ${window.innerWidth}px;
    &.show {
        transform: translateY(0%);
    }
`;

const BottomDialogChildren = styled.div`
    text-align: center;
    padding: 1rem;
    margin: auto;
`;

export interface GlobalBottomDialogComponentInterface {
    readonly globalBottomDialog?: HidingObservableComponentInterface;
}

export const GlobalBottomDialog: React.FC<GlobalBottomDialogComponentInterface> = inject(
    (provide: ProvidedService) => ({
        globalBottomDialog: provide.globals.globalBottomDialog,
    })
)(
    observer(({ globalBottomDialog }) => {
        if (!globalBottomDialog) {
            return <>Error on init globalBottomDialog</>;
        }

        const bottomDialog = useRef<HTMLDivElement>(null);
        const background = useRef<HTMLDivElement>(null);

        const close = () => {
            if (bottomDialog.current) bottomDialog.current.classList.remove('show');
            setTimeout(() => {
                if (background.current) background.current.classList.remove('solid');
            }, 150);
            setTimeout(() => {
                if (background.current) background.current.classList.add('hide');
            }, 300);
        };

        const open = () => {
            if (background.current) background.current.classList.remove('hide');
            setTimeout(() => {
                if (bottomDialog.current) bottomDialog.current.classList.add('show');
                if (background.current) background.current.classList.add('solid');
            }, 100);
        };

        useEffect(() => {
            if (globalBottomDialog.visible === true) {
                open();
            } else {
                close();
            }
        }, [globalBottomDialog.visible]);

        return (
            <BottomDialogContainer id={GLOBAL_BOTTOM_DIALOG_ID}>
                <Background
                    ref={background}
                    className="hide"
                    onClick={() => {
                        if (globalBottomDialog) globalBottomDialog.hide();
                    }}
                ></Background>
                <BottomDialog ref={bottomDialog} style={globalBottomDialog.style}>
                    <BottomDialogChildren>{globalBottomDialog.children}</BottomDialogChildren>
                </BottomDialog>
            </BottomDialogContainer>
        );
    })
);

export default GlobalBottomDialog;
