import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree';
import { math } from '../helpers/math';

export interface GenericXYStoreInterface extends Instance<typeof GenericXYStore> {}
export interface GenericXYStoreInterfaceSnapshotIn extends SnapshotIn<typeof GenericXYStore> {}
export interface GenericXYStoreInterfaceSnapshotOut extends SnapshotOut<typeof GenericXYStore> {}

export const GenericXYStore = types
    .model({
        x: types.number,
        y: types.number,
    })
    .actions((self) => ({
        set(data: GenericXYStoreInterfaceSnapshotIn): void {
            self.x = math.round(data.x);
            self.y = math.round(data.y);
        },
    }));
