import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree';
import { filterName } from '../adjust-helpers/filtersHelper';
import AdjustPreviewStore from './AdjustPreviewStore';

export interface SvgEditedElementsStoreInterface extends Instance<typeof SvgEditedElementsStore> {}
export interface SvgEditedElementsStoreInterfaceSnapshotIn extends SnapshotIn<typeof SvgEditedElementsStore> {}
export interface SvgEditedElementsStoreInterfaceSnapshotOut extends SnapshotOut<typeof SvgEditedElementsStore> {
    filter: filterName;
}

export const SvgEditedElementsStore = types
    .model({
        stageId: types.string,
        id: types.string,
        fill: types.string,
        filter: types.string,
        filterHTML: types.string,
        fontFamily: types.string,
        fontSize: types.string,
        textContent: types.string,
        textContentOrigin: types.string,
        preserveAspectRatio: types.string,
        href: types.string,
        type: types.string,
        adjustament: types.maybeNull(AdjustPreviewStore),
    })
    .actions((self) => ({
        set(data: Partial<SvgEditedElementsStoreInterfaceSnapshotIn>) {
            if (data.id) self.id = data.id;
            if (data.stageId) self.stageId = data.stageId;
            if (data.fill) self.fill = data.fill;
            if (typeof data.filter !== 'undefined') self.filter = data.filter;
            if (typeof data.filterHTML !== 'undefined') self.filterHTML = data.filterHTML;
            if (data.fontFamily) self.fontFamily = data.fontFamily;
            if (data.fontSize) self.fontSize = data.fontSize;
            if (data.textContent) self.textContent = data.textContent;
            if (data.textContentOrigin) self.textContentOrigin = data.textContentOrigin;
            if (data.preserveAspectRatio) self.preserveAspectRatio = data.preserveAspectRatio;
            if (data.href) self.href = data.href;
            if (data.type) self.type = data.type;
            if (data.adjustament) self.adjustament = AdjustPreviewStore.create(data.adjustament);
        },
    }));
