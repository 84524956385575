import PhotosiEditorSDK from '../PhotosiEditorSDK';

export const createCanvasElement = (): HTMLCanvasElement | OffscreenCanvas => {
    let canvas: HTMLCanvasElement | OffscreenCanvas = document.createElement('canvas');

    if (PhotosiEditorSDK.useOffscreenCanvas() && 'OffscreenCanvas' in window)
        return canvas.transferControlToOffscreen();

    return canvas;
};
