import { Image as ImageSvg, Polyline } from '@svgdotjs/svg.js';
import Hammer from 'hammerjs';
import { BehaviorSubject } from 'rxjs';
import { delay, distinctUntilChanged, filter } from 'rxjs/operators';
import { ADJUST_MAX_SCALE, ADJUST_MIN_SCALE } from '../environment/const';
import clamp from '../helpers/clamp';
import drawBestFit from '../helpers/drawBestFit';
import scaleLinear from '../helpers/scaleLinear';
import { PhotosiEditorSDK } from '../PhotosiEditorSDK';

interface gestureObv {
    translate: {
        x: number;
        y: number;
    };
    scale: number;
    rotate: number;
    event: HammerInput | string;
}

let mc: HammerManager;

export const initializeTouchGesture = (stageTarget: HTMLElement, svgPreview: ImageSvg, holeBound: Polyline) => {
    const { AdjustPreviewStore } = PhotosiEditorSDK.getService();

    if (AdjustPreviewStore.visible === false) return;

    if (mc) mc.destroy();

    mc = new Hammer.Manager(stageTarget);

    mc.add(new Hammer.Pan({ threshold: 0, pointers: 0 }));
    mc.add(new Hammer.Rotate({ threshold: 0 })).recognizeWith(mc.get('pan'));
    mc.add(new Hammer.Tap({ event: 'doubletap', taps: 2 }));
    mc.add(new Hammer.Pinch({ threshold: 0 })).recognizeWith([mc.get('pan'), mc.get('rotate')]);

    let scale = AdjustPreviewStore.stage.transform.scale,
        rotate = AdjustPreviewStore.stage.transform.rotate;

    let newScale = scale;
    let newRotate = rotate;

    const { x, y } = AdjustPreviewStore.stage.transform.translate;
    let coord = { x, y };
    let newCoord = coord;

    const gestureTransform$ = new BehaviorSubject<gestureObv>({
        translate: newCoord,
        scale: newScale,
        rotate: newRotate,
        event: {} as HammerInput,
    });

    gestureTransform$.pipe(distinctUntilChanged()).subscribe((transform) => {
        const { translate, scale, rotate } = transform;
        svgPreview.transform({ translate, scale, rotate });
    });

    stageTarget.ontouchstart = () => {
        (scale = AdjustPreviewStore.stage.transform.scale), (rotate = AdjustPreviewStore.stage.transform.rotate);

        newScale = scale;
        newRotate = rotate;

        coord = AdjustPreviewStore.stage.transform.translate;
        newCoord = coord;
    };

    stageTarget.ontouchend = () => {
        gestureTransform$.next({ translate: newCoord, scale: newScale, rotate: newRotate, event: 'touchend' });
    };

    const gestureEnd$ = gestureTransform$.pipe(
        filter((transform) => transform.event === 'touchend'),
        distinctUntilChanged(),
        delay(50)
    );

    gestureEnd$.subscribe((transform) => {
        const transformation = drawBestFit(transform, svgPreview, holeBound);
        svgPreview.transform(transformation);
        AdjustPreviewStore.setStageTransform(transformation);
    });

    const pinchZoomAction = (event: HammerInput, inOrOut: boolean = true) => {
        const currentScale = scaleLinear(
            event.distance,
            0,
            window.innerWidth,
            newScale,
            inOrOut ? ADJUST_MAX_SCALE : ADJUST_MIN_SCALE
        );
        newRotate = AdjustPreviewStore.stage.transform.rotate;
        newScale = clamp(currentScale, ADJUST_MIN_SCALE, ADJUST_MAX_SCALE);
    };

    mc.on('pan panend pinchin pinchout pinchend doubletap', function (ev) {
        switch (ev.type) {
            case 'doubletap': // RESET TRANSFORM
                coord = {
                    x: AdjustPreviewStore.stage.boundaries.translateX.default,
                    y: AdjustPreviewStore.stage.boundaries.translateY.default,
                };
                newCoord = coord;

                scale = AdjustPreviewStore.stage.boundaries.scale.default;
                newScale = scale;

                rotate = AdjustPreviewStore.stage.boundaries.rotate.default;
                newRotate = rotate;
                break;
            case 'pan':
                newCoord = { x: coord.x + ev.deltaX, y: coord.y + ev.deltaY };
                break;
            case 'panend':
                coord = newCoord;
                break;
            case 'pinchout': // ZOOM OUT
                pinchZoomAction(ev, true);
                break;
            case 'pinchin': // ZOOM IN
                pinchZoomAction(ev, false);
            case 'pinchend':
                break;
        }

        gestureTransform$.next({ translate: newCoord, scale: newScale, rotate: newRotate, event: ev });
    });
};
