import { Svg, Element } from '@svgdotjs/svg.js';
import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree';
import { GenericSizeStore } from './GenericSizeStore';

export interface ImageUrlStoreInterface extends Instance<typeof ImageUrlStore> {}
export interface ImageUrlStoreInterfaceSnapshotIn extends SnapshotIn<typeof ImageUrlStore> {}
export interface ImageUrlStoreInterfaceSnapshotOut extends SnapshotOut<typeof ImageUrlStore> {}

export interface ImageDataExtend {
    draw: Svg | null;
    element: Element | null;
}
export interface ImageUrlStoreExtendedInterface extends ImageUrlStoreInterfaceSnapshotOut {
    draw: Svg | null;
    element: Element | null;
}

export const ImageUrlStore = types
    .model({
        index: types.number,
        stageId: types.string,
        thumbnail: types.string,
        svgRotate: types.boolean,
        svgPreview: types.string,
        svgProduction: types.string,
        outputSize: GenericSizeStore,
        presignedImageURL: types.string,
        thumbnailSize: GenericSizeStore,
        holeSize: GenericSizeStore,
        originSize: GenericSizeStore,
        name: types.maybeNull(types.string),
        type: types.maybeNull(types.string),
        size: types.maybeNull(types.number),
        cached: types.boolean,
        inView: types.boolean,
    })
    .volatile<ImageDataExtend>(() => ({
        draw: null,
        element: null,
    }))
    .actions((self) => ({
        set(data: Partial<ImageUrlStoreExtendedInterface>) {
            if (data.index) self.index = data.index;
            if (data.thumbnail) self.thumbnail = data.thumbnail;
            if (data.stageId) self.stageId = data.stageId;
            if (data.element) self.element = data.element;
            if (data.svgRotate) self.svgRotate = data.svgRotate;
            if (data.svgPreview) self.svgPreview = data.svgPreview;
            if (data.svgProduction) self.svgProduction = data.svgProduction;
            if (data.presignedImageURL) self.presignedImageURL = data.presignedImageURL;
            if (data.outputSize) self.outputSize.set(data.outputSize);
            if (data.originSize) self.originSize.set(data.originSize);
            if (data.thumbnailSize) self.thumbnailSize.set(data.thumbnailSize);
            if (data.holeSize) self.holeSize.set(data.holeSize);
            if (data.name) self.name = data.name;
            if (data.type) self.type = data.type;
            if (data.draw) self.draw = data.draw;
            if (data.size) self.size = data.size;
            if (data.element) self.element = data.element;
            if (data.cached) self.cached = data.cached;
            if (data.inView) self.inView = data.inView;
        },
    }));
