import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree';
import { AdjustPreviewTrasformStore } from './AdjustPreviewTrasformStore';
import { AdjustPreviewBoundariesStore } from './AdjustPreviewBoundariesStore';
import { GenericSizeStore } from './GenericSizeStore';

export interface StageEnvInterface extends Instance<typeof StageEnv> {}
export interface StageEnvInterfaceSnapshotIn extends SnapshotIn<typeof StageEnv> {}
export interface StageEnvInterfaceSnapshotOut extends SnapshotOut<typeof StageEnv> {}

export const StageEnv = types
    .model({
        transform: AdjustPreviewTrasformStore,
        boundaries: AdjustPreviewBoundariesStore,
        size: GenericSizeStore,
        image: GenericSizeStore,
        svghole: GenericSizeStore,
    })
    .actions((self) => ({
        set(data: StageEnvInterfaceSnapshotIn) {
            self.transform.set(data.transform);
            self.boundaries.set(data.boundaries);
            self.size.set(data.size);
            self.image.set(data.image);
            self.svghole.set(data.svghole);
        },
    }));

export default StageEnv;
