import { SVG, Polyline, Image, Point, Rect } from '@svgdotjs/svg.js';
import { PhotosiEditorSDK } from '../PhotosiEditorSDK';
import { pointDistance } from './pointToLine2d';
import { math } from './math';
import { degreesToRadians } from './degreesToRadians';
import { clamp } from './clamp';
import { ImageSize } from '../interface/ImageSize';

type Transformation = { rotate: number; translate: { x: number; y: number }; scale: number };

export function getRotatedScale(angle: number, hole: ImageSize, image: ImageSize): number {
    const cw = hole.width / 2; // half canvas width and height
    const ch = hole.height / 2;

    const iw = image.width / 2; // half image width and height
    const ih = image.height / 2;
    // get the length C-B
    const dist = Math.sqrt(Math.pow(cw, 2) + Math.pow(ch, 2));
    // get the angle A
    const diagAngle = Math.asin(ch / dist);

    // Do the symmetry on the angle
    let a1 = ((angle % (Math.PI * 2)) + Math.PI * 4) % (Math.PI * 2);
    if (a1 > Math.PI) {
        a1 -= Math.PI;
    }
    if (a1 > Math.PI / 2 && a1 <= Math.PI) {
        a1 = Math.PI / 2 - (a1 - Math.PI / 2);
    }
    // get angles A1, A2
    const ang1 = Math.PI / 2 - diagAngle - Math.abs(a1);
    const ang2 = Math.abs(diagAngle - Math.abs(a1));
    // get lenghts C-E and C-F
    const dist1 = Math.cos(ang1) * dist;
    const dist2 = Math.cos(ang2) * dist;
    // get the max scale
    return Math.max(dist2 / iw, dist1 / ih);
}

// Refer to diagram in answer
function rotatePoint(center: { x: number; y: number }, point: { x: number; y: number }, angle: number) {
    var radians = (Math.PI / -180) * angle,
        cos = Math.cos(radians),
        sin = Math.sin(radians),
        x = cos * (point.x - center.x) + sin * (point.y - center.y) + center.x,
        y = cos * (point.y - center.y) - sin * (point.x - center.x) + center.y;

    x = math.round(x);
    y = math.round(y);
    return { x, y };
}

export const shiftPointByRotation = (
    rotate: number,
    {
        op1,
        op2,
        op3,
        op4,
    }: {
        op1: Point;
        op2: Point;
        op3: Point;
        op4: Point;
    }
) => {
    let p1 = op2;
    let p2 = op4;
    let p3 = op1;
    let p4 = op3;

    if (rotate < -90) {
        p1 = op4;
        p2 = op3;
        p3 = op2;
        p4 = op1;
    } else if (rotate > -90 && rotate <= 0) {
        p1 = op2;
        p2 = op4;
        p3 = op1;
        p4 = op3;
    } else if (rotate >= 0 && rotate <= 90) {
        p1 = op1;
        p2 = op2;
        p3 = op3;
        p4 = op4;
    } else if (rotate > 90) {
        p1 = op3;
        p2 = op1;
        p3 = op4;
        p4 = op2;
    }

    return { p1, p2, p3, p4 };
};

const getCoord = (
    image: Image | Polyline | Rect,
    translate: { x: number; y: number } = { x: 0, y: 0 },
    rotate: number = 0
) => {
    const center = { x: image.cx() + translate.x, y: image.cy() + translate.y };

    const imageX = Number(image.x()) + translate.x;
    const imageY = Number(image.y()) + translate.y;

    const op1 = new Point(rotatePoint(center, { x: imageX, y: imageY }, rotate));
    const op2 = new Point(rotatePoint(center, { x: imageX + Number(image.width()), y: imageY }, rotate));
    const op3 = new Point(rotatePoint(center, { x: imageX, y: imageY + Number(image.height()) }, rotate));
    const op4 = new Point(
        rotatePoint(center, { x: imageX + Number(image.width()), y: imageY + Number(image.height()) }, rotate)
    );

    return { op1, op2, op3, op4 };
};

/**
 * @desc draw border of item in editing stage, only for develop
 * @param image
 * @param hole
 * @param param2
 * @returns
 */
const drawDebugBounds = (image: Image, hole: Polyline, { translate, rotate, scale }: any) => {
    // translation correction
    const imageScaled = SVG()
        .image()
        .size(Number(image.width()) * scale, Number(image.height()) * scale)
        .cx(image.cx())
        .cy(image.cy());

    const { op1, op2, op3, op4 } = getCoord(imageScaled, translate, rotate);

    const { p1, p2, p3, p4 } = shiftPointByRotation(rotate, { op1, op2, op3, op4 });

    const { op1: hp1, op2: hp2, op3: hp3, op4: hp4 } = getCoord(hole);

    const origImageBoundScaled = SVG()
        .polyline([
            [p1.x, p1.y],
            [p2.x, p2.y],
            [p4.x, p4.y],
            [p3.x, p3.y],
            [p1.x, p1.y],
        ])
        .id('imageBoundScaled')
        .fill({ color: 'rgba(255,255,255,0)' })
        .stroke({ color: '#fff000', width: 2 });

    const screenClamp = (value: number) => {
        return clamp(value, 10, window.innerWidth - 30);
    };

    const text1 = SVG()
        .text('p1')
        .x(screenClamp(p1.x))
        .y(screenClamp(p1.y))
        .id('imageBoundScaledText1')
        .stroke({ color: '#fff000', width: 1 });

    const text2 = SVG()
        .text('p2')
        .x(screenClamp(p2.x))
        .y(screenClamp(p2.y))
        .id('imageBoundScaledText2')
        .stroke({ color: '#fff000', width: 1 });

    const text3 = SVG()
        .text('p3')
        .x(screenClamp(p3.x))
        .y(screenClamp(p3.y))
        .id('imageBoundScaledText3')
        .stroke({ color: '#ff9d00', width: 1 });

    const text4 = SVG()
        .text('p4')
        .x(screenClamp(p4.x))
        .y(screenClamp(p4.y))
        .id('imageBoundScaledText4')
        .stroke({ color: '#ff9d00', width: 1 });

    SVG(image.node.closest('svg'))
        .find('[id^="imageBound"]')
        .forEach((item) => {
            item.remove();
        });

    const hText1 = SVG().text('hp1').x(hp1.x).y(hp1.y).id('holeBoundScaledText1').fill({ color: '#ffffff' });

    const hText2 = SVG().text('hp2').x(hp2.x).y(hp2.y).id('holeBoundScaledText2').fill({ color: '#ffffff' });

    const hText3 = SVG().text('hp3').x(hp3.x).y(hp3.y).id('holeBoundScaledText3').fill({ color: '#ffffff' });

    const hText4 = SVG().text('hp4').x(hp4.x).y(hp4.y).id('holeBoundScaledText4').fill({ color: '#ffffff' });

    SVG(image.node.closest('svg'))
        .find('[id^="holeBound"]')
        .forEach((item) => {
            item.remove();
        });

    SVG(image.node.closest('svg'))
        .add(origImageBoundScaled)
        .add(text1)
        .add(text2)
        .add(text3)
        .add(text4)
        .add(hText1)
        .add(hText2)
        .add(hText3)
        .add(hText4);

    return { scale, translate, rotate };
};

const selfAdjustPosition = (image: Image, hole: Polyline, { translate, rotate, scale }: Transformation) => {
    // get hole point
    const { op1: hp1, op2: hp2, op3: hp3, op4: hp4 } = getCoord(hole);

    const adjust = ({ translate, rotate, scale }: Transformation): Transformation => {
        // create theoric rect with same szie of image scaled
        const rectScaled = SVG()
            .rect()
            .size(Number(image.width()) * scale, Number(image.height()) * scale)
            .cx(image.cx())
            .cy(image.cy());

        // shift point with current image rotation
        const { p1, p2, p3, p4 } = shiftPointByRotation(rotate, getCoord(rectScaled, translate, rotate));

        const topLeft = pointDistance(hp1, p3, p1);
        const bottomLeft = pointDistance(hp3, p4, p3);
        const topRight = pointDistance(hp2, p2, p1);
        const bottomRight = pointDistance(hp4, p2, p4);

        const cornerDistances = {
            left: Math.min(topLeft.x, bottomLeft.x),
            top: Math.min(topLeft.y, topRight.y),
            right: Math.max(topRight.x, bottomRight.x),
            bottom: Math.max(bottomLeft.y, bottomRight.y),
        };

        if (cornerDistances.top < 0) translate.y += cornerDistances.top - 1;
        if (cornerDistances.left < 0) translate.x += cornerDistances.left - 1;
        if (cornerDistances.right > 0) translate.x += cornerDistances.right + 1;
        if (cornerDistances.bottom > 0) translate.y += cornerDistances.bottom + 1;

        //console.log(cornerDistances);

        return { scale, translate, rotate };
    };

    const newScale = getRotatedScale(
        degreesToRadians(rotate),
        { width: hole.width(), height: hole.height() },
        { width: Number(image.width()), height: Number(image.height()) }
    );

    scale = newScale > scale ? newScale : scale;

    let beforeAndAfterIsTheSame = false;
    let data = '';

    let i = 0;

    while (!beforeAndAfterIsTheSame) {
        ({ scale, translate, rotate } = adjust({ translate, rotate, scale }));

        if (data === JSON.stringify({ translate, rotate, scale })) {
            beforeAndAfterIsTheSame = true;
        } else {
            data = JSON.stringify({ translate, rotate, scale });
        }

        i++;
        if (i >= 100) {
            if (PhotosiEditorSDK.debug()) console.debug('drawBestFit stopped adjustment loop');
            break;
        }
    }

    return { scale, translate, rotate };
};

export function drawBestFit(transform: Transformation, image: Image, hole: Polyline) {
    transform = selfAdjustPosition(image, hole, transform);
    drawDebugBounds(image, hole, transform);
    return transform;
}

export default drawBestFit;
