import { detect } from 'detect-browser';
import { BenchTestResizeResult } from '../components/BenchTest';
import { MAIN_QUEUE_CONCURRENCY } from '../environment/const';
import { clamp } from '../helpers/clamp';

export const queueConcurrency = (benchResult: BenchTestResizeResult) => {
    const MAX_CONCURRENCY = 4;

    if (benchResult === 'powerless') return 1;

    let concurrency = MAIN_QUEUE_CONCURRENCY;
    const hardwareConcurrency = window.navigator.hardwareConcurrency || 1;

    if (benchResult === 'ok') concurrency = Math.max(concurrency, Math.round(hardwareConcurrency / 1.5));

    const browser = detect();
    switch (browser && browser.os) {
        case 'iOS':
        case 'Android OS':
        case 'android':
            concurrency = MAIN_QUEUE_CONCURRENCY;
    }

    return clamp(concurrency, 1, MAX_CONCURRENCY);
};

export default queueConcurrency;
