/**
 * @desc The workspace is development?
 * @return {boolean}
 */
export function isDevEnv() {
    if (!(window && window.location)) {
        return;
    }

    if (
        window &&
        window.location &&
        window.location.origin &&
        (window.location.origin.indexOf('localhost') !== -1 ||
            window.location.origin.indexOf('photoforse') !== -1 ||
            window.location.origin.indexOf('ngrok') !== -1 ||
            window.location.origin.indexOf('0.0.0.0') !== -1 ||
            window.location.origin.indexOf('192.168.') !== -1)
    ) {
        return true;
    }
    return false;
}

export default isDevEnv;
