import { Image as ImageSvg, SVG } from '@svgdotjs/svg.js';
import React, { useEffect, useRef, useState } from 'react';
import { fromEvent } from 'rxjs';
import { debounceTime, map, distinctUntilChanged } from 'rxjs/operators';
import PhotosiEditorSDK from '../..';
import { setFilterToElements, filterName, filtersList } from '../../adjust-helpers/filtersHelper';
import { DEFAULT_FILTER, FILTER_ELEM_WIDTH } from '../../environment/const';
import clamp from '../../helpers/clamp';
import math from '../../helpers/math';
import DOMEvent from '../../interface/DomEvent';
import FilterLabel from '../FilterLabel';
import FilterList from '../FilterList';

const scrollToCenter = (target?: HTMLElement | Element | null, behavior: ScrollBehavior = 'smooth') => {
    target?.scrollIntoView({
        behavior,
        block: 'center',
        inline: 'center',
    });
};

const useFilters = () => {
    const { AdjustPreviewStore } = PhotosiEditorSDK.getService();

    const filterList = filtersList();
    const currentFilter = filterList
        .filter((filter) => {
            return AdjustPreviewStore.filter === filter.value;
        })
        .reduce<string>((_, item) => item.value, DEFAULT_FILTER);

    const [selectedFilter, setSelectedFilter] = useState<string>(currentFilter);

    useEffect(() => {
        AdjustPreviewStore.setFilter(selectedFilter);
    }, [selectedFilter]);

    return { selectedFilter, setSelectedFilter, filterList };
};

export const Filter: React.FC<{ svgPreview: ImageSvg }> = ({ svgPreview }) => {
    const { selectedFilter, setSelectedFilter, filterList } = useFilters();
    const [scrollBehavior, setScrollBehavior] = useState<ScrollBehavior>('auto');

    const { AdjustPreviewStore } = PhotosiEditorSDK.getService();

    const refFilterList = useRef<HTMLUListElement>(null);

    // EVENT ON CHANGE FILTERS
    useEffect(() => {
        if (!refFilterList.current) return;
        const onScrollFilter$ = fromEvent(refFilterList.current, 'scroll')
            .pipe(
                debounceTime(10),
                map((event: DOMEvent<HTMLDivElement>): string => {
                    const index = clamp(
                        math.round((event.target.scrollLeft + FILTER_ELEM_WIDTH * 2) / FILTER_ELEM_WIDTH) - 1,
                        1,
                        filterList.length
                    );

                    const target = event.target.children[index];
                    return target.getAttribute('data-filter') || DEFAULT_FILTER;
                }),
                distinctUntilChanged()
            )
            .subscribe((selectedFilter: string) => {
                setSelectedFilter(selectedFilter);
                setFilterToElements(selectedFilter as filterName, [svgPreview]);
            });

        // SCROLL TO CURRENT FILTER
        scrollToCenter(refFilterList.current.querySelector(`[data-filter="${selectedFilter}"]`), scrollBehavior);
        setScrollBehavior('smooth');

        return () => onScrollFilter$.unsubscribe();
    }, [svgPreview, refFilterList.current]);

    return (
        <>
            <FilterLabel>{selectedFilter}</FilterLabel>
            <FilterList ref={refFilterList}>
                <li className="lateral"></li>
                {filterList.map((filter) => (
                    <li
                        data-filter={filter.value}
                        key={`filter-item-${filter.name}`}
                        className={selectedFilter === filter.value ? 'highlight' : ''}
                    >
                        <svg>
                            <image
                                onLoad={(event: React.SyntheticEvent<SVGImageElement, Event>) => {
                                    setFilterToElements(filter.value as filterName, [SVG(event.target)]);
                                }}
                                onClick={(event: React.MouseEvent<SVGImageElement, MouseEvent>) => {
                                    setSelectedFilter(filter.value);
                                    setFilterToElements(filter.value as filterName, [svgPreview]);
                                    scrollToCenter(event.currentTarget);
                                }}
                                height="100%"
                                width="100%"
                                href={AdjustPreviewStore.image}
                                preserveAspectRatio="xMidYMid slice"
                            ></image>
                        </svg>
                    </li>
                ))}
                <li className="lateral"></li>
            </FilterList>
        </>
    );
};
