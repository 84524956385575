import { I18N_PATH } from '../environment/const';
import i18next, { i18n } from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpApi from 'i18next-http-backend';
import { getLanguage } from '../helpers/getLanguage';

export const i18nextInitalize = (config: any, language?: string, publicPath: string = ''): i18n => {
    const myConfig = {
        crossDomain: true,
        keySeparator: false,
        interpolation: {
            escapeValue: false,
        },
        debug: false,
        lng: language || getLanguage(),
        preload: [language || getLanguage()],
        fallbackLng: false,
        ns: ['translation'],
        defaultNS: 'translation',
        backend: {
            crossDomain: true,
            loadPath: `${publicPath ? publicPath + '/' : ''}${I18N_PATH}/{{lng}}/{{ns}}.json`,
            allowMultiLoading: false,
            requestOptions: {
                cache: 'default',
            },
        },
        load: 'languageOnly',
        react: {
            wait: true,
            useSuspense: true,
        },
        /*
        requestOptions: { // used for fetch, can also be a function (payload) => ({ method: 'GET' })
            mode: 'cors',
            credentials: 'same-origin',
            cache: 'default'
        }
        */
    };

    (async () => await i18next.use(HttpApi).use(initReactI18next).init(Object.assign(myConfig, config)))();

    return i18next;
};
export default i18nextInitalize;
