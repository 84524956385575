import React, { useEffect, useRef, useState } from 'react';
import { Subject } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import styled from 'styled-components';
import { ADJUST_MAX_ROTATION, ADJUST_MIN_ROTATION } from '../../environment/const';
import { clamp } from '../../helpers/clamp';
import scaleLinear from '../../helpers/scaleLinear';
import { RotateItem } from './RotateItem';

const Controls = styled.div`
    position: relative;
    z-index: 0;
    height: 90px;
    overflow-x: auto;
    &::-webkit-scrollbar {
        display: none;
    }
    display: flex;
    width: ${window.innerWidth}px;
    &::first-child {
        margin-left: 30px;
    }
    &::last-child {
        margin-right: 30px;
    }
    .divide-item {
        margin-top: auto;
        width: 10px;
        height: 20px;
        border-left: 1px solid ${({ theme }) => theme.colors.background.darkgray};
        border-top: 0px solid transparent;
        border-bottom: 0px solid transparent;
        border-right: 0px solid transparent;
        &.thick {
            border-left: 1px solid ${({ theme }) => theme.colors.background.white} !important;
        }
    }
`;

const generateRotationBar = () => {
    let rotationBar = [];
    for (let i = 0; i <= 89; i++) {
        rotationBar.push(
            <div key={`rotationBar-item-${i}`} className={`divide-item ${i % 10 === 0 && i > 0 ? 'thick' : ''}`}></div>
        );
    }
    return rotationBar;
};

const Bar = styled.div`
    cursor: pointer;
    display: flex;
    width: ${generateRotationBar().length * 10}px;
`;
interface CenterPointerComponentInteface {
    degree?: number;
}
const CenterPointer = styled.div<CenterPointerComponentInteface>`
    pointer-events: none;
    position: fixed;
    margin-top: 50px;
    height: 40px;
    left: ${window.innerWidth / 2 - 1}px;
    border-left: 1px solid
        ${({ theme, degree }) => (degree === 0 ? theme.colors.background.white : theme.colors.background.mint)};
`;

interface DegreeComponentInterface {
    degree?: number;
}
const Degree = styled.div<DegreeComponentInterface>`
    pointer-events: none;
    position: fixed;
    margin-top: 14px;
    color: ${({ theme, degree }) => (degree === 0 ? theme.colors.background.white : theme.colors.background.mint)};
    font-family: Lato;
    font-size: 24px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 28px;
    letter-spacing: normal;
    text-align: center;
    width: 40px;
    height: 24px;
    left: ${window.innerWidth / 2 - 20}px;
`;

interface GradientComponentInterface {
    position: 'left' | 'right';
}
const Gradient = styled.div<GradientComponentInterface>`
    pointer-events: none;
    z-index: 10;
    position: absolute;
    top: 0;
    ${({ position }) => position}:0;
    transform: scaleX(${({ position }) => (position === 'right' ? '-1' : '1')});
    height: 100%;
    width: ${window.innerWidth / 6}px;
    background-image: linear-gradient(to right, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
`;

export interface RotationControllerComponentInterface {
    action(value: number): void;
    initialValue?: number;
}

const scaleValue = ({ scrollLeft, scrollWidth, clientWidth }: HTMLDivElement): number => {
    return scaleLinear(scrollLeft, 0, scrollWidth - clientWidth, ADJUST_MIN_ROTATION, ADJUST_MAX_ROTATION);
};

export const RotationController: React.FC<RotationControllerComponentInterface> = ({ action, initialValue }) => {
    const refControls = useRef<HTMLDivElement>(null);
    const [degree, setDegree] = useState(Math.round(initialValue || 0));
    const [redraw, forceRedraw] = useState(0);
    const rotationAction$ = new Subject<number>();

    const clampRotation = (value: number) => {
        return Math.round(clamp(value, ADJUST_MIN_ROTATION, ADJUST_MAX_ROTATION));
    };

    useEffect(() => {
        if (initialValue === 0) {
            setDegree(clampRotation(initialValue));
            forceRedraw(Date.now());
        }
    }, [initialValue]);

    useEffect(() => {
        if (!(refControls && refControls.current)) return;
        const target = refControls.current;
        const left = scaleLinear(
            initialValue || 0,
            ADJUST_MIN_ROTATION,
            ADJUST_MAX_ROTATION,
            0,
            target.scrollWidth - target.clientWidth
        );
        target.scrollTo({ left, top: 0 });
    }, [redraw]);

    useEffect(() => {
        rotationAction$.pipe(distinctUntilChanged()).subscribe((value: number) => {
            const clampedValue = clampRotation(value);
            action(clampedValue);
            setDegree(clampedValue);
        });

        return () => {
            rotationAction$.unsubscribe();
        };
    });

    return (
        <div style={{ position: 'relative' }}>
            <Gradient position={'left'} />
            <RotateItem
                direction="left"
                onClick={() => {
                    rotationAction$.next(clamp(degree - 90, ADJUST_MIN_ROTATION, ADJUST_MAX_ROTATION));
                }}
            />
            <RotateItem
                direction="right"
                onClick={() => {
                    rotationAction$.next(clamp(degree + 90, ADJUST_MIN_ROTATION, ADJUST_MAX_ROTATION));
                }}
            />
            <Controls
                ref={refControls}
                onScroll={(event) => rotationAction$.next(scaleValue(event.currentTarget) || 0)}
                id="RotationController"
            >
                <Degree {...{ degree }}>{degree}</Degree>
                <CenterPointer {...{ degree }} />
                <Bar>{generateRotationBar()}</Bar>
            </Controls>
            <Gradient position={'right'} />
        </div>
    );
};
