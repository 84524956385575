import { makeObservable, observable, action } from 'mobx';
import HidingObservableComponentInterface from '../../interface/HidingObservableComponentInterface';

export const BottomDialog$ = (): HidingObservableComponentInterface => {
    const globalBottomDialog$ = {
        visible: false,
        children: null,
        style: {},
        hide() {
            this.visible = false;
        },
        show() {
            this.visible = true;
        },
        setChildren(children: any) {
            this.children = children;
        },
        setStyle(style: React.CSSProperties) {
            this.style = style;
        },
    };

    makeObservable<HidingObservableComponentInterface>(globalBottomDialog$, {
        style: observable,
        visible: observable,
        children: observable,
        setStyle: action,
        setChildren: action,
        show: action,
        hide: action,
    });

    return globalBottomDialog$;
};

export default BottomDialog$;
