import { Point } from '@svgdotjs/svg.js';
import math from './math';

export function pointDistance(p: Point, a: Point, b: Point) {
    const atob = { x: b.x - a.x, y: b.y - a.y };
    const atop = { x: p.x - a.x, y: p.y - a.y };
    const len = atob.x * atob.x + atob.y * atob.y;
    const dot = atop.x * atob.x + atop.y * atob.y;
    const t = Math.min(1, Math.max(0, dot / len));
    const point = new Point(p.x - (a.x + atob.x * t), p.y - (a.y + atob.y * t));

    return new Point(math.round(point.x), math.round(point.y));
}
