import { IMAGE_TYPE_JPEG, IMAGE_TYPE_PNG } from '../environment/const';
import canvasEncode from './canvasEncode';
import { getPseudoID } from './getPseudoID';

type extensionAccepted = 'jpeg' | 'png';

export const encodeImageData = async (imageData: ImageData, ext: extensionAccepted = 'jpeg') => {
    let type = IMAGE_TYPE_JPEG;
    switch (ext) {
        default:
        case 'jpeg':
            type = IMAGE_TYPE_JPEG;
            break;
        case 'png':
            type = IMAGE_TYPE_PNG;
            break;
    }

    const blob = await canvasEncode(imageData, type);
    return new File([blob], `canvas_${getPseudoID()}.${ext}`, {
        type,
    });
};

export const imageDataToPNG = async (imageData: ImageData): Promise<File> => {
    return await encodeImageData(imageData, 'png');
};

export const ImageDataToJPEG = async (imageData: ImageData): Promise<File> => {
    return await encodeImageData(imageData, 'jpeg');
};
