import { Provider } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import GlobalsResponsive from '../interface/GlobalResponsive';
import { ProvidedService } from '../interface/ProvidedService';
import { Breakpoints } from '../service/responsive/mediaQueries';

interface CustomProviderComponentInterface {
    children: any;
    service: ProvidedService;
}

export const CustomProvider: React.FC<CustomProviderComponentInterface> = ({ children, service }) => {
    const [customService, setCustomService] = useState<ProvidedService | null>(null);

    const isDesktop = (() => Breakpoints.isDesktop())();
    const isMobile = (() => Breakpoints.isMobile())();

    useEffect(() => {
        const responsive: GlobalsResponsive = { isDesktop: isDesktop, isMobile: isMobile };
        service.globals.responsive = responsive;
        setCustomService(service);
    }, []);

    return <>{customService && <Provider {...customService}>{children}</Provider>}</>;
};
