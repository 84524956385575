import { math } from './math';

export type calculateAspectRatioMode = 'fit' | 'cover';

/**
 * Conserve aspect ratio of the original region. Useful when shrinking/enlarging
 * images to fit into a certain area.
 *
 * @param {Number} srcWidth width of source image
 * @param {Number} srcHeight height of source image
 * @param {Number} maxWidth maximum available width
 * @param {Number} maxHeight maximum available height
 * @return {Object} { width, height }
 */

export function calculateAspectRatio(
    srcWidth: number,
    srcHeight: number,
    maxWidth: number,
    maxHeight: number,
    mode: calculateAspectRatioMode = 'fit'
) {
    let ratio;
    switch (mode) {
        case 'fit':
            ratio = Math.min(maxWidth / srcWidth, maxHeight / srcHeight);
            break;
        case 'cover':
            ratio = Math.max(maxWidth / srcWidth, maxHeight / srcHeight);
            break;
    }

    return { width: math.round(srcWidth * ratio), height: math.round(srcHeight * ratio) };
}
export default calculateAspectRatio;
