import { toJS } from 'mobx';
import PhotosiEditorSDK from '../../PhotosiEditorSDK';
import { applyAdjustment } from '../../stage-helpers/applyAdjustment';
import { AdjustPreviewStoreInterface } from '../../state-manager/AdjustPreviewStore';

export const applyAdjustPreview = ({
    stageId,
    id,
    adjustPreviewStoreData,
}: {
    stageId: string;
    id: string;
    adjustPreviewStoreData: AdjustPreviewStoreInterface;
}) => {
    const { SvgPreviewStore, AdjustPreviewStore, adjustPreviewEvent } = PhotosiEditorSDK.getService();
    const currentData = toJS(SvgPreviewStore.getStageItem(stageId, id));

    const { adjustament: preEdit } = currentData;

    const { draw, element } = toJS(adjustPreviewStoreData);

    if (!(draw && element)) throw 'failed to apply adjsustment, draw or element is lost';

    applyAdjustment(draw, element, adjustPreviewStoreData);

    const afterEdit = toJS(AdjustPreviewStore);

    if (!preEdit) return;

    if (preEdit.filter !== afterEdit.filter) adjustPreviewEvent.savedFilter();

    if (JSON.stringify(afterEdit.stage.transform) != JSON.stringify(preEdit.stage.transform || {})) {
        adjustPreviewEvent.savedTransform();
    }
};
