import { createGlobalStyle } from 'styled-components';
import { GLOBAL_CONTAINER_ID } from '../environment/const';
import { FontsCSS } from './fonts';
import { MainThemeInterface } from './main';

export const GlobalStyle = createGlobalStyle`
  
    ${FontsCSS}
   
    #${GLOBAL_CONTAINER_ID}{
      

        font-family: ${({ theme }: { theme: MainThemeInterface }) => theme.fonts.SFProTextMedium.name};
        margin: 0px;
        padding: 0px;
        width: 100%;
        min-width: 100%;
        input,
        button,
        textarea {
            font-family: ${({ theme }: { theme: MainThemeInterface }) => theme.fonts.SFProTextMedium.name};
        }
        button {
            border-radius: 10px;
        }
        .hide {
            display: none !important;
        }



        *,
        *::before,
        *::after {
            font-family: ${({ theme }: { theme: MainThemeInterface }) => theme.fonts.SFProTextMedium.name};
            box-sizing: border-box;
        }
    }
`;
