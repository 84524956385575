import { toJS } from 'mobx';
import { cast, Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree';
import {
    SvgEditedElementsStore,
    SvgEditedElementsStoreInterface,
    SvgEditedElementsStoreInterfaceSnapshotIn,
} from './SvgEditedElementsStore';

export interface SvgPreviewStoreInterface extends Instance<typeof SvgPreviewStore> {}
export interface SvgPreviewStoreInterfaceSnapshotIn extends SnapshotIn<typeof SvgPreviewStore> {}
export interface SvgPreviewStoreInterfaceSnapshotOut extends SnapshotOut<typeof SvgPreviewStore> {}

export const SvgPreviewStore = types
    .model({
        current: types.array(SvgEditedElementsStore),
    })
    .actions((self) => ({
        updateCurretByStageIdAndId(
            stageId: string,
            id: string,
            data: Partial<SvgEditedElementsStoreInterfaceSnapshotIn>
        ) {
            self.current.forEach((item) => {
                if (item.id === id && item.stageId === stageId) item.set(data);
            });
        },
    }))
    .actions((self) => ({
        length() {
            return self.current.length;
        },
        setCurrent(stageId: string, data: Array<SvgEditedElementsStoreInterfaceSnapshotIn>): void {
            const existStageId: boolean =
                self.current.filter((item) => {
                    return item.stageId === stageId;
                }).length > 0;

            if (!existStageId) {
                self.current.push(...data);
                return;
            }

            data.forEach((newStore) => {
                self.updateCurretByStageIdAndId(newStore.stageId, newStore.id, newStore);
            });
        },
        getStage(stageId: string | null = null): SvgEditedElementsStoreInterface[] {
            if (stageId === null) return [];
            return toJS(
                self.current
                    .filter((item) => {
                        return item.stageId === stageId;
                    })
                    .map((item) => toJS(item))
            );
        },
        getAdjustment(stageId: string, elementId: string) {
            const image = self.current.reduce((acc: any, value) => {
                if (value.id === elementId && value.stageId === stageId) {
                    acc = toJS(value);
                    return toJS(value);
                } else {
                    return acc;
                }
            }, {});
            return image.adjustament || null;
        },
        getStageItem(stageId: string, id: string): SvgEditedElementsStoreInterface {
            return self.current
                .filter((item) => {
                    return item.stageId === stageId && item.id === id;
                })
                .reduce((_, value) => {
                    return value;
                });
        },
        removeCurrentByStageId(stageId: string) {
            self.current = cast(
                self.current.filter((item) => {
                    return item.stageId !== stageId;
                })
            );
        },
        resetImageAdjustment(stageId: string) {
            self.current = cast(
                self.current.map((item) => {
                    if (item.stageId === stageId && item.type === 'image') item.adjustament?.clearTransform();
                    return item;
                })
            );
        },
    }));
