import styled from 'styled-components';
import { FILTER_ELEM_WIDTH } from '../environment/const';

export const FilterList = styled.ul`
    display: flex;
    margin: 0px;
    padding: 0px;
    overflow-x: auto;
    overflow-y: hidden;
    margin-top: 10px;
    scroll-snap-type: x mandatory;

    &::-webkit-scrollbar {
        display: none;
    }
    li.lateral {
        margin-left: calc(50% - 26px);
        padding: 0px;
        width: 0px;
        height: ${FILTER_ELEM_WIDTH}px;
    }
    li {
        scroll-snap-align: center;
        height: ${FILTER_ELEM_WIDTH + 4}px;
        width: ${FILTER_ELEM_WIDTH + 4}px;
        list-style-type: none;
        margin: 0px;
        margin-left: -2px;
        padding: 0px;
        border: 2px solid transparent;
        border-radius: 3px;
        flex-direction: row;
        cursor: pointer;
        &.highlight {
            border: 2px solid ${({ theme }) => theme.colors.main.white} !important;
        }
        svg {
            height: ${FILTER_ELEM_WIDTH}px;
            width: ${FILTER_ELEM_WIDTH}px;
            margin: 0px;
            padding: 0px;
            image {
                transition: opacity 0.3s;
            }
        }
    }
`;

export default FilterList;
