import React from 'react';
import { Subject } from 'rxjs';
import { ProvidedService } from '../interface/ProvidedService';
import { PreviewComponent } from './CreatePreview';
import ProcessEditor from './ProcessEditor';

export interface EditorFactoryOutputInterface {
    index: number;
    stageId: string;
    redraw: Function;
    remove: (forceStageId?: string, forceIndex?: number) => void;
    adjust: () => void;
    element: (key: string) => Promise<JSX.Element>;
    replace: (
        image: File,
        forceStageId?: string,
        forceIndex?: number,
        priority?: number | undefined
    ) => Promise<EditorFactoryOutputInterface>;
    rasterize: () => Promise<any>;
    export: () => Promise<any>;
    chooseBgColor: (forceStageId: string) => boolean;
    presignedImageURL: (url: string, forceIndex?: number) => void;
}

export const EditorFactory = ({
    stageId,
    index,
    service,
    globals,
}: {
    stageId: string;
    index: number;
    service: ProvidedService['service'];
    globals: ProvidedService['globals'];
}): EditorFactoryOutputInterface => {
    const { serviceQueue } = globals;

    let forceRedraw$ = new Subject<number>();

    const redraw = () => {
        forceRedraw$.next(Date.now());
    };

    const element = async (key: string): Promise<JSX.Element> => {
        const editorComponentElement = new Promise<React.FC<PreviewComponent>>((resolve, reject) => {
            serviceQueue.add(() => {
                return service.editor
                    .get({ index, stageId })
                    .then((element) => {
                        resolve(element);
                    })
                    .catch(reject);
            });
        });

        const Element = await editorComponentElement;

        return <Element key={key} forceRedraw={forceRedraw$} />;
    };

    const replace = async (
        image: File,
        forceStageId: string = stageId,
        forceIndex: number = index,
        priority?: number
    ): Promise<EditorFactoryOutputInterface> => {
        const current = service.FilesPileStore.getImageByStageIdAndIndex(stageId, index);

        const result = await ProcessEditor({
            ...current,
            svg: { preview: current.svgPreview, production: current.svgProduction, selfRotate: current.svgRotate },
            service,
            globals,
            stageId: forceStageId,
            index: forceIndex,
            priority,
            image,
            replace: true,
            selfStart: true,
        }).then((editor) => {
            redraw();
            return editor;
        });

        return result;
    };

    const remove = (forceStageId: string = stageId, forceIndex: number = index) => {
        console.warn('TODO: Work in progress ');
        /*
        const filePileStore = service.FilesPileStore;
        filePileStore.getImagesByStageId(stageId).forEach((image) => {
            const target = document.getElementById(image.stageId);
            if (target && target.parentElement) target.parentElement.remove();
        });

        filePileStore.remove(stageId, forceIndex ? forceIndex : index);
        service.SvgPreviewStore.removeCurrentByStageId(forceStageId ? `${forceStageId}` : `${stageId}`);
        service.EditorPileStore.remove(stageId);
        */
    };

    return {
        index,
        stageId,
        redraw,
        remove,
        adjust: () => service.editor.adjust(stageId, index),
        element,
        replace,
        export: () => service.editor.export(stageId, index),
        rasterize: () => service.editor.export(stageId, index),
        chooseBgColor: (forceStageId: string) =>
            service.editor.chooseBgColor(forceStageId ? `${forceStageId}` : `${stageId}`),
        presignedImageURL: (url: string, forceIndex: number = index): void => {
            service.FilesPileStore.replaceImage({
                stageId,
                index: forceIndex ? forceIndex : index,
                presignedImageURL: url,
            });
        },
    };
};
