import PhotosiEditorSDK from '../PhotosiEditorSDK';

class AbstractEvent {
    constructor(private target: string) {}
    protected createEvent(name: string, value?: object): CustomEvent {
        return new CustomEvent(name, value);
    }
    protected dispatchEvent(name: string, value?: object) {
        if (PhotosiEditorSDK.debug()) console.debug(`adjust preview event => ${name}`);
        const event = this.createEvent(name, value);
        const target = document.querySelector(this.target);
        if (target) {
            target.dispatchEvent(event);
        } else {
            if (PhotosiEditorSDK.debug()) console.debug(`target to dispatch event is ${target}`);
        }
    }
}

export class AdjustPreviewEvent extends AbstractEvent {
    constructor(target: string) {
        super(target);
    }
    public view() {
        this.dispatchEvent('modify_photo_view');
    }
    public selectFilterTab() {
        this.dispatchEvent('modify_photo_select_filter');
    }
    public selectTransformTab() {
        this.dispatchEvent('modify_photo_select_transform');
    }
    public savedFilter() {
        this.dispatchEvent('modify_photo_filter_save');
    }
    public savedTransform() {
        this.dispatchEvent('modify_photo_transform_save');
    }
}

export default AdjustPreviewEvent;
